import { Component, EventEmitter, HostBinding, Input, Output, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'hy-input-group',
  templateUrl: './input-group.component.html',
  styleUrls: ['./input-group.component.scss'],
  providers: [
      {
          provide: NG_VALUE_ACCESSOR,
          useExisting: forwardRef(() => InputGroupComponent),
          multi: true
      }
  ]
})
export class InputGroupComponent {

    private _value = "";

    @Input()
    public set value(value: string | undefined) {
        if (value === undefined) {
            value = "";
        }

        if (value != this._value) {
            this._value = value ;
            this.valueChange.next(value);
        }
    }

    public get value(): string {
        return this._value;
    }

    /** Text that is displayed above input box. */
    @Input()
    public label?: string;

    @Input()
    public type?: "text" | "number" | "email" | "password" = "text";

    /** Text that is displayed below input box. */
    @Input()
    public hint?: string;

    /** Text that is displayed in input box when there is no value. */
    @Input()
    public placeholder?: string;

    @Input()
    public autocomplete?: string;

    /** Defines if component is in error state. */
    @Input()
    @HostBinding("class.error")
    public error: boolean = false;

    /** Defines if component is in disabled state. */
    @Input()
    public disabled: boolean = false;


    @Output()
    public valueChange = new EventEmitter<string>();


    // ----- ControlValueAccessor ------------------------------------------- //

    _onChange = (value: string) => {};

    _onTouched = () => {};

    public writeValue(value: string): void {
        this._value = value;
        //this.onChange(value);
    }
    
    public registerOnChange(fn: (value: string) => void): void {
        this._onChange = fn;
    }

    public registerOnTouched(fn: () => void): void {
        this._onTouched = fn;
    }

    public setDisabledState?(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }
}
