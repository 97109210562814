import DateUtils, { newUtc } from 'src/app/util/DateUtils';
import { DayItem, DateRangeSelectorComponent } from './date-range-selector.component';

class CalendarMonth {
    public year: number = 0;
    public month: number = 0;

    public label: string = "";
    public days: DayItem[] = [];

    public nextMonthClass: string = "";
    public prevMonthClass: string = "";

    constructor(protected comp: DateRangeSelectorComponent) {
    }

    public setMonth(month: number, year: number): void {
        this.year = year;
        this.month = month;
        this.days = this.generateMonthItems(month, year);

        this.label = DateUtils.getMonthName(month) + " " + year;

        this.updateCanMoveFlags();
    }

    public refresh() {
        this.setMonth(this.month, this.year);
    }

    public updateCanMoveFlags() {
        this.nextMonthClass = this.calcNextMonthClass();
        this.prevMonthClass = this.calcPrevMonthClass();
    }

    protected calcNextMonthClass() {
        return "";
    }

    protected calcPrevMonthClass() {
        return "";
    }

    public moveToNextMonth(): void {
        if (this.month === 11) {
            this.setMonth(0, this.year + 1);
        } else {
            this.setMonth(this.month + 1, this.year);
        }
    }

    public moveToPrevMonth(): void {
        if (this.month === 0) {
            this.setMonth(11, this.year - 1);
        } else {
            this.setMonth(this.month - 1, this.year);
        }
    }

    private generateMonthItems(month: number, year: number): DayItem[] {
        const now = new Date();
        const curMonth = newUtc(year, month, 1);
        const monthLastDay = newUtc(year, month + 1, 0).getUTCDate();

        const items: DayItem[] = [];

        // First fill items[] with days from previous month 
        const firstDayInWeek = getDayOfWeek(curMonth);
        for (let i = 0; i < firstDayInWeek; i++) {
            items.push({
                //label: (prevMonthLastDay - firstDayInWeek + i + 1), 
                label: "",
                type: "out",
                available: false
            });
        }

        // Add all dates from selected date
        for (let i = 1; i <= monthLastDay; i++) {
            let date = newUtc(year, month, i);

            items.push({
                label: i,
                type: "in" + this.comp._selectionClass(date, now),
                available: this.comp._isDateAvailable(date),
                date
            });
        }

        // Fill rest with days from following month
        const totalDaysInCalendar = 7 * 6;
        while (items.length < totalDaysInCalendar) {
            items.push({ label: "", type: "out", available: false });
        }

        return items;
    }
}
export class LeftCalendarMonth extends CalendarMonth {
    override calcNextMonthClass() {
        const movedMonth = newUtc(this.year, this.month + 1, 1);
        const rightMonth = newUtc(this.comp.rightMonth.year, this.comp.rightMonth.month, 1);

        const canMove = movedMonth.getTime() < rightMonth.getTime();

        return canMove ? "available" : "disabled";
    }

    override calcPrevMonthClass() {
        if (!this.comp.minDate) {
            return "available";
        }

        const leftMonth = newUtc(this.year, this.month, 1);

        const canMove = this.comp.minDate.getTime() < leftMonth.getTime();

        return canMove ? "available" : "hidden";
    }

    override setMonth(month: number, year: number): void {
        super.setMonth(month, year);

        this.comp.rightMonth.updateCanMoveFlags();
    }
}
export class RightCalendarMonth extends CalendarMonth {
    override calcNextMonthClass() {
        if (!this.comp.maxDate) {
            return "available";
        }

        const movedMonth = newUtc(this.year, this.month + 1, 1);
        const canMove = movedMonth.getTime() < this.comp.maxDate.getTime();

        return canMove ? "available" : "hidden";
    }

    override calcPrevMonthClass() {
        const movedMonth = newUtc(this.year, this.month - 1, 1);
        const leftMonth = newUtc(this.comp.leftMonth.year, this.comp.leftMonth.month, 1);

        const canMove = movedMonth.getTime() > leftMonth.getTime();

        return canMove ? "" : "disabled";
    }

    override setMonth(month: number, year: number): void {
        super.setMonth(month, year);

        this.comp.leftMonth.updateCanMoveFlags();
    }
}
/**
 * Correct getDay() so that Monday is first day(0) of the week and Sunday 
 * last(6).
 */
function getDayOfWeek(date: Date): number {
    let day = date.getDay();

    return (day === 0) ? 6 : (day - 1);
}