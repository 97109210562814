import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'hy-forgot-password-sent-page',
  templateUrl: './forgot-password-sent-page.component.html',
  styleUrls: ['./forgot-password-sent-page.component.scss']
})
export class ForgotPasswordSentPageComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
