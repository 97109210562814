<hy-request-info *ngIf="showRequestInfo" [type]="loadingStatus" (tryAgain)="tryAgain.emit()">
</hy-request-info>

<ng-container *ngIf="!showRequestInfo">

    <div class="top-buttons hy-fadein">
        <div class="edit" *ngIf="showEditBtn" (click)="edit.emit(this)"></div>
        <div class="move" *ngIf="showMoveBtn"></div>
        <div class="expand" (click)="openFullScreen()"></div>
    </div>

    <div class="selected-title hy-fadein">
        {{_title}} 
        <span class="itooltip offset-10 offset-t5" data-tooltip="Plot Widget pinned value"></span>
    </div>
    <div class="selected-values hy-fadein" [class.negative]="pinnedChange < 0">
        <div class="marker" [style.background]="pinnedColor"></div>
        <div>
            <span class="value" *ngIf="pinnedValueNumber !== undefined">{{pinnedValueNumber | largeNumber}}</span>
            <span class="value" *ngIf="pinnedValueString !== undefined">{{pinnedValueString}}</span>
            <span class="unit" *ngIf="pinnedUnits">{{pinnedUnits}}</span>
            <span class="change" *ngIf="pinnedChange">
                <span class="htooltip right" data-tooltip="Change between the avg. last 24h and the avg, 24h forecast.">
                    {{pinnedChange | number:'1.0-2'}} 
                    ({{pinnedChangePct | number:'1.0-1'}}%)
                </span>
            </span>
        </div>
    </div>
    <div class="chart-container">
        <div class="chart hy-fadein" 
                echarts 
                [options]="chartOptions" 
                (chartInit)="onChartInit($event)"
                theme="hy"> 
        </div>
        <div *ngIf="loadingStatus === 'loading'" class="loading-overlay">Updating plots. Please wait...</div>
    </div>
    <div *ngIf="!live" class="zoom-chart-container">
        <div class="zoom-chart hy-fadein" 
                echarts 
                [options]="zoomChartOptions"
                (chartInit)="onZoomChartInit($event)"
                (chartDataZoom)="onDataZoomChange($event)"
                (mousedown)="onZoomChartMousedown($event)"
                theme="hy"> 
        </div>
        <div *ngIf="dragZoomStartDate" 
            class="zoom-tooltip start"
            [style.left.%]="startZoomTooltipPos">{{dragZoomStartDate | date: 'E, MMM d YYYY\nHH:mm' : '+0000'}}</div>

        <div *ngIf="dragZoomStartDate" 
            class="zoom-tooltip end"
            [style.right.%]="(100-endZoomTooltipPos)">{{dragZoomEndDate | date: 'E, MMM d YYYY\nHH:mm' : '+0000'}}</div>
    </div>
    <div *ngIf="showLegend" 
            class="legend hy-fadein" 
            [class.has-confidence]="hasConfidence"
            [style.minHeight.px]="43 + minLegendRows * 41.5">
        <div class="row">
            <div class="header"></div>
            <div class="header"></div>
            <div class="header">{{metricTitle}}</div>
            <div class="header">{{$any(data[0])?.stats_past_label}}</div>
            <div class="header">{{$any(data[0])?.stats_forecast_label }}</div>
            <div class="header"></div>
            <div class="header" *ngIf="hasConfidence">CONF.INT.</div>
            
        </div>

        <div class="row second-header">
            <div class="header"></div>
            <div class="header"></div>
            <div class="header">{{metricTitle}}</div>
            <div class="header">{{$any(data[0])?.stats_past_label}}</div>
            <div class="header">{{$any(data[0])?.stats_forecast_label }}</div>
            <div class="header"></div>
            <div class="header" *ngIf="hasConfidence">CONF.INT.</div>
        </div>

        <div *ngFor="let item, index as i of data" 
                class="row i" 
                [class.active]="isSeriesActive(i)"
                (click)="toggleSeriesActive(i); $event.stopPropagation()">
            <div class="select">
                <div class="check" [style.background]="getSeriesColor(i)">
                    <svg [innerHTML]="'tick' | fico"></svg>
                </div>
            </div>
            <div class="space"></div>
            <div class="metric">{{$any(item)[metricKey]}}</div>
            <div class="value">{{item.stats.stats_past | largeNumber}} <span class="unit">{{item.units}}</span></div>
            <div class="desc" *ngIf="_secondColumnDescription">{{item.stats.stats_description}}</div>
            <div class="value" *ngIf="!_secondColumnDescription">{{item.stats.stats_forecast | largeNumber}} <span class="unit">{{item.units}}</span></div>
            <div class="pin htooltip" [class.active]="isPinnedSeries(i)" (click)="selectPinnedSeries(i); $event.stopPropagation()" data-tooltip="Pin this data to Plot Widget header">
                <svg [innerHTML]="'pin_icon' | fico"></svg>
            </div>
            <div class="confidence" *ngIf="hasConfidence">
                <hy-toggle class="small" *ngIf="item.confidence_available" [(ngModel)]="item.show_confidence" (change)="updateFromData()"></hy-toggle>
            </div>
        </div>
    </div>
</ng-container>