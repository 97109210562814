<right-sidebar *ngIf="service.opened" 
        [service]="service"
        [title]="'Select Scenario'"
        class="has-header-border">
    
    <div *ngFor="let scenario of configService.scenarios" 
            class="scenario" 
            [class.expanded]="expanded[scenario.id]">

        <div class="scenario-name hy-check-row" 
                [class.active]="scenario.id === selectedScenarioId"
                (click)="select(scenario)">
            <div class="hy-check"></div>
            <div class="name">{{scenario.name}}</div>
            <div class="minimize" (click)="toggleExpanded(scenario); $event.stopPropagation()">
                {{ expanded[scenario.id] ? "MINIMIZE" : "EXPAND" }}
            </div>
        </div>

        <div class="params">
            <div class="header">SCENARIO PARAMETER</div>
            <div class="header">VALUE</div>
            <div class="header">UNIT</div>
            <ng-container *ngFor="let param of scenario.params">
                <div class="name">{{param.name}}</div>
                <div class="value">{{param.value}}</div>
                <div class="unit">{{param.unit}}</div>
            </ng-container>
        </div>
    </div>

    <ng-template #buttons>
        <div class="hy-btn secondary" (click)="cancel()">CANCEL</div>
        <div class="hy-btn" (click)="done()">DONE</div>
    </ng-template>

</right-sidebar>

<hy-password-change-info-dialog></hy-password-change-info-dialog>