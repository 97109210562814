/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';

import { ScenariosSchema } from '../models/scenarios-schema';
import { SiteInfoResponseSchema } from '../models/site-info-response-schema';

@Injectable({ providedIn: 'root' })
export class GeneralService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `healthCheckHealthGet()` */
  static readonly HealthCheckHealthGetPath = '/health';

  /**
   * Health Check.
   *
   * Endpoint used to check the health status of the backend.
   * .. example::
   *    $ curl http://localhost:8000/health
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `healthCheckHealthGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  healthCheckHealthGet$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<any>> {
    const rb = new RequestBuilder(this.rootUrl, GeneralService.HealthCheckHealthGetPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<any>;
      })
    );
  }

  /**
   * Health Check.
   *
   * Endpoint used to check the health status of the backend.
   * .. example::
   *    $ curl http://localhost:8000/health
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `healthCheckHealthGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  healthCheckHealthGet(
    params?: {
    },
    context?: HttpContext
  ): Observable<any> {
    return this.healthCheckHealthGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<any>): any => r.body)
    );
  }

  /** Path part for operation `getScenarioInformationSettingIdSiteInformationGet()` */
  static readonly GetScenarioInformationSettingIdSiteInformationGetPath = '/{setting_id}/site-information';

  /**
   * Get Scenario Information.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getScenarioInformationSettingIdSiteInformationGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  getScenarioInformationSettingIdSiteInformationGet$Response(
    params: {
      setting_id: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<SiteInfoResponseSchema>> {
    const rb = new RequestBuilder(this.rootUrl, GeneralService.GetScenarioInformationSettingIdSiteInformationGetPath, 'get');
    if (params) {
      rb.path('setting_id', params.setting_id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<SiteInfoResponseSchema>;
      })
    );
  }

  /**
   * Get Scenario Information.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getScenarioInformationSettingIdSiteInformationGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getScenarioInformationSettingIdSiteInformationGet(
    params: {
      setting_id: string;
    },
    context?: HttpContext
  ): Observable<SiteInfoResponseSchema> {
    return this.getScenarioInformationSettingIdSiteInformationGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<SiteInfoResponseSchema>): SiteInfoResponseSchema => r.body)
    );
  }

  /** Path part for operation `getScenariosScenariosGet()` */
  static readonly GetScenariosScenariosGetPath = '/scenarios';

  /**
   * Get Scenarios.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getScenariosScenariosGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  getScenariosScenariosGet$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<ScenariosSchema>> {
    const rb = new RequestBuilder(this.rootUrl, GeneralService.GetScenariosScenariosGetPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ScenariosSchema>;
      })
    );
  }

  /**
   * Get Scenarios.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getScenariosScenariosGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getScenariosScenariosGet(
    params?: {
    },
    context?: HttpContext
  ): Observable<ScenariosSchema> {
    return this.getScenariosScenariosGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<ScenariosSchema>): ScenariosSchema => r.body)
    );
  }

}
