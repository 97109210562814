import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { DialogComponent } from 'src/app/modules/shared/dialog/dialog.component';
import { BackendService } from 'src/app/services/backend.service';
import { SettingsSidebarService } from 'src/app/services/settings-sidebar.service';
import { ToastMessageService } from 'src/app/services/toast-message.service';

@Component({
  selector: 'hy-password-change-info-dialog',
  templateUrl: './password-change-info-dialog.component.html',
  styleUrls: ['./password-change-info-dialog.component.scss']
})
export class PasswordChangeInfoDialogComponent {

    @ViewChild(DialogComponent, { static: true })
    dialog!: DialogComponent;

    @Input()
    public address: string = "...";


    resendExecuting = false;

    constructor(
        public settingsSidebarService: SettingsSidebarService,
        private backend: BackendService, 
        private toast: ToastMessageService,
        private router: Router) { }


    sendEmail() {
        this.dialog.show();

        this.resendExecuting = true;
        this.backend.changeUserPassword()
            .then(resp => this.address = resp.email)
            .catch(this.toast.networErrorHandler)
            .finally(() => this.resendExecuting = false);
    }

    backToDash() {
        this.settingsSidebarService.close();
        this.dialog.close();
        this.router.navigate(["/network"]);

    }
}
