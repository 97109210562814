import { formatDate } from '@angular/common';
import { Component, EventEmitter, HostBinding, HostListener, Input, NgZone, OnChanges, Output, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { ECharts, EChartsOption } from 'echarts';
import { MarkLineOption, XAXisOption } from 'echarts/types/dist/shared';
import { Subscription } from 'rxjs';
import { DateRange } from 'src/app/layout/time-slider/time-slider.component';
import { Tick, createChartTicksForTimeRange } from 'src/app/layout/time-slider/timerange-ticks';
import { LoadingStatus } from 'src/app/modules/shared/loading-status';
import { DataPoint } from 'src/app/services/backend.service';
import { ConfigurationService } from 'src/app/services/configuration.service';
import DateUtils, { dateEndOfDay } from 'src/app/util/DateUtils';


export const defaultXAxis = () : XAXisOption => ({
    silent: false,
    type: 'time',
    axisPointer: {
        show: true,
        lineStyle: {
            type: [2, 4],
            width: 2,
            color: "#CFDFF6",
        },
        label: {   
            show: false
        }
    },
    axisLabel: {
        show: true,
        fontFamily: 'DINPro',
        color: "#CFDFF6",
        formatter: " ",
        fontSize: 12,
        lineHeight: 15,
        fontWeight: 500,
        margin: 12,
        align: 'left',
        hideOverlap: true
    },
    axisTick: { show: false },
    splitLine: { show: false },
});


export interface ChartStats {
    stat_main: number | string;
    stat_main_units: string;
    change_abs: number;
    change_pct: number;
    stats_past?: number;
    stats_forecast?: number;
    stats_description?: string;
}

export interface ChartData  {
    data: DataPoint[];
    stats: ChartStats;
    units: string;
    confidence_available?: boolean;
    show_confidence?: boolean;
    stats_past_label?: string;
    stats_forecast_label?: string;
}


@Component({
    templateUrl: './chart-block.component.html',
    styleUrls: ['./chart-block.component.scss'],
    host: {
        '[class.show-request-info]': 'showRequestInfo'
    }
  })
export class ChartBlockComponent implements OnChanges {


    private readonly chartColors = ["#5eaa61", "#00c3ff" ,"#ebff00","#9dcfeb", "#bd6d6d"];

    @Input()
    public id: string = "";

    /**
     * Title of the chart. If title is `undefined`, it will be formatted from
     * pinned series name.
     */
    @Input()
    public title?: string;

    /** Array of series data */
    @Input()
    public zoomData: ChartData[] = [];

    /** Array of series data */
    @Input()
    public data: any;

    @Input()
    @HostBinding('class.live')
    public live: boolean = true;

    @Input()
    public nowDate?: Date;

    /** Minimal date for X axis of main data chart. */
    @Input()
    public fullRangeStart!: Date;

    /** Maximal date for X axis of main data chart. */
    @Input()
    public fullRangeEnd!: Date;

    /** Start of zoom selection. */
    @Input()
    public zoomStart!: Date;

    /** End of zoom selection. */
    @Input()
    public zoomEnd!: Date;

    @Input()
    public secondaryMarkDate?: Date;

    /** Title of metric column */
    @Input()
    public metricTitle: string = "";

    /** Name of the key in {data} that defines name of the metric in series. */
    @Input()
    public metricKey: string = "metric";

    /** Defines if time is displayed in tooltips. */
    @Input()
    public showTimeInTooltips: boolean = true;

    /** Router link used for expand icon. */
    @Input()
    public expandRouterLink: string = "/network/storage";

    /**
     * Defines minimal height of legend row. It is used to align multiple charts
     * to same height
     * */
    @Input()
    public minLegendRows: number = 0;

    @Input({ required: true })
    public loadingStatus: LoadingStatus = LoadingStatus.Loading;

    /** Defines if legend is displayed below the chart. */
    @Input()
    public showLegend: boolean = true;

    /** Defines if Edit button is displayed. */
    @Input()
    public showEditBtn: boolean = false;

    /** Defines if Move button is displayed. */
    @Input()
    public showMoveBtn: boolean = false;

    @Output()
    public edit = new EventEmitter<ChartBlockComponent>();

    @Output()
    public tryAgain = new EventEmitter<void>();

    @Output()
    public clickDate = new EventEmitter<Date>();

    /** Triggered when user moves selection on zoom chart. */
    @Output()
    public zoomSelection = new EventEmitter<DateRange>();

    get showRequestInfo() {
        const showChart = this.loadingStatus === 'loaded'
            || (this.loadingStatus === 'loading' && !!this.data);
        return !showChart;
    }


    /** Indexes of storage series that are active for display. */
    private activeSeries: number[] = [];

    protected pinnedSeriesIndex = 0;

    chartOptions: EChartsOption = {};

    zoomChartOptions: EChartsOption = {};

    /**
     * Calculated title. It will be `title` if it is defined, else it is
     * formatted from pinned series name.
     */
    _title = "";

    /** Defines if second column is description or forecasts value */
    _secondColumnDescription = false;

    pinnedColor = "";
    /** Pinned value if it is a number. */
    pinnedValueNumber?:  number;
    /** Pinned value if it is a string. */
    pinnedValueString?:  string;
    pinnedUnits = "";
    pinnedChange = 0;
    pinnedChangePct = 0;

    /**
     * Calculated form data. Is true if at least one series has
     * `confidence_available`
     */
    hasConfidence = false;

    log = console.log;

    private chartConfigClearedSubscription: Subscription;

    constructor(protected config: ConfigurationService, 
            private router: Router,
            private zone: NgZone) {
        this.chartConfigClearedSubscription = config.chartConfigCleared.subscribe(x => this.updateFromData());
    }

    ngOnDestroy() {
        this.chartConfigClearedSubscription.unsubscribe();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.zoomStart || changes.zoomEnd) {
            this.dragZoomStartDate = undefined;
            this.dragZoomEndDate = undefined;
        }

        this.updateFromData();
    }
    openFullScreen() {
        this.router.navigate(
            [this.expandRouterLink], 
            {
                state: {
                    zoomStart: this.zoomStart.getTime(),
                    zoomEnd: this.zoomEnd.getTime(),
                }
            });
    }

    onChartInit(ec:any ) {
        // Add click handler that will emit clickDate whit X axis value at location of the click
        ec.getZr().on('click', (params: any) => this.zone.run(() => {
            const pointInPixel = [params.offsetX, params.offsetY];
            const pointInGrid = ec.convertFromPixel('grid', pointInPixel);

            const date = new Date(pointInGrid[0]);

            if (!isNaN(date.getTime())) {
                this.clickDate.emit(date);
            }
        }));
    }

    private zoomChartEc?: ECharts
    onZoomChartInit(ec: ECharts) {
        this.zoomChartEc = ec;
    }

    onZoomChartMousedown(event: MouseEvent) {
        this.zoomChartMouseDown = true;
    }

    @HostListener('document:mouseup')
    onZoomChartMouseup(event: MouseEvent) {
        setTimeout(() => {
            // when just clicking one point on zoom chart, mouse up is triggered before drag event, so we use timeout to 
            // make sure we capture the drag event before handling zoom move
            if (this.zoomChartMouseDown && this.dragZoomStartDate && this.dragZoomEndDate) {
                this.zoomSelection.emit({
                    start: this.dragZoomStartDate,
                    end: this.dragZoomEndDate
                })
            }
            
            this.zoomChartMouseDown = false;
            this.dragZoomStartDate = undefined;
            this.dragZoomEndDate = undefined;
        }, 0);

    }


    zoomChartMouseDown = false;
    startZoomTooltipPos: number = 0;
    endZoomTooltipPos: number = 100;
    dragZoomStartDate?: Date = new Date();
    dragZoomEndDate?: Date = new Date();
    //endZoomTooltipPos: number ;


    onDataZoomChange(event: any) {
        const dz = (<any>this.zoomChartEc!.getOption())?.dataZoom[0];

        this.startZoomTooltipPos = event.start;
        this.endZoomTooltipPos = event.end;

        this.dragZoomStartDate = new Date(dz.startValue);
        this.dragZoomEndDate = new Date(dz.endValue);
    }

    updateFromData() {
        if (!this.data || this.data.length === 0) {
            return;
        }

        this.hasConfidence = this.data.some((x: any) => x.confidence_available);

        this.pinnedSeriesIndex = this.config.getChartPinnedSeries(this.id);
        if (this.pinnedSeriesIndex >= this.data.length) {
            // If backend starts returning less series for this chart ID, we
            // reset pinned index, so that we do not cause index out of bounds
            // error
            this.pinnedSeriesIndex = 0;
        }
        this.activeSeries = this.getChartActiveSeries();

        this.updatePinnedValues();
        this.updateChartOptions();
        

        if (this.data.length > 0) {
            // stats_forecast_label property is title of second column when in description mode handling of second column
            const stats_forecast = this.data[0].stats.stats_forecast;
            this._secondColumnDescription = stats_forecast === null 
                                         || stats_forecast === undefined;
        }
        else {
            this._secondColumnDescription = false;
        }
    }

    protected getChartActiveSeries() : number[] {
        const value = this.config.getChartActiveSeries(this.id);

        if (Array.isArray(value)) {
            return value.filter((index: number) => {
                return index >= 0 && index < this.data.length;
            })
        }
        else {
            return [];
        }
    }

    protected updateChartOptions(): void {
    };

    protected updateZoomChartOptions(): void {
    }

    private updatePinnedValues() {
        const series = this.data[this.pinnedSeriesIndex];

        if (this.title) {
            this._title = this.title;
        }
        else {
            this._title = 
                (this.live ? "Current " + (<any>series)[this.metricKey]?.toLowerCase() : (<any>series)[this.metricKey])
                + " status";
        }

        this.pinnedColor = this.getSeriesColor(this.pinnedSeriesIndex);
        if (typeof series.stats.stat_main === "number") {
            this.pinnedValueNumber = series.stats.stat_main;
            this.pinnedValueString = undefined;
        }
        else {
            this.pinnedValueNumber = undefined;
            this.pinnedValueString = series.stats.stat_main ?? "-";
        }
        
        this.pinnedUnits = series.stats.stat_main_units ?? series.units;
        this.pinnedChange = series.stats.change_abs;
        this.pinnedChangePct = series.stats.change_pct;
    }

    isSeriesActive(index: number) {
        if (this.activeSeries.length === 0) {
            return true;
        }

        return this.activeSeries.includes(index);
    }

    toggleSeriesActive(index: number) {
        let active = this.getEffectiveActiveSeries();

        if (active.includes(index)) {
            active = active.filter(x => x !== index);
        } else {
            active.push(index);
        }

        this.activeSeries = active;
        this.config.setChartActiveSeries(this.id, this.activeSeries);

        this.updateChartOptions();
    }

    selectPinnedSeries(index: number) {
        if (this.pinnedSeriesIndex !== index) {
            this.config.setChartPinnedSeries(this.id, index);
            this.pinnedSeriesIndex = index;
            this.updatePinnedValues();
            this.updateZoomChartOptions();
        }
    }

    isPinnedSeries(index: number) {
        return this.pinnedSeriesIndex === index;
    }

    protected getEffectiveActiveSeries() {
        if (this.activeSeries.length === 0) {
            // If there are no series in active list, then we consider all of
            // them as active
            const active = [];
            for (let i=0; i < this.data.length; i++) {
                active.push(i);
            }

            return active;
        }
        else {
            return this.activeSeries;
        }
    }

    getSeriesColor(index: number): string {
        return this.chartColors[index % this.chartColors.length];
    }

    private createNowMarkLine() : MarkLineOption | undefined {
        if (!this.live || !this.nowDate) {
            return undefined;
        }

        return this.createVerticalMarkLine(this.nowDate, "NOW");
    }

    private createSecondaryMarkLine() : MarkLineOption | undefined {
        if (!this.secondaryMarkDate) {
            return undefined;
        }

        return this.createVerticalMarkLine(this.secondaryMarkDate, "");
    }

    private createVerticalMarkLine(date: Date, label: string) : MarkLineOption {
        return {
            silent: true,
            symbol: 'none',
            data: [
                { xAxis: DateUtils.formatAsDataSource(date) }
            ],
            lineStyle: {
                type: [2, 4],
                width: 2,
                color: "#EBFF00"
            },
            label: {
                show: label.length > 0,
                position: "start",
                formatter: () => label,
                color: "#BFD006",
                fontSize: 12,
                distance: 12,
                backgroundColor: "#13151D",
                padding: [0, 5, 20, 5]
            }
        };
    }

    protected addMarkLines(options: EChartsOption) {
        if (!Array.isArray(options.series)) {
            return;
        }

        this.addTickMarkLines(options);

        if (this.nowDate) {
            options.series.push({
                    type: 'line',
                    data: [],
                    animation: false,
                    markLine: <any>this.createNowMarkLine(),
            });
        }

        if (this.secondaryMarkDate) {
            options.series.push({
                    type: 'line',
                    data: [],
                    animation: false,
                    markLine: <any>this.createSecondaryMarkLine(),
            });
        }

        
    }

    private addTickMarkLines(options: EChartsOption) {
        if (!Array.isArray(options.series)) {
            return;
        }

        const ticks = createChartTicksForTimeRange(this.zoomStart, this.zoomEnd);
        const ticksDs = ticks.map(tick => ({
            xAxis: DateUtils.formatAsDataSource(tick.startDate),
            label: tick.label
        }));

        options.series.push({
            type: 'line',
            data: [],
            animation: false,
            markLine: <any>{
                silent: true,
                symbol: 'none',
                data: ticksDs,
                lineStyle: {
                    type: [1, 0],
                    width: 1,
                    "color": "#383e48"
                },
                label: {
                    position: "start",
                    padding: [8, 0, 0, 0],
                    formatter: (a: any) => {
                        return a.data.label;
                    },
                    fontFamily: 'DINPro',
                    color: "#CFDFF6",
                    fontSize: 12,
                    lineHeight: 15,
                    fontWeight: 500,
                    margin: 12,
                    align: 'left',
                    hideOverlap: true
                }
            },
        });
    }

}

export function xAxisFormatter(value: number) : string {
    const date = new Date(value);
    const isStartOfDay = date.getUTCHours() == 0 && date.getUTCMinutes() == 0 && date.getSeconds() == 0;

    const time = formatDate(date, "HH:mm", "en-UK", "+0000");

    if (time == "00:00") {
        return formatDate(date, "ccc,\nMMM dd", "en-UK", "+0000");
    }
    else {
        return time;
    }
}

export function calcAxisMax(start?: Date, end?: Date) {
    if (end) {
        return calcAxisMaxImpl(end);
    }
    else if (start) {
        return calcAxisMaxImpl(start);
    }
    else {
        return undefined;
    }
}

export function calcAxisMaxImpl(date: Date) {
    const max = dateEndOfDay(date).getTime();

    const now = Date.now();

    return (max > now) ? now : max;
}


