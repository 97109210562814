import { formatDate } from '@angular/common';
import { Component, ElementRef, EventEmitter, HostListener, Input, Output, SimpleChanges } from '@angular/core';
import { ConfigurationService } from 'src/app/services/configuration.service';
import { MilisInDay, MilisInMinute, MinutesInDay } from 'src/app/util/DateUtils';
import { Tick, addDay, createSliderTicksForTimeRange } from './timerange-ticks';




export interface DateRange {
    start: Date;
    end: Date;
}

interface AvailabilityRange {
    start?: Date;
    end?: Date;
    size: number;
    available: boolean;
}



@Component({
  selector: 'hy-time-slider',
  templateUrl: './time-slider.component.html',
  styleUrls: ['./time-slider.component.scss']
})
export class TimeSliderComponent {
    /** Oldest date that can be selected */
    @Input()
    public startDate!: Date;

    /** Last date that can be selected */
    @Input()
    public endDate?: Date;
    
    @Input()
    @Output()
    public selectedDate?: Date;

    @Input() 
    public unavailableDateRanges: DateRange[] = [];

    @Output()
    public selectedDateChanged = new EventEmitter<Date>();

    numDays: number = 0;

    ticks: Tick[] = [];

    availabilityRanges: AvailabilityRange[] = [];

    /** Location of the handle on the slider. */
    handleOffsetPercent = 0;
    handleLabel = "";

    ghostHandleOffsetPercent?: number;
    ghostHandleLabel = "";

    
    constructor(private elRef: ElementRef<HTMLElement>, private configService: ConfigurationService) {
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.startDate || changes.endDate || changes.unavailableDateRanges) {
            this.updateFromDates();
            this.availabilityRanges = this.buildAvailabilityRanges();
            this.updateHandleOffsetForDate();
        }
    }

    selectDate(date: Date, emitEvent: boolean = true) {
        if (date.getTime() < this.startDate.getTime()) {
            console.error("Date before slider start", date, this.startDate);
            this.selectedDate = new Date(this.startDate);
        }
        else {
            this.selectedDate = new Date(date);
        }

        this.updateHandleOffsetForDate();
        this.updateSelectedTick();

        if(emitEvent) {
            this.selectedDateChanged.emit(this.selectedDate);
        }
    }


    /** Constructs availability ranges from unavailableDateRanges */
    private buildAvailabilityRanges() {
        const start = this.startDate;
        const end = addDay(this.endDate ?? this.startDate);

        const uaRanges = this.unavailableDateRanges ?? [];
        if (uaRanges.length === 0) {
            return [{
                size: 1,
                available: true
            }];
        }

        const aRanges = new Array<AvailabilityRange>();

        const firstUa = uaRanges[0];
        let lastSegmentEnd = firstUa.start;
        if (firstUa.start.getTime() > start.getTime()) {
            // if first unavailable date is after sider start date, we insert 
            // available period until start of first unavailable date
            aRanges.push(createAvailabilitySegment(start, firstUa.start, true));
            lastSegmentEnd = firstUa.start;
            
        }
        else {
            lastSegmentEnd = start;
        }

        for (let i = 0; i < uaRanges.length; i++) {
            const uaRange = uaRanges[i];

            if (uaRange.start.getTime() > lastSegmentEnd.getTime()) {
                // Add available period after last unavailable and before next
                
                aRanges.push(createAvailabilitySegment(lastSegmentEnd, uaRange.start, true));
            }

            const startBeforeRange = uaRange.start.getTime() < start.getTime();
            const endAfterRange = uaRange.end.getTime() > end.getTime();
            
            const segmentStart = startBeforeRange? start : uaRange.start;
            const segmentEnd = endAfterRange ? end : uaRange.end;
            aRanges.push(createAvailabilitySegment(segmentStart, segmentEnd, false));

            lastSegmentEnd = segmentEnd;
        }

        // add trailing segment for available data after last unavailable segment
        if (lastSegmentEnd.getTime() < end.getTime()) {
            aRanges.push(createAvailabilitySegment(lastSegmentEnd, end, true));
        }

        return aRanges;
    }

    private updateHandleOffsetForDate() {
        if (this.selectedDate) {
            this.handleOffsetPercent = this.calcHandleOffsetForDate(this.selectedDate) * 100;
            this.handleOffsetPercent = Math.min(this.handleOffsetPercent, 100);
        }
        else {
            this.handleOffsetPercent = 0;
        }
    }

    private calcHandleOffsetForDate(date: Date) {
        const dateTick = this.tickForDate(date);

        const tickOffset = this.calcTickOffset(dateTick);

        const tickTimeOffset = date.getTime() - dateTick.startDate.getTime();

        const insideTickOffset = tickTimeOffset / (MilisInDay * this.numDays);
    
        return tickOffset + insideTickOffset;
    }

    private calcTickOffset(offsetTick: Tick) {
        const ticks = this.ticks;
        let sum = 0;
        for (let i=0; i < ticks.length ; i++) {
            const tick = ticks[i];
            if (tick === offsetTick) {
                break;
            }

            sum += ticks[i].size;
        }

        return sum / this.numDays;
    }

    private tickForDate(date: Date) {
        const ticks = this.ticks;
        for (let i=0; i < (ticks.length - 1); i++) {
            const tick = ticks[i];
            const nextTick = ticks[i + 1];

            // if selected date is between tick start date and next tick start 
            // date, tick is selected
            if (date.getTime() >= tick.startDate.getTime()
                    && date.getTime() < nextTick.startDate.getTime()) {
                
                return tick;
            }
        }

        // no of the previous tick is selected, so we select the last one
        const lastTick = ticks[ticks.length - 1];
        
        return lastTick;
    }

    private updateSelectedTick() {
        const ticks = this.ticks;
        ticks.forEach(x => x.selected = false);

        if (!this.selectedDate) {
            ticks[0].selected = true;
            return;
        }


        const tick = this.tickForDate(this.selectedDate);
        tick.selected = true;
    }

    focusToTick(tick: Tick, event: MouseEvent) {
        event.preventDefault();
        event.stopPropagation();

        this.selectDate(tick.startDate, true);
    }

    updateFromDates() {
        const start = this.startDate;
        const end = addDay(this.endDate ?? this.startDate);

        this.numDays = Math.floor((end.getTime() - start.getTime()) / MilisInDay);

        this.ticks = createSliderTicksForTimeRange(start, end);

        const totalSize = this.ticks.reduce((val, tick) => val + tick.size, 0);

        if (totalSize !== this.numDays) {
            console.error("numDays and totalSize don't match", this.numDays * 24, totalSize);
        }

        setTimeout(() => {
            this.updateLabelVisibility();
        });
    }

    @HostListener("window:resize")
    private updateLabelVisibility() {
        const container =this.elRef.nativeElement;
        const labelEls = this.elRef.nativeElement.querySelectorAll<HTMLElement>('.tick .label');

        if (!labelEls || labelEls.length === 0) {
            return;
        }

        let lastVisible = labelEls[0];

        for (let i=1; i < labelEls.length; i++) {
            const labelEl = labelEls[i];
            const hidden = elementsOverlap(lastVisible, labelEl)
                        || !elementsInsideX(labelEl, container);

            if (hidden) {
                labelEl.style.visibility = 'hidden';
            }
            else {
                labelEl.style.visibility = 'visible';
                lastVisible = labelEl;
            }
        }

    }

    

    // ----- Start: Mouse handling ------------------------------------------ //

    private dragStartedX = 0;

    get isBeingDragged() {
        return this.dragStartedX !== 0;
    }

    onTicksMousemove(event: MouseEvent) {
        if (!this.dragStartedX) {
            const percent = this.getPercentFromLocation(event);
            this.ghostHandleOffsetPercent = percent * 100;
            this.ghostHandleLabel = formatDate(this.findDateFromPercent(percent), "ccc, MMM dd HH:mm", "en", "+0000");
        }
    }

    onTicksMouseout(event: MouseEvent) {
        this.ghostHandleOffsetPercent = undefined;
    }

    onMousedown(event: MouseEvent | Touch) {
        this.dragStartedX = this.getMouseRelativeX(event);
    }

    @HostListener('document:mousemove', ['$event'])
    onMousemove(event: MouseEvent) {
        if (this.dragStartedX) {
            this.updateDateFromLocation(event);
        }
    }

    @HostListener('document:mouseup')
    @HostListener('document:touchcancel')
    @HostListener('document:touchend')
    onMouseup() {
        if (this.dragStartedX !== 0) {
            this.selectedDateChanged.emit(this.selectedDate);
            this.dragStartedX = 0;
        }
    }

    // ----- Start: Touch handling ------------------------------------------ //

    onTouchstart(event: TouchEvent) {
        if (event.touches.length == 1) {
            this.dragStartedX = this.getMouseRelativeX(event.touches[0]);
            event.preventDefault();
        }
    }

    //@HostListener('document:touchmove', ['$event'])
    onTouchmove(event: any) {
        this.onMousemove(event.touches[0]);
    }

    // ----- Start: Touch handling ------------------------------------------ //

    onTicksClick(event: MouseEvent) {
        this.updateDateFromLocation(event);
        this.selectDate(this.selectedDate!);
    }
    
    // ----- Start: General handlers ---------------------------------------- //

    private updateDateFromLocation(event: MouseEvent | Touch) {
        const date = this.findDateFromPercent(this.getPercentFromLocation(event));
        this.selectedDate = date;


        this.updateHandleOffsetForDate();
    }

    private findDateFromPercent(percent: number) {
        const tick = this.getTickForPctOffset(percent);

        const insideTickOffsetPct = percent - tick.pctStart;
        
        const pctOfTick = insideTickOffsetPct / tick.pct;

        const granularityInTick = tick.size * MinutesInDay / this.configService.granularity;

        const granularityOffset = Math.round(pctOfTick * granularityInTick);

        return new Date(tick.startDate.getTime() + granularityOffset * this.configService.granularity * MilisInMinute);
    }

    private getPercentFromLocation(event: MouseEvent | Touch) {
        const relativeOffset = this.getMouseRelativeX(event)
        const width = this.elRef.nativeElement.offsetWidth;
        // offset needs to be between 0 and width
        const offset = Math.max(Math.min(relativeOffset, width), 0);

        return offset / width;
    }


    private getTickForPctOffset(pctOffset: number) {
        const tick = this.ticks.find(tick => tick.pctStart <= pctOffset && pctOffset < tick.pctEnd);

        if (tick) {
            return tick;
        }
        else if (pctOffset < 0) {
            return this.ticks[0];
        }
        else {
            return this.ticks[this.ticks.length - 1];
        }
    }


    getMouseRelativeX(event: MouseEvent | Touch) {
        var rect = this.elRef.nativeElement.getBoundingClientRect();

        return event.clientX - rect.left;
    }
}









function elementsOverlap(el1: Element, el2: Element) {
    const domRect1 = el1.getBoundingClientRect();
    const domRect2 = el2.getBoundingClientRect();

    return !(
        domRect1.top > domRect2.bottom ||
        domRect1.right < domRect2.left ||
        domRect1.bottom < domRect2.top ||
        domRect1.left > domRect2.right
    );
}

function elementsInsideX(container: Element, contained: Element) {
    var rect1 = container.getBoundingClientRect();
    var rect2 = contained.getBoundingClientRect();

    return (
      ((rect2.left <= rect1.left) && (rect1.left <= rect2.right)) &&
      ((rect2.left <= rect1.right) && (rect1.right <= rect2.right))
    );
}



function createAvailabilitySegment(start: Date, end: Date, available: boolean) {
    return {
        start, 
        end, 
        available, 
        size: Math.floor((end.getTime() - start.getTime()) / MilisInMinute)
    };
    
}