import { Component, OnInit } from '@angular/core';
import { DateRange } from 'src/app/layout/time-slider/time-slider.component';
import { LoadingStatus } from 'src/app/modules/shared/loading-status';
import { BackendService } from 'src/app/services/backend.service';
import { CachedBackendService } from 'src/app/services/cached-backend.service';
import { DateSelectionHeaderService } from 'src/app/services/date-selection-header.service';
import { ToastMessageService } from 'src/app/services/toast-message.service';
import { ChartLoadingSupport } from 'src/app/util/ChartLoadingSupport';

@Component({
  selector: 'hy-metrics-page',
  templateUrl: './metrics-page.component.html',
  styleUrls: ['./metrics-page.component.scss'],
  host: { '[class.double-charts-page]': 'true' }
})
export class MetricsPageComponent implements OnInit {

    loadingStatus: LoadingStatus = LoadingStatus.Loading;

    zoomChartData?: any;
    data?: any;

    minLegendRows = 0;

    showTimeInTooltips = true;

    cls: ChartLoadingSupport;

    constructor(
            private backendService: BackendService,
            private toast: ToastMessageService,
            public dateSelectionService: DateSelectionHeaderService) {

        this.cls = new ChartLoadingSupport(this.dateSelectionService);
    }

    ngOnInit() {
        this.loadChartData();
    }

    private loadChartData() {
        this.loadingStatus = LoadingStatus.Loading;
        this.backendService
            .getMetrics(this.cls.getFilter())
            .then(data => {
                if (data) {
                    if (!this.zoomChartData) {
                        this.zoomChartData = data;
                    }
                    this.loadingStatus = LoadingStatus.Loaded;
                    this.data = data;
                    this.dateSelectionService.setNextUpdateTime(new Date(data.next_update_time));
                    this.minLegendRows = Math.max(data.hydrogen.length, data.profit.length);
                    this.showTimeInTooltips = (data.show_time !== false);
                }
                else {
                    this.loadingStatus = LoadingStatus.NoData;
                    this.data = undefined;
                    return;
                }
            })
            .catch(() => this.loadingStatus = LoadingStatus.Failed);
    }

    
    reload() {
        if (this.backendService instanceof CachedBackendService) {
            this.backendService.clearMetricsCache();
        }
        this.loadChartData();
    }

    selectedDateChanged() {
        this.cls.selectedDateChanged();
        this.zoomChartData = undefined;
        this.loadChartData();
    }


    onZoomSelection(range: DateRange) {
        this.cls.onZoomSelection(range);
        this.loadChartData();
    }

}
