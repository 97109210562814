<hy-sidebar></hy-sidebar>

<div #contentEl class="content">
    <div class="toasts">
        <div class="toast" *ngFor="let t of toast.messages">
            <div class="spacer"></div>
            
            <div class="text">
                <!-- Embeded svg as the error is displayed when there is no internet connection. -->
                <svg class="alert-icon" width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect x="14" y="10" width="2" height="8" style="fill:var(--color, currentcolor)"/>
                    <rect width="2" height="2" transform="matrix(1 0 0 -1 14 21)" style="fill:var(--color, currentcolor)"/>
                    <rect x="1" y="1" width="28" height="28" rx="14" style="stroke:var(--color, currentcolor);vector-effect: non-scaling-stroke;" stroke-width="2"/>
                </svg>
                {{t}}
            </div>
            <div class="dismiss" (click)="dismissToast(t)">
                DISMISS
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect x="2.34375" y="1" width="7" height="2" transform="rotate(45 2.34375 1)" style="fill:var(--color, currentcolor)"/>
                    <rect x="10.1211" y="8.77832" width="7" height="2" transform="rotate(45 10.1211 8.77832)" style="fill:var(--color, currentcolor)"/>
                    <rect x="0.929688" y="13.7285" width="7" height="2" transform="rotate(-45 0.929688 13.7285)" style="fill:var(--color, currentcolor)"/>
                    <rect x="8.70703" y="5.9502" width="7" height="2" transform="rotate(-45 8.70703 5.9502)" style="fill:var(--color, currentcolor)"/>
                </svg>
            </div>
        </div>
    </div>
    <router-outlet (activate)="onRouteActivate()"></router-outlet>
</div>

<hy-double-field-input-dialog *ngIf="inputService.dialogOpened"></hy-double-field-input-dialog>

<hy-settings-sidebar *ngIf="settingsSidebarService.opened"></hy-settings-sidebar>
<hy-notifications-sidebar *ngIf="notificationsSidebarService.opened"></hy-notifications-sidebar>
<hy-scenario-selection-sidebar></hy-scenario-selection-sidebar>