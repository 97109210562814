<simple-page-header title="About Us"></simple-page-header>

<hy-request-info [type]="loadingStatus" (tryAgain)="loadSiteInformation()"></hy-request-info>

<div class="content" *ngIf="siteInfo">

    <div class="left">
        <div class="nav" *ngFor="let tab of siteInfo.tabs" (click)="selectedTab = tab" [class.active]="selectedTab === tab">{{tab.title}}</div>
    </div>

    <div *ngIf="selectedTab" class="center">
        <h1>{{selectedTab.title}}</h1>

        <div class="text" [innerHTML]="selectedTab.text | safeHtml"></div>

        <div class="stats">
            <div class="stat"  *ngFor="let kpi of selectedTab.kpis">
                <div class="value">{{kpi.value}} <span class="units">{{kpi.units}}</span></div>
                <div class="label">{{kpi.name}}</div>
            </div>
        </div>
    </div>


    <div class="right">
        <div class="support">
            <h2>Support</h2>
            <div class="desc">{{siteInfo.support_text}}</div>

            <div class="label">EMAIL SUPPORT</div>
            <div class="email"><a href="mailto:{{siteInfo.support_email}}">{{siteInfo.support_email}}</a></div>
        </div>
    </div>
<!--
    <div class="left">
        <div class="nav" (click)="page = 1" [class.active]="page == 1">HyAI</div>
        <div class="nav" (click)="page = 2" [class.active]="page == 2">Whitelee energy system</div>
        <div class="nav" (click)="page = 3" [class.active]="page == 3">Web Application</div>
    </div>

    <div *ngIf="page == 1" class="center">
        <h1>HyAI</h1>
        <p>HyAI is H2GO Power's cloud platform used to optimally manage hydrogen
            energy systems. It uses proprietary state-of-the-art Machine Learning
            and Optimisation algorithms to make intelligent,
            data-driven decisions that maximise the monetisation of hydrogen energy
            systems.</p>
        <p>As an end-to-end hydrogen asset management solution, HyAI integrates a
            variety of data sources in real time, dynamically plans on-the-go and
            can be fully integrated for autonomous control. The modular design of
            the platform also makes it highly customisable to a variety of hydrogen
            storage technologies, and a diverse portfolio of infrastructure projects
            and business requirements.</p>

        <p>HyAI has been developed by a c0ross-functional team of experts with
            backgrounds in machine learning, operations research, software
            engineering, chemistry and electrical systems integration. We have taken
            great care to build a powerful, detailed model that accurately captures
            each process in the hydrogen value chain.</p>
    </div>

    <div *ngIf="page == 2" class="center">
        <h1>Whitelee energy system</h1>
        <p>Whitelee is the largest on-shore wind farm in the United Kingdom with
            215 wind turbines and a total capacity of 539 MW, with an average of
            2.5 MW per turbine.</p>
        <p>The energy system modelled by HyAI is an hypothetical configuration
            that includes H2GO Power's hydrogen storage asset, HySTOR.</p>

        <div class="stats">
            <div class="stat">
                <div class="value">2015</div>
                <div class="label">Wind turbines</div>
            </div>
            <div class="stat">
                <div class="value">539 <span class="units">MW</span></div>
                <div class="label">Total capacity</div>
            </div>
            <div class="stat">
                <div class="value">2.5 <span class="units">MW</span></div>
                <div class="label">avg. per turbine</div>
            </div>
        </div>
    </div>


    <div *ngIf="page == 3" class="center">
        <h1>Whitelee energy system</h1>
        <p>This web application provides information on:</p>

        <p>● the real-time status of the energy system<br>
            ● the schedule created by HyAI for the different system assets<br>
            ● the forecasts produced by HyAI to generate optimal schedules<br>
            ● profitability metrics</p>

<p>The web application is updated every 30 minutes.</p>
    </div>


    <div class="right">
        <div class="support">
            <h2>Support</h2>
            <div class="desc">If you experience any issue with the app, or if you simply want to get in touch with our support team, please contact via email at the following address:</div>

            <div class="label">EMAIL SUPPORT</div>
            <div class="email">info@h2gopower.com</div>
        </div>
    </div>

-->

</div>
