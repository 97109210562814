
<div class="dialog" (click)="$event.stopPropagation()">
    <ng-container *ngIf="visible">
        <div class="content">
            <div class="esc-container">
                <div class="esc" (click)="close()">
                    <span>ESC</span> 
                    <svg [innerHTML]="'collapse' | fico"></svg>
                </div>
            </div>

            <ng-content></ng-content>
        </div>
    </ng-container>
</div>