import { Pipe, PipeTransform } from '@angular/core';


const oneK = 1000;
const oneM = 1000000;
const oneB = 1000000000;

@Pipe({
  name: 'largeNumber'
})
export class LargeNumberPipe implements PipeTransform {


    public transform(value: number): string {
        return formatLargeNumber(value);
    }
}


export function formatLargeNumber(value: number) {
    if (value < oneK) {
        return value.toLocaleString("en", { maximumFractionDigits: 2 });
    } 
    else if (value < oneM) {
        return value.toLocaleString("en", { maximumFractionDigits: 0 });
    }
    else if (value < oneB) {
        return (value / oneM).toLocaleString("en", { maximumFractionDigits: 2 }) + "M";
    }
    else {
        return (value / oneB).toLocaleString("en", { maximumFractionDigits: 2 }) + "B";
    }
}