<hy-date-selection-header #header
    title="Monitoring"
    (selectedDateChanged)="selectedDateChanged()"
    stickyClass="stuck"
    class="sticky">
</hy-date-selection-header>


<hy-request-info
    *ngIf="signalsLoadingStatus !== 'loaded'"
    class="hy-fadein"
    [type]="signalsLoadingStatus"
    (tryAgain)="forceLoadMonitoringData()">
</hy-request-info>

<div *ngIf="signalsLoadingStatus === 'loaded'" class="content hy-fadein">
    <div>
        <h2>Alert outputs</h2>
        <div class="signals-header">
            &nbsp;
        </div>
        
        <hy-line-chart-block 
            id="monitoring"
            [zoomData]="zoomChartData ? [$any(zoomChartData)] : $any(undefined)"
            [data]="chartData ? [$any(chartData)] : undefined"
            [live]="dateSelectionService.liveModeSelected"
            [nowDate]="undefined"
            [fullRangeStart]="cls.fullRangeStart"
            [fullRangeEnd]="cls.fullRangeEnd"
            [zoomStart]="cls.zoomStartDate"
            [zoomEnd]="cls.zoomEndDate"
            [loadingStatus]="chartLoadingStatus"
            [alertRule]="selectedSignal?.alert_enabled ? selectedSignal?.alert_rule : undefined"
            metricKey="signal_name"
            expandRouterLink="/monitoring/chart/{{selectedDevice?.node_id}}/{{selectedSignal?.signal_id}}"
            [showLegend]="false"
            class="vstretch"
            (tryAgain)="loadChart(selectedSignal!)"
            (zoomSelection)="onZoomSelection($event)">
        </hy-line-chart-block>
    </div>
    <div>
        <h2>Components manager</h2>
        <div class="signals-header">
            <div></div>
            <div>DEVICE</div>
            <div>ALERT CONDITION</div>
            <div></div>
        </div>

        <ng-container *ngFor="let device of signals?.devices; let i=index">
            <div class="device">{{device.node_name}}</div>

            <div *ngFor="let signal of device.signals" class="signal" [class.active]="signal === selectedSignal" (click)="selectSignal(device, signal)">
                <div class="select">
                    <div class="check" [style.background]="'#4CA256'">
                        <svg width="12" height="9" viewBox="0 0 12 9" fill="none">
                            <path d="M1 5L4 8L11 1" stroke="#0C0E17" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                    </div>
                </div>
                <div class="name">{{signal.signal_name}}</div>
                <div class="alert-conf" *ngIf="signal.alert_enabled" >{{formatAlertCondition(signal)}}</div>
                <div class="alert-conf" *ngIf="!signal.alert_enabled"></div>
                <div>
                    <div *ngIf="signal.alert_ongoing" class="alert hy-btn no-outline small" (click)="viewWarnings(device, signal)">
                        <span class="ico-24"></span>VIEW WARNINGS
                    </div>
                </div>
                <div>
                    <div *ngIf="signal.alert_ongoing" class="dismiss hy-btn no-outline small" (click)="dismissWarnings(device, signal)">DISMISS ALL</div>
                </div>
                <div class="action">
                    <div *ngIf="!signal.alert_enabled" class="add hy-btn outline small" (click)="editAlert(device, signal, $event)">
                        <span class="ico"></span>
                        ADD
                    </div>

                    <div *ngIf="signal.alert_enabled" class="edit hy-btn outline small" (click)="editAlert(device, signal, $event)">
                        <span class="ico"></span>
                        EDIT
                    </div>

                </div>
            </div>

        </ng-container>
    </div>
</div>

<monitoring-alert-editor-sidebar 
    *ngIf="editorSidebar.opened" 
    [service]="editorSidebar"
    (updated)="loadSignals()">
</monitoring-alert-editor-sidebar>