import { Injectable } from "@angular/core";

/**
 * @returns If true, delegate will be removed from the stack after execution.
 */
type EscKeyDelegate = () => boolean | void;

export class EscKeySubscription {
    
    constructor(private service: EscKeyScopeService, public delegate: EscKeyDelegate) {
    }

    unsubscribe() {
        this.service.unsubscribe(this);
    }
}


@Injectable({
    providedIn: 'root',
})
export class EscKeyScopeService {

    private stack: EscKeySubscription[] = [];

    public subscribe(delegate: EscKeyDelegate) {
        const subscription = new EscKeySubscription(this, delegate);
        
        this.stack.push(subscription);

        return subscription;
    }

    public unsubscribe(subscription: EscKeySubscription) {
        const index = this.stack.indexOf(subscription);

        if (index !== undefined) {
            this.stack.splice(index)
        }
    }

    /** Executes EscKey delegate at the top of the stack. */
    public execute() {
        if (this.stack.length > 0) {
            const ret = this.stack[this.stack.length - 1].delegate();

            if (ret) {
                this.stack.pop();
            }
        }
        
    }

}