<div class="hy-btn secondary previous" [class.disabled]="!hasPrevious()" (click)="selectPage(page-1); $event.preventDefault()">
    <span class="ico-24"></span>PREVIOUS 
</div>
<div class="space"></div>
<div *ngFor="let pageNumber of pages" 
        class="page-item" 
        [class.active]="pageNumber === page" 
        [class.page-link]="!isEllipsis(pageNumber)"
        (click)="selectPage(pageNumber); $event.preventDefault()">

    {{ isEllipsis(pageNumber) ? "..." : pageNumber }}
</div>
<div class="space"></div>
<div class="hy-btn secondary next" [class.disabled]="!hasNext()" (click)="selectPage(page+1); $event.preventDefault()">
    NEXT<span class="ico-24 right"></span>
</div>
