import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LoadingStatus } from 'src/app/modules/shared/loading-status';
import { BackendService, MonitoringDevice, MonitoringResponse, MonitoringSignal, MonitoringSignalsResponse } from 'src/app/services/backend.service';
import { CachedBackendService } from 'src/app/services/cached-backend.service';
import { DateSelectionHeaderService } from 'src/app/services/date-selection-header.service';
import { MonitoringAlertEditorSidebarService as MonitoringAlertEditorSidebarService } from 'src/app/services/monitoring-alert-editor-sidebar.service';
import { ToastMessageService } from 'src/app/services/toast-message.service';
import { AlertRule } from 'src/app/util/AlertRule';
import { ChartData } from '../full-size-chart-page/full-size-chart-page.component';
import { ChartLoadingSupport } from 'src/app/util/ChartLoadingSupport';
import { DateRange } from 'src/app/layout/time-slider/time-slider.component';

@Component({
    selector: 'monitoring-page',
    templateUrl: 'monitoring-page.component.html',
    styleUrls: ['monitoring-page.component.scss']
})
export class MonitoringPageComponent {
    
    signalsLoadingStatus: LoadingStatus = LoadingStatus.Loading;

    chartLoadingStatus: LoadingStatus = LoadingStatus.Loading;

    signals: MonitoringSignalsResponse | undefined;

    selectedDevice?: MonitoringDevice;

    selectedSignal?: MonitoringSignal;

    alertRule?: AlertRule;

    zoomChartData?: ChartData;
    chartData?: ChartData;

    cls: ChartLoadingSupport;

    constructor(
            private backendService: BackendService,
            private toast: ToastMessageService,
            private router: Router,
            private route: ActivatedRoute,
            public dateSelectionService: DateSelectionHeaderService,
            public editorSidebar: MonitoringAlertEditorSidebarService) {

        this.cls = new ChartLoadingSupport(this.dateSelectionService);
    }

    ngOnInit() {
        const query = this.route.snapshot.queryParams;
        const op = query.op;

        if (op == "signal") {
            this.setLastViewedSignal(parseInt(query.node_id), parseInt(query.signal_id));
            this.dateSelectionService.setHistoricOverview(new Date(query.timestamp));
        }

        this.loadSignals();
    }

    loadSignals() {
        this.signalsLoadingStatus = LoadingStatus.Loading;
        this.backendService.getMonitoringSignals(this.dateSelectionService.getCommonDateFilter())
            .then(data => {
                if (!data || !data.devices?.length) {
                    this.signalsLoadingStatus = LoadingStatus.NoData;
                    this.signals = undefined;
                    return;
                }
                else {
                    this.signalsLoadingStatus = LoadingStatus.Loaded;
                    this.signals = data;
                    this.dateSelectionService.setNextUpdateTime(new Date(data.next_update_time));
                }

                const { device, signal } = this.getLastViewedSignal(data);

                if (device && signal) {
                    this.selectedDevice = device;
                    this.selectedSignal = signal;
                }
                else {
                    this.selectedDevice = data.devices[0];
                    this.selectedSignal = data.devices[0].signals[0];
                }

                this.loadChart(this.selectedSignal);

                this.dateSelectionService.setNextUpdateTime(new Date(data.next_update_time));
            })
            .catch(_ => this.signalsLoadingStatus = LoadingStatus.Failed);
    }

    loadChart(signal: MonitoringSignal) {
        this.chartLoadingStatus = LoadingStatus.Loading;
        this.backendService.getMonitoringChart(this.dateSelectionService.getCommonDateFilter(), signal.signal_id)
            .then(data => {
                this.chartLoadingStatus = LoadingStatus.Loaded;

                this.chartData = {
                    ...signal,
                    ...data
                };

                if (!this.zoomChartData) {
                    this.zoomChartData = this.chartData;
                }
            })
            .catch(_ => this.chartLoadingStatus = LoadingStatus.Failed);
    }

    forceLoadMonitoringData() {
        if (this.backendService instanceof CachedBackendService) {
            this.backendService.clearMonitoringSignalsCache();
        }
        this.loadSignals();
    }

    selectedDateChanged() {
        this.cls.selectedDateChanged();
        this.zoomChartData = undefined;

        if (this.selectedSignal) {
            this.loadChart(this.selectedSignal);
        }
    }


    onZoomSelection(range: DateRange) {
        this.cls.onZoomSelection(range);

        if (this.selectedSignal) {
            this.loadChart(this.selectedSignal);
        }
    }

    selectSignal(device: MonitoringDevice, signal: MonitoringSignal) {
        this.selectedDevice = device;
        this.selectedSignal = signal;

        this.setLastViewedSignal(device.node_id, signal.signal_id);
        this.loadChart(this.selectedSignal);
    }

    formatAlertCondition(signal: MonitoringSignal) {
        if (!signal.alert_rule) {
            return;
        }

        let rule = signal.alert_rule;

        let condition;
        switch (rule.operator) {
            case '>': condition = 'if > ' + rule.value_1; break;
            case '<': condition = 'if < ' + rule.value_1; break;
            case 'outside range': condition = 'if < ' + rule.value_1 + ' or > ' + rule.value_2; break;
            case '=': condition = 'if = ' + rule.value_1; break;
        }

        if (condition) {
            condition += " " + signal.units;
        }

        return condition;
    }

    editAlert(device: MonitoringDevice, signal: MonitoringSignal, event: MouseEvent) {
        event.stopPropagation();

        this.editorSidebar.openForSignal(device, signal);
    }

    dismissWarnings(device: MonitoringDevice, signal: MonitoringSignal) {
        this.backendService
            .clearMonitoringAlert(device.node_id, signal.signal_id)
            .then(() => location.reload())
            .catch(this.toast.networErrorHandler);
    }

    viewWarnings(device: MonitoringDevice, signal: MonitoringSignal) {
        this.router.navigate(
            ["/notifications"],
            { queryParams: { type: "warning", device: "" + device.node_id }}
        );
    }



    // ----- LocalStorage settings ------------------------------------------ //

    private readonly LsPrefix = "HYAI-Monitoring-";
    private readonly LsLastNodeIdKey = this.LsPrefix + "-Last-NodeId";
    private readonly LsLastSignalIdKey = this.LsPrefix + "-Last-SignalId";

    setLastViewedSignal(nodeId: number, signalId: number) {
        localStorage.setItem(this.LsLastNodeIdKey, nodeId.toString());
        localStorage.setItem(this.LsLastSignalIdKey, signalId.toString());
    }

    getLastViewedSignal(data: MonitoringSignalsResponse) {
        const nodeId = parseInt(localStorage.getItem(this.LsLastNodeIdKey) || "");
        const signalId = parseInt(localStorage.getItem(this.LsLastSignalIdKey) || "");

        const device = data.devices.find(x => x.node_id == nodeId);
        const signal = device?.signals.find(x => x.signal_id == signalId);

        return { device, signal };
    }
}
