<div class="content">
    <div class="logo"></div>
    <router-outlet></router-outlet>
</div>
<div class="bottom">
    <div class="copy">© H2GO Power. All Right Reserved.</div>
    <div class="links">
        <a href="https://www.h2gopower.com/privacy-policy">Terms</a>
        <a href="https://www.h2gopower.com/privacy-policy">Privacy</a>
    </div>
</div>
