<right-sidebar *ngIf="opened" title="{{env.enableScenarios ? 'Custom Plot' : 'Forecast Plot'}} editor" [service]="this">

        <div class="desc indent">Add new {{env.enableScenarios ? 'custom plot' : 'forecast plot'}} by inputing<br>the basic information to be displayed.</div>

        <div class="chart-illustration"></div>

        <div class="group name">
            <div class="hy-e-title indent">PLOT NAME</div>
            <div class="hy-input-e indent">
                <input type="text" [(ngModel)]="chartTitle" placeholder="Plot name">
            </div>
        </div>

        <div class="indent">
            <div class="group">
                <div class="hy-e-title">CHOOSE TIME SERIES</div>
                <div class="hy-select-e">
                    <select #timeseriesSelect [disabled]="availableTimeseries.length === 0">
                        <option *ngFor="let series of availableTimeseries" [value]="series.forecast_id">
                            {{series.forecast_name}}
                        </option>
                        <option *ngIf="availableTimeseries.length === 0">
                            All time series added
                        </option>
                    </select>
                </div>
            </div>

            <div class="hy-btn add secondary" (click)="addTimeseries(timeseriesSelect.value)">ADD PLOT DATA ITEM</div>

            <div class="hy-e-title">PLOT DATA ITEMS</div>
        </div>

        <div *ngIf="selectedTimeseries.length === 0" class="no-items">No items added yet.</div>

        <div *ngIf="selectedTimeseries.length > 0" class="data-items">
            <div *ngFor="let series of selectedTimeseries" class="data-item" (click)="removeSeries(series)">
                <span>{{series.forecast_name}}</span>
            </div>
        </div>

        <ng-template #footer>
            <div class="error" *ngIf="error">
                <div class="ico"></div>
                <div class="text">{{error}}</div>
            </div>
            <div *ngIf="chartId" class="delete" (click)="delete()">Delete this plot</div>
        </ng-template>

        <ng-template #buttons>
            <div class="hy-btn secondary" (click)="close()">CANCEL</div>
            <div class="hy-btn" (click)="save()" [class.disabled]="saveDisabled">SAVE</div>
        </ng-template>

</right-sidebar>