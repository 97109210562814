import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AuthHttpInterceptor, AuthModule } from '@auth0/auth0-angular';
import { NgxEchartsModule } from 'ngx-echarts';
import { LottieModule } from 'ngx-lottie';

import player from 'lottie-web/build/player/lottie_svg';

import { APP_INITIALIZER } from '@angular/core';
import { BackendService } from 'src/app/services/backend.service';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ChartBlockComponent } from './components/chart-block/chart-block.component';
import { DoubleFieldInputDialogComponent } from './components/double-field-input-dialog/double-field-input-dialog.component';
import { StickyClassDirective } from './directives/sticky-class.directive';
import { ExpiredTokenInterceptor } from './http-interceptors/expired-token.interceptor';
import { AppLayoutComponent } from './layout/app-layout/app-layout.component';
import { DateRangeSelectorComponent } from './layout/date-range-selector/date-range-selector.component';
import { DateSelectionHeaderComponent } from './layout/date-selection-header/date-selection-header.component';
import { LoginLayoutComponent } from './layout/login-layout/login-layout.component';
import { NotificationsAndAccountComponent } from './layout/notifications-and-account/notifications-and-account.component';
import { SettingsSidebarComponent } from './layout/settings-sidebar/settings-sidebar.component';
import { SidebarComponent } from './layout/sidebar/sidebar.component';
import { TimeSelectionSliderComponent } from './layout/time-selection-slider/time-selection-slider.component';
import { ForecastsPageComponent } from './pages/forecasts-page/forecasts-page.component';
import { ForgotPasswordPageComponent } from './pages/forgot-password-page/forgot-password-page.component';
import { ForgotPasswordSentPageComponent } from './pages/forgot-password-sent-page/forgot-password-sent-page.component';
import { MetricsHydrogenChartPageComponent } from './pages/full-size-chart-page/metrics-hydrogen-chart-page.component';
import { MetricsProfitChartPageComponent } from './pages/full-size-chart-page/metrics-profit-chart-page.component';
import { NetworkDevicesChartPageComponent } from './pages/full-size-chart-page/network-devices-chart-page.component';
import { NetworkStorageChartPageComponent } from './pages/full-size-chart-page/network-storage-chart-page.component';
import { LoginPageComponent } from './pages/login-page/login-page.component';
import { MetricsPageComponent } from './pages/metrics-page/metrics-page.component';
import { NetworkStatusPageComponent } from './pages/network-status-page/network-status-page.component';
import { ResetPasswordDonePageComponent } from './pages/reset-password-done-page/reset-password-done-page.component';
import { ResetPasswordPageComponent } from './pages/reset-password-page/reset-password-page.component';
import { CountdownPipe } from './pipes/countdown.pipe';
import { TimeAgoPipe } from './pipes/time-ago.pipe';
import { StartupService } from './services/startup.service';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LineChart } from 'echarts/charts';
import { GridComponent, TooltipComponent } from 'echarts/components';
import * as echarts from 'echarts/core';
import { CanvasRenderer } from 'echarts/renderers';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { SortablejsModule } from 'nxt-sortablejs';
import { ApiModule } from 'src/api/api.module';
import { environment } from 'src/environments/environment';
import { LineChartBlockComponent } from './components/chart-block/line-chart-block.component';
import { ModalDialogComponent } from './components/modal-dialog/modal-dialog.component';
import { NetworkGraphComponent } from './components/network-graph/network-graph.component';
import { NotificationsSidebarComponent } from './components/notifications-sidebar/notifications-sidebar.component';
import { PaginationComponent } from './components/pagination/pagination.component';
import { RightSidebarComponent } from './components/right-sidebar/right-sidebar.component';
import { SimplePageHeaderComponent } from './components/simple-page-header/simple-page-header.component';
import { ToggleComponent } from './components/toggle/toggle.component';
import './hy-echarts-theme.js';
import { SharedModule } from './modules/shared/shared.module';
import { AboutUsComponent } from './pages/about-us/about-us.component';
import { NetworkGraphConfigPageComponent } from './pages/dev/network-graph-config-page/network-graph-config-page.component';
import { NetworkGraphConfigComponent } from './pages/dev/network-graph-config/network-graph-config.component';
import { ForecastChartEditorSidebarComponent } from './pages/forecasts-page/forecast-chart-editor-sidebar/forecast-chart-editor-sidebar.component';
import { ForecastsChartPageComponent } from './pages/full-size-chart-page/forecasts-chart-page.component';
import { MonitoringChartPageComponent } from './pages/full-size-chart-page/monitoring-chart-page.component';
import { MonitoringAlertEditorSidebarComponent } from './pages/monitoring-page/monitoring-alert-editor-sidebar/monitoring-alert-editor-sidebar.component';
import { MonitoringPageComponent } from './pages/monitoring-page/monitoring-page.component';
import { EmailNotificationsDialogComponent } from './pages/notifications-page/email-notifications-dialog/email-notifications-dialog.component';
import { NotificationsFilterSidebarComponent } from './pages/notifications-page/notifications-filter-sidebar/notifications-filter-sidebar.component';
import { NotificationsPageComponent } from './pages/notifications-page/notifications-page.component';
import { CachedBackendService } from './services/cached-backend.service';
import { MonitoringAlertEditorSidebarService } from './services/monitoring-alert-editor-sidebar.service';
import { PasswordChangeInfoDialogComponent } from './components/password-change-info-dialog/password-change-info-dialog.component';
import { ScenarioSelectionSidebarComponent } from './components/scenario-selection-sidebar/scenario-selection-sidebar.component';
import { TimeSliderComponent } from './layout/time-slider/time-slider.component';
import { LargeNumberPipe } from './pipes/large-number.pipe';

echarts.use([TooltipComponent, GridComponent, LineChart, CanvasRenderer]);


function startupServiceFactory(startupService: StartupService): Function {
    return () => startupService.load();
}

@NgModule({
  declarations: [
    TimeAgoPipe,
    CountdownPipe,
    LargeNumberPipe,
    AppComponent,
    LoginLayoutComponent,
    AppLayoutComponent,
    ForgotPasswordPageComponent,
    LoginPageComponent,
    ResetPasswordPageComponent,
    ResetPasswordDonePageComponent,
    ForgotPasswordSentPageComponent,
    SidebarComponent,
    NotificationsAndAccountComponent,
    DateSelectionHeaderComponent,
    NetworkStatusPageComponent,
    DateRangeSelectorComponent,
    TimeSelectionSliderComponent,
    NetworkStorageChartPageComponent,
    ChartBlockComponent,
    LineChartBlockComponent,
    ForecastsPageComponent,
    MetricsPageComponent,
    NetworkStorageChartPageComponent,
    NetworkDevicesChartPageComponent,
    MetricsProfitChartPageComponent,
    MetricsHydrogenChartPageComponent,
    SettingsSidebarComponent,
    DoubleFieldInputDialogComponent,
    StickyClassDirective,
    MonitoringPageComponent,
    MonitoringAlertEditorSidebarComponent,
    RightSidebarComponent,
    MonitoringChartPageComponent,
    NotificationsPageComponent,
    SimplePageHeaderComponent,
    PaginationComponent,
    NotificationsFilterSidebarComponent,
    ModalDialogComponent,
    EmailNotificationsDialogComponent,
    NotificationsSidebarComponent,
    NetworkGraphConfigComponent,
    NetworkGraphConfigPageComponent,
    ForecastsChartPageComponent,
    ForecastChartEditorSidebarComponent,
    ToggleComponent,
    AboutUsComponent,
    NetworkGraphComponent,
    PasswordChangeInfoDialogComponent,
    ScenarioSelectionSidebarComponent,
    TimeSliderComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    HttpClientModule,
    NgxEchartsModule.forRoot({ echarts }),
    LottieModule.forRoot({ player: () => player }),
    NgxDaterangepickerMd.forRoot({ firstDay: 1 }),
    SortablejsModule.forRoot({ animation: 150 }),
    ApiModule.forRoot({ rootUrl: environment.backendUrl }),
    AuthModule.forRoot({
      domain: environment.domain,
      clientId: environment.clientId,
      authorizationParams: {
        redirect_uri: window.location.origin,
        audience: environment.audience,
        // organization: environment.org_id
      },
      httpInterceptor: {
        allowedList: [
            {
            uri: environment.backendUrl + '*',
          }
        ]
      }
    }
    ),
    SharedModule
  ],
  providers: [
    //{ provide: BackendService, useClass: BackendMockService }
    { provide: BackendService, useClass: CachedBackendService },
    { provide: HTTP_INTERCEPTORS, useClass: ExpiredTokenInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: AuthHttpInterceptor, multi: true },
    {
        provide: APP_INITIALIZER,
        useFactory: startupServiceFactory,
        deps: [StartupService],
        multi: true
    },

    MonitoringAlertEditorSidebarService
  ],

  bootstrap: [AppComponent]
})
export class AppModule { }

