import { Component, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
// import { AuthService } from 'src/app/services/auth.service';
import { AuthService } from '@auth0/auth0-angular';
import { PasswordChangeInfoDialogComponent } from 'src/app/components/password-change-info-dialog/password-change-info-dialog.component';
import { BackendService } from 'src/app/services/backend.service';
import { ConfigurationService } from 'src/app/services/configuration.service';
import { DateSelectionHeaderService } from 'src/app/services/date-selection-header.service';
import { DoubleFieldInputService } from 'src/app/services/double-field-input.service';
import { SettingsSidebarService } from 'src/app/services/settings-sidebar.service';
import { ToastMessageService } from 'src/app/services/toast-message.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'hy-settings-sidebar',
  templateUrl: './settings-sidebar.component.html',
  styleUrls: ['./settings-sidebar.component.scss']
})
export class SettingsSidebarComponent {

    @ViewChild(PasswordChangeInfoDialogComponent, { static: true })
    passwordChangeInfoDialog!: PasswordChangeInfoDialogComponent;

    somOptions = [
        { id: "metric", label: "Metric"   , value: "s, m, kg, A, K, mol, cd, C" },
        { id: "imperial", label: "Imperial" , value: "s, miles, pounds" }
    ]

    selectedTab = 0;

    constructor(
        public settingsSidebarService: SettingsSidebarService,
        private inputService: DoubleFieldInputService,
        private dateService: DateSelectionHeaderService,
        public backend: BackendService,
        public config: ConfigurationService,
        private toast: ToastMessageService,
        public auth: AuthService,
        public router: Router) { }

    setSom(units: string) {
        this.config.units = units;
        this.backend
            .setUnits(units)
            .then(() => this.dateService.triggerReload())
            .catch(this.toast.networErrorHandler);
    }

    editName() {
        this.inputService.showSingleInput(
            "Update your name",
            "",
            "USERNAME",
            this.config.userName,
            (userName: string) => {
                if (!userName || userName.trim().length == 0) {
                    return Promise.reject({ message: "Name is empty, please input your name." });
                }
                else if (userName.length > 15) {
                    return Promise.reject({ message: "Your name can contain max. 15 characters. Please review and update."});
                }

                return this.backend
                    .changeUserName(userName)
                    .then(x => { this.config.userName = userName; return x; } );
            }
        );
    }

    changePassword() {
        this.passwordChangeInfoDialog.sendEmail();
    }

    logout() {
        this.auth.logout({ logoutParams: { returnTo: window.location.origin } });
        this.settingsSidebarService.close();
    }

    env = environment;

}
