<hy-date-selection-header #header
    [title]="'Network Status'"
    (selectedDateChanged)="selectedDateChanged($event)"
    stickyClass="stuck"
    class="sticky">
</hy-date-selection-header>



<div class="stuck-placeholder"></div>

<div class="content" [class.live-mode]="dateSelectionService.liveModeSelected">

    <div class="graph-block">
        <div class="graph-container">

            <div *ngIf="graphData && graphImage" class="graph hy-fadein">
                <hy-network-graph [image]="graphImage" [nodes]="graphData.nodes" [arcs]="graphData.arcs"></hy-network-graph>
            </div>

            <div *ngIf="graphDataStatus !== 'loaded'" 
                    class="request-info-overlay hy-fadein {{graphDataStatus}}"
                    [class.has-graph]="graphData && graphImage">
                <hy-request-info  [type]="graphDataStatus" (tryAgain)="loadGraphData(false)">
                </hy-request-info>
            </div>
        </div>

        <div class="date-selector" *ngIf="!dateSelectionService.liveModeSelected">
            <div class="header">
                <div class="text">
                    <svg [innerHTML]="'clock-back' | fico"></svg>
                    <div>
                        <span>Network Status on </span>
                        <span class="date">{{ graphSelectedDate | date: "ccc, MMM dd HH:mm" : '+0000' }}</span>
                    </div>
                </div>
            </div>

            <hy-time-slider
                [startDate]="dateSelectionService.selectedStartDate"
                [endDate]="dateSelectionService.selectedEndDate"
                [selectedDate]="graphSelectedDate"
                (selectedDateChanged)="onGraphSliderChange($event)"
                [unavailableDateRanges]="unavailableDateRanges ?? []">
            </hy-time-slider>
        </div>
    </div>

    <div class="split-title">
        <div class="title">{{ env.enableScenarios ? "Storage and Assets Overview" : (dateSelectionService.liveModeSelected ? "Live data" : "Historical Overview") }}</div>

        <a class="hy-btn mid secondary" routerLink="/metrics">VIEW ALL METRICS</a>
    </div>

    <div class="charts">
        <hy-line-chart-block  
            id="network-storage"
            [zoomData]="zoomChartData?.storage"
            [data]="chartsData?.storage"
            [live]="dateSelectionService.liveModeSelected"
            [nowDate]="dateSelectionService.selectedDate"
            [fullRangeStart]="cls.fullRangeStart"
            [fullRangeEnd]="cls.fullRangeEnd"
            [zoomStart]="cls.zoomStartDate"
            [zoomEnd]="cls.zoomEndDate"
            [secondaryMarkDate]="dateSelectionService.liveModeSelected ? undefined : graphSelectedDate"
            [loadingStatus]="chartsLoadingStatus"
            [showTimeInTooltips]="showTimeInTooltips"
            metricTitle="DEVICE"
            metricKey="node_name"
            (tryAgain)="loadChartsData()"
            (clickDate)="onChartClick($event)"
            (zoomSelection)="onZoomSelection($event)">
        </hy-line-chart-block>
        <hy-line-chart-block  
            id="network-devices-line"
            [zoomData]="zoomChartData?.devices"
            [data]="chartsData?.devices"
            [live]="dateSelectionService.liveModeSelected"
            [nowDate]="dateSelectionService.selectedDate"
            [fullRangeStart]="cls.fullRangeStart"
            [fullRangeEnd]="cls.fullRangeEnd"
            [zoomStart]="cls.zoomStartDate"
            [zoomEnd]="cls.zoomEndDate"
            [secondaryMarkDate]="dateSelectionService.liveModeSelected ? undefined : graphSelectedDate"
            [loadingStatus]="chartsLoadingStatus"
            [showTimeInTooltips]="showTimeInTooltips"
            expandRouterLink="/network/devices"
            metricTitle="DEVICE"
            metricKey="node_name"
            (tryAgain)="loadChartsData()"
            (clickDate)="onChartClick($event)"
            (zoomSelection)="onZoomSelection($event)">
        </hy-line-chart-block>
    </div>

</div>