import { Component, ContentChild, Input, TemplateRef } from '@angular/core';
import { EscKeyScopeService, EscKeySubscription } from 'src/app/services/esc-key-scope.service';

@Component({
  selector: 'modal-dialog',
  templateUrl: './modal-dialog.component.html',
  styleUrls: ['./modal-dialog.component.scss']
})
export class ModalDialogComponent {

    @Input()
    public visible = false;

    @Input() 
    public title: string = "Dialog";

    @ContentChild("buttons") 
    buttons?: TemplateRef<unknown>;

    private escKeySubscription?: EscKeySubscription;

    constructor(private escKeyScopeService: EscKeyScopeService) {
        
	}

    public open() {
        this.visible = true;
        this.escKeySubscription = this.escKeyScopeService.subscribe(() => this.close());
    }

    public close() {
        this.visible = false;
        this.escKeySubscription?.unsubscribe();
        this.escKeySubscription = undefined;
    }

}
