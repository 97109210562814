/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';

import { ClearMonitoringAlertsSchema } from '../models/clear-monitoring-alerts-schema';
import { EditMonitoringAlertsSchema } from '../models/edit-monitoring-alerts-schema';
import { MonitoringChartsResponseSchema } from '../models/monitoring-charts-response-schema';
import { MonitoringResponseSchema } from '../models/monitoring-response-schema';

@Injectable({ providedIn: 'root' })
export class MonitoringService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getMonitoringSettingIdMonitoringGet()` */
  static readonly GetMonitoringSettingIdMonitoringGetPath = '/{setting_id}/monitoring';

  /**
   * Get Monitoring.
   *
   * Returns the Monitoring page with the list of devices and the signals for each device.
   * Needs a JWT authentication token and a timestamp.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getMonitoringSettingIdMonitoringGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMonitoringSettingIdMonitoringGet$Response(
    params: {
      setting_id: string;
      start_date?: (string | string);
      end_date?: (string | string);
      timestamp?: (string | string);
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<any>> {
    const rb = new RequestBuilder(this.rootUrl, MonitoringService.GetMonitoringSettingIdMonitoringGetPath, 'get');
    if (params) {
      rb.path('setting_id', params.setting_id, {});
      rb.query('start_date', params.start_date, {});
      rb.query('end_date', params.end_date, {});
      rb.query('timestamp', params.timestamp, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<any>;
      })
    );
  }

  /**
   * Get Monitoring.
   *
   * Returns the Monitoring page with the list of devices and the signals for each device.
   * Needs a JWT authentication token and a timestamp.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getMonitoringSettingIdMonitoringGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMonitoringSettingIdMonitoringGet(
    params: {
      setting_id: string;
      start_date?: (string | string);
      end_date?: (string | string);
      timestamp?: (string | string);
    },
    context?: HttpContext
  ): Observable<any> {
    return this.getMonitoringSettingIdMonitoringGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<any>): any => r.body)
    );
  }

  /** Path part for operation `getMonitoringSignalsSettingIdMonitoringSignalsGet()` */
  static readonly GetMonitoringSignalsSettingIdMonitoringSignalsGetPath = '/{setting_id}/monitoring/signals';

  /**
   * Get Monitoring Signals.
   *
   * Returns the Monitoring page with the list of devices and the signals for each device.
   * Needs a JWT authentication token and a timestamp.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getMonitoringSignalsSettingIdMonitoringSignalsGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMonitoringSignalsSettingIdMonitoringSignalsGet$Response(
    params: {
      setting_id: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<MonitoringResponseSchema>> {
    const rb = new RequestBuilder(this.rootUrl, MonitoringService.GetMonitoringSignalsSettingIdMonitoringSignalsGetPath, 'get');
    if (params) {
      rb.path('setting_id', params.setting_id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<MonitoringResponseSchema>;
      })
    );
  }

  /**
   * Get Monitoring Signals.
   *
   * Returns the Monitoring page with the list of devices and the signals for each device.
   * Needs a JWT authentication token and a timestamp.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getMonitoringSignalsSettingIdMonitoringSignalsGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMonitoringSignalsSettingIdMonitoringSignalsGet(
    params: {
      setting_id: string;
    },
    context?: HttpContext
  ): Observable<MonitoringResponseSchema> {
    return this.getMonitoringSignalsSettingIdMonitoringSignalsGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<MonitoringResponseSchema>): MonitoringResponseSchema => r.body)
    );
  }

  /** Path part for operation `getMonitoringChartSettingIdMonitoringChartGet()` */
  static readonly GetMonitoringChartSettingIdMonitoringChartGetPath = '/{setting_id}/monitoring/chart';

  /**
   * Get Monitoring Chart.
   *
   * Returns the Monitoring page with the list of devices and the signals for each device.
   * Needs a JWT authentication token and a timestamp.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getMonitoringChartSettingIdMonitoringChartGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMonitoringChartSettingIdMonitoringChartGet$Response(
    params: {
      setting_id: string;
      signal_id: number;
      start_date?: (string | string);
      end_date?: (string | string);
      timestamp?: (string | string);
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<MonitoringChartsResponseSchema>> {
    const rb = new RequestBuilder(this.rootUrl, MonitoringService.GetMonitoringChartSettingIdMonitoringChartGetPath, 'get');
    if (params) {
      rb.path('setting_id', params.setting_id, {});
      rb.query('signal_id', params.signal_id, {});
      rb.query('start_date', params.start_date, {});
      rb.query('end_date', params.end_date, {});
      rb.query('timestamp', params.timestamp, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<MonitoringChartsResponseSchema>;
      })
    );
  }

  /**
   * Get Monitoring Chart.
   *
   * Returns the Monitoring page with the list of devices and the signals for each device.
   * Needs a JWT authentication token and a timestamp.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getMonitoringChartSettingIdMonitoringChartGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMonitoringChartSettingIdMonitoringChartGet(
    params: {
      setting_id: string;
      signal_id: number;
      start_date?: (string | string);
      end_date?: (string | string);
      timestamp?: (string | string);
    },
    context?: HttpContext
  ): Observable<MonitoringChartsResponseSchema> {
    return this.getMonitoringChartSettingIdMonitoringChartGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<MonitoringChartsResponseSchema>): MonitoringChartsResponseSchema => r.body)
    );
  }

  /** Path part for operation `editMonitoringAlertsSettingIdMonitoringAlertEditorPost()` */
  static readonly EditMonitoringAlertsSettingIdMonitoringAlertEditorPostPath = '/{setting_id}/monitoring/alert_editor';

  /**
   * Edit Monitoring Alerts.
   *
   * Edits alerts that will be displayed in the monitoring page.
   * Needs a JWT authentication token and a timestamp.
   * .. example::
   *    $ curl http://localhost:8000/monitoring/alert_editor -X POST           -H 'Accept: application/json' -H 'Authorization: Bearer <access_token>'           -d '{"node_id": 3, "signal_id": 0, "operator": "outside_range",                "value_1": 10.0, "value_2": 50.5, "alert_enabled": true}'
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `editMonitoringAlertsSettingIdMonitoringAlertEditorPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  editMonitoringAlertsSettingIdMonitoringAlertEditorPost$Response(
    params: {
      setting_id: string;
      body: EditMonitoringAlertsSchema
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<any>> {
    const rb = new RequestBuilder(this.rootUrl, MonitoringService.EditMonitoringAlertsSettingIdMonitoringAlertEditorPostPath, 'post');
    if (params) {
      rb.path('setting_id', params.setting_id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<any>;
      })
    );
  }

  /**
   * Edit Monitoring Alerts.
   *
   * Edits alerts that will be displayed in the monitoring page.
   * Needs a JWT authentication token and a timestamp.
   * .. example::
   *    $ curl http://localhost:8000/monitoring/alert_editor -X POST           -H 'Accept: application/json' -H 'Authorization: Bearer <access_token>'           -d '{"node_id": 3, "signal_id": 0, "operator": "outside_range",                "value_1": 10.0, "value_2": 50.5, "alert_enabled": true}'
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `editMonitoringAlertsSettingIdMonitoringAlertEditorPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  editMonitoringAlertsSettingIdMonitoringAlertEditorPost(
    params: {
      setting_id: string;
      body: EditMonitoringAlertsSchema
    },
    context?: HttpContext
  ): Observable<any> {
    return this.editMonitoringAlertsSettingIdMonitoringAlertEditorPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<any>): any => r.body)
    );
  }

  /** Path part for operation `clearMonitoringAlertSettingIdMonitoringClearAlertPost()` */
  static readonly ClearMonitoringAlertSettingIdMonitoringClearAlertPostPath = '/{setting_id}/monitoring/clear_alert';

  /**
   * Clear Monitoring Alert.
   *
   * Clear the specified alert from the monitoring page.
   * Needs a JWT authentication token and a timestamp.
   * .. example::
   *    $ curl http://localhost:8000/{scenario_id}/monitoring/clear_alert -X POST        -H 'Accept: application/json' -H 'Authorization: Bearer <access_token>'        -d '{"signal_id": 0, "node_id": 3}'
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `clearMonitoringAlertSettingIdMonitoringClearAlertPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  clearMonitoringAlertSettingIdMonitoringClearAlertPost$Response(
    params: {
      setting_id: string;
      body: ClearMonitoringAlertsSchema
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<any>> {
    const rb = new RequestBuilder(this.rootUrl, MonitoringService.ClearMonitoringAlertSettingIdMonitoringClearAlertPostPath, 'post');
    if (params) {
      rb.path('setting_id', params.setting_id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<any>;
      })
    );
  }

  /**
   * Clear Monitoring Alert.
   *
   * Clear the specified alert from the monitoring page.
   * Needs a JWT authentication token and a timestamp.
   * .. example::
   *    $ curl http://localhost:8000/{scenario_id}/monitoring/clear_alert -X POST        -H 'Accept: application/json' -H 'Authorization: Bearer <access_token>'        -d '{"signal_id": 0, "node_id": 3}'
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `clearMonitoringAlertSettingIdMonitoringClearAlertPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  clearMonitoringAlertSettingIdMonitoringClearAlertPost(
    params: {
      setting_id: string;
      body: ClearMonitoringAlertsSchema
    },
    context?: HttpContext
  ): Observable<any> {
    return this.clearMonitoringAlertSettingIdMonitoringClearAlertPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<any>): any => r.body)
    );
  }

}
