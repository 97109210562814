<hy-dialog #dialog>

    <div class="featured-ico">
        <svg [innerHTML]="'mail-01' | fico"></svg>
    </div>

    <div class="title">Check your email</div>
    <div class="desc">We sent a password reset link to <span class="address">{{address}}</span></div>

    <div class="no-email">Didn’t receive the email?</div>

    <button class="hy-btn" [disabled]="resendExecuting" (click)="sendEmail()">CLICK TO RESEND</button>
    <div class="hy-btn secondary" (click)="backToDash()">BACK TO DASHBOARD</div>
        

</hy-dialog>