/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';


@Injectable({ providedIn: 'root' })
export class MetricsService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getMetricsSettingIdMetricsGet()` */
  static readonly GetMetricsSettingIdMetricsGetPath = '/{setting_id}/metrics';

  /**
   * Get Metrics.
   *
   * Get the metrics data.
   * Needs a JWT authentication token and a timestamp.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getMetricsSettingIdMetricsGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMetricsSettingIdMetricsGet$Response(
    params: {
      setting_id: string;
      start_date?: (string | string);
      end_date?: (string | string);
      timestamp?: (string | string);
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<any>> {
    const rb = new RequestBuilder(this.rootUrl, MetricsService.GetMetricsSettingIdMetricsGetPath, 'get');
    if (params) {
      rb.path('setting_id', params.setting_id, {});
      rb.query('start_date', params.start_date, {});
      rb.query('end_date', params.end_date, {});
      rb.query('timestamp', params.timestamp, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<any>;
      })
    );
  }

  /**
   * Get Metrics.
   *
   * Get the metrics data.
   * Needs a JWT authentication token and a timestamp.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getMetricsSettingIdMetricsGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMetricsSettingIdMetricsGet(
    params: {
      setting_id: string;
      start_date?: (string | string);
      end_date?: (string | string);
      timestamp?: (string | string);
    },
    context?: HttpContext
  ): Observable<any> {
    return this.getMetricsSettingIdMetricsGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<any>): any => r.body)
    );
  }

}
