import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { DateRangeSelectorComponent, DateSelection } from '../date-range-selector/date-range-selector.component';
import DateUtils, { getShortMonthName } from 'src/app/util/DateUtils';
import { ConfigurationService } from 'src/app/services/configuration.service';
import { DateSelectionHeaderService } from 'src/app/services/date-selection-header.service';
import { Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ScenarioSelectionSidebarService } from 'src/app/components/scenario-selection-sidebar/scenario-selection-sidebar.service';


@Component({
  selector: 'hy-date-selection-header',
  templateUrl: './date-selection-header.component.html',
  styleUrls: ['./date-selection-header.component.scss']
})
export class DateSelectionHeaderComponent implements OnInit, OnDestroy {
    @Input()
    public title: string = "";

    @Input()
    public showNotificationsAndAccount = true;

    @ViewChild(DateRangeSelectorComponent, {static: true}) 
    dateRangeSelector!: DateRangeSelectorComponent;

    /** Defines if date range selection popup is visible. */
    dateRangeSelectorVisible: boolean = false;

    /** Label used in button for selecting historic view. */
    activeDatesLabel!: string;

    @Output()
    public selectedDateChanged = new EventEmitter<Date>();

    private dataReloadSubscription: Subscription;

    // In Scenarios mode we don't want to show live view button.
    _liveViewEnabled = !environment.enableScenarios;

    constructor(
            public config: ConfigurationService, 
            public scenarioSidebar: ScenarioSelectionSidebarService,
            public service: DateSelectionHeaderService) {
        this.dataReloadSubscription = service.reloadNeeded.subscribe(
            () => {
                if (service.liveModeSelected) {
                    // to update selected date to current time
                    this.service.setLiveMode();
                }

                this.selectedDateChanged.emit(this.service.selectedDate);
            }
        );
    }

    ngOnInit(): void {
        this.activeDatesLabel = this.getActiveDatesLabel();
    }

    ngOnDestroy() {
        this.dataReloadSubscription.unsubscribe();
    }

    selectLiveMode() {
        this.service.setLiveMode();
        this.dateRangeSelectorVisible = false;
        this.selectedDateChanged.emit(this.service.selectedDate);
    }

    openDateRangeSelector(): void {
        this.dateRangeSelectorVisible = true;
    }

    updateSelectedDates(selection: DateSelection): void {
        this.service.setHistoricOverview(selection.start, selection.end);
        this.activeDatesLabel = this.getActiveDatesLabel();
        this.dateRangeSelectorVisible = false;
        this.selectedDateChanged.emit(this.service.selectedDate);
    }

    cloaseDateRangeSelector(): void {
        this.dateRangeSelectorVisible = false;
    }


    private getActiveDatesLabel(): string {
        if (this.service.selectedEndDate) {
            return this.getDateLabel(this.service.selectedStartDate) + " - " + this.getDateLabel(this.service.selectedEndDate);
        }
        else {
            return this.getDateLabel(this.service.selectedStartDate);
        }
    }

    private getDateLabel(date: Date): string {
        return getShortMonthName(date.getUTCMonth()) 
            + " " 
            + date.getUTCDate() 
            + ", " 
            + date.getUTCFullYear();
    }

    env = environment;
}
