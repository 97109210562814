import { formatDate } from "@angular/common";
import { MilisInDay, MilisInHour, dateIsStartOfDay, dateNextDayStart, getShortMonthName, newUtc } from "src/app/util/DateUtils";


export interface Tick {
    label: string;
    startDate: Date;
    selected?: boolean;
    /** Number of hors tick represents */
    size: number;
    /** Tick size percentage in whole slider */
    pct: number;
    pctEnd: number;
    pctStart: number;
}

export function createSliderTicksForTimeRange(start: Date, end: Date): Tick[] {
    return createTicksForTimeRange(start, end, false);
}


export function createChartTicksForTimeRange(start: Date, end: Date): Tick[] {
    return removeLabelOfFirstOverlap(limitTicksForChart(createChartTicksForTimeRangeImpl(start, end), end));
}
export function createChartTicksForTimeRangeImpl(start: Date, end: Date): Tick[] {
    const numDays = Math.floor((end.getTime() - start.getTime()) / MilisInDay);

    // 1 day - every 6h
    // 2 days - every 6h
    // 3 days - every 12h
    // 4 days - every 12h

    if (numDays <= 1) {
        return createWithHourlyTicks(alignToNextHourDevider(start, 6), end, 6);
    }
    else if (numDays <= 4) {
        return createWithHourlyTicks(alignToNextHourDevider(start, 12), end, 12);
    }
    else {
        if (!dateIsStartOfDay(start)) {
            start = dateNextDayStart(start);
        }

        return createTicksForTimeRange(start, end, true);
    }
}

function createTicksForTimeRange(start: Date, end: Date, breakDayYearLabel: boolean): Tick[] {
    const numDays = Math.floor((end.getTime() - start.getTime()) / MilisInDay);

    if (numDays <= 14) {
        return createWithDailyTicks(start, end);
    }
    else if (numDays <= (2 * 30)) { // less then 2 months
        return createWeeklyTicks(start, end);
    }
    else if (numDays <= (24 * 30)) { // less then 2 years
        return createMonthlyTicks(start, end, breakDayYearLabel);
    }
    else {
        return createYearlyTicks(start, end, breakDayYearLabel);
    }
}

function createWithHourlyTicks(start: Date, end: Date, stepHours: number) {
    const ticks = new Array<Tick>();

    let nextTick = start;

    while (nextTick.getTime() < end.getTime()) {

        const isStartOfDay = (nextTick.getUTCHours() === 0 && nextTick.getUTCMinutes() === 0);
        if (isStartOfDay) {
            ticks.push(createTick(formatDayLabel(nextTick), nextTick));
        }
        else {
            ticks.push(createTick(formatHourLabel(nextTick), nextTick));
        }

        nextTick = new Date(nextTick.getTime() + MilisInHour * stepHours);
    }

    calcSizeOfTicks(ticks, end);

    return ticks;
}

function createWithDailyTicks(start: Date, end: Date) {
    const ticks = new Array<Tick>();

    let nextTick = start;

    while (nextTick.getTime() < end.getTime()) {
        ticks.push(createTick(formatDayLabel(nextTick), nextTick));

        nextTick = dateNextDayStart(nextTick);
    }

    calcSizeOfTicks(ticks, end);

    return ticks;
}

function createWeeklyTicks(start: Date, end: Date) {
    const ticks = new Array<Tick>();

    ticks.push(createTick(formatDayLabel(start), start));

    let nextTick = nextMonday(start);

    while (nextTick.getTime() < end.getTime()) {
        ticks.push(createTick(formatDayLabel(nextTick), nextTick));

        nextTick = nextMonday(nextTick);
    }

    calcSizeOfTicks(ticks, end);

    return ticks;
}

function createMonthlyTicks(start: Date, end: Date, breakDayYearLabel: boolean) {
    const ticks = new Array<Tick>();

    const startIsMonthStart = (start.getUTCDate() === 1);

    ticks.push(createTick(
        startIsMonthStart ? formatMonthLabel(start) : formatDayYearLabel(start, breakDayYearLabel), 
        start
    ));


    let nextTick = startOfNextMonth(start);

    while (nextTick.getTime() < end.getTime()) {
        ticks.push(createTick(formatMonthLabel(nextTick), nextTick));

        nextTick = startOfNextMonth(nextTick);
    }

    calcSizeOfTicks(ticks, end);

    return ticks;
}    

function createYearlyTicks(start: Date, end: Date, breakDayYearLabel: boolean) {
    const ticks = new Array<Tick>();
    const startIsYearStart = (start.getUTCDate() === 1 && start.getUTCMonth() === 0) ;

    ticks.push(createTick(
        startIsYearStart ? formatYearLabel(start) : formatDayYearLabel(start, breakDayYearLabel), 
        start
    ));

    let nextTick = startOfNextYear(start);

    while (nextTick.getTime() < end.getTime()) {
        ticks.push(createTick(formatYearLabel(nextTick), nextTick));

        nextTick = startOfNextYear(nextTick);
    }

    calcSizeOfTicks(ticks, end);

    return ticks;
}

function createTick(label: string, startDate: Date): Tick {
    return {
        label: label,
        startDate: startDate,
        size: 0,
        pct: 0,
        pctStart: 0,
        pctEnd: 0,
        selected: false
    };
}

function calcSizeOfTicks(ticks: Tick[], endDate: Date) {
    if (ticks.length == 0) {
        return;
    }
    for (let i=0; i < (ticks.length - 1); i++) {
        const tick = ticks[i];
        const nextTick = ticks[i + 1];

        ticks[i].size = daysBetweenDates(tick.startDate, nextTick.startDate);
    }

    const lastTick = ticks[ticks.length - 1];
    lastTick.size = daysBetweenDates(lastTick.startDate, endDate);

    const totalSize = ticks.reduce((val, tick) => val + tick.size, 0);

    let sum = 0;
    ticks.forEach(tick => {
        tick.pct = tick.size / totalSize;
        tick.pctStart = sum / totalSize;
        tick.pctEnd = (sum + tick.size) / totalSize;
        sum += tick.size;
    })
}

function daysBetweenDates(date1: Date, date2: Date) {
    return Math.floor((date2.getTime() - date1.getTime()) / MilisInDay);
}

function hoursBetweenDates(date1: Date, date2: Date) {
    return Math.floor((date2.getTime() - date1.getTime()) / MilisInHour);
}

function formatDayLabel(date: Date) {
    return getShortMonthName(date.getUTCMonth()) + ' ' + date.getUTCDate();
}


function formatDayYearLabel(date: Date, breakDayYearLabel: boolean) {
    return formatDayLabel(date) 
        + ',' 
        + (breakDayYearLabel ? '\n' : ' ')
        + date.getUTCFullYear();
}

function formatMonthLabel(date: Date) {
    return getShortMonthName(date.getUTCMonth()) + ' ' + date.getUTCFullYear();
}

function formatYearLabel(date: Date) {
    return date.getUTCFullYear().toString();
}

function formatHourLabel(date: Date) {
    return formatDate(date, "HH:mm", "en-UK", "+0000");
}

function startOfNextMonth(date: Date) {
    return newUtc(date.getUTCFullYear(), date.getUTCMonth() + 1, 1);
}

function startOfNextYear(date: Date) {
    return newUtc(date.getUTCFullYear() + 1, 0, 1);
}

function nextMonday(date: Date) {
    const day = date.getDay();
    let toMove: number;
    switch (day) {
        case 0: toMove = 1; break;
        default: toMove = 7 - (day - 1);
    }

    return addDay(date, toMove)
}

function alignToNextHourDevider(date: Date, hourDevider: number) {
    const hour = Math.ceil(date.getUTCHours() / hourDevider) * hourDevider;

    console.log(date.toUTCString(), hourDevider, hour);

    return newUtc(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), hour, 0, 0, 0);
}

export function addDay(date: Date, days: number = 1) {
    return newUtc(
        date.getUTCFullYear(), 
        date.getUTCMonth(), 
        date.getUTCDate() + days
    );
}

function limitTicksForChart(ticks: Tick[], end: Date) {
    const bestCountOfTicks = 6;

    const skipTicks = Math.round(ticks.length / bestCountOfTicks);
    if (skipTicks <= 1) {
        return ticks;
    }

    const newTicks = new Array<Tick>();

    const isFirstTimeLabel = (ticks[0].startDate.getUTCHours() != 0);
    const startI = isFirstTimeLabel ? 1 : 0;
    
    for (let i = startI; i < ticks.length; i += skipTicks) {
        newTicks.push(ticks[i]);
    }

    calcSizeOfTicks(newTicks, end);

    return newTicks;
}

function removeLabelOfFirstOverlap(ticks: Tick[]) {
    if (ticks.length < 2) {
        return ticks;
    }

    const firstTick = ticks[0];
    if (firstTick.pct < 0.10) {
        ticks[1].label = " ";
    } 

    return ticks;
}