import { Component, EventEmitter, Input, Output } from '@angular/core';
import { LoadingStatus } from '../loading-status';

@Component({
  selector: 'hy-request-info',
  templateUrl: './request-info.component.html',
  styleUrls: ['./request-info.component.scss'], 
  host: {
    '[class]': 'type'
  }
})
export class RequestInfoComponent {

    @Output()
    public tryAgain = new EventEmitter<void>();

    @Input()
    public type: LoadingStatus = LoadingStatus.Loading;

}
