import { Component } from '@angular/core';
import { ConfigurationService } from 'src/app/services/configuration.service';
import { ScenarioSelectionSidebarService } from './scenario-selection-sidebar.service';
import { ScenarioSchema } from 'src/api/models';
import { DateSelectionHeaderService } from 'src/app/services/date-selection-header.service';


interface ExpandedMap { [key: string]: boolean; }

@Component({
  selector: 'hy-scenario-selection-sidebar',
  templateUrl: './scenario-selection-sidebar.component.html',
  styleUrls: ['./scenario-selection-sidebar.component.scss']
})
export class ScenarioSelectionSidebarComponent {

    selectedScenarioId: string = "1";

    /** Map containing state which scenarios are expanded and which minimized */
    expanded: ExpandedMap = {};

    constructor(
            public configService: ConfigurationService,
            private dateService: DateSelectionHeaderService,
            public service: ScenarioSelectionSidebarService) {
        this.selectedScenarioId = configService.selectedScenarioId;
        this.expanded[this.selectedScenarioId] = true;

    }

    select(scenario: ScenarioSchema) {
        this.selectedScenarioId = scenario.id;
        this.expanded[scenario.id] = true;
    }

    toggleExpanded(scenario: ScenarioSchema) {
        this.expanded[scenario.id] = !this.expanded[scenario.id];
    }

    cancel() {
        this.selectedScenarioId = this.configService.selectedScenarioId;
        this.service.close();
    }

    done() {
        this.configService.selectScenario(this.selectedScenarioId, false);
        this.service.close();

        this.dateService.triggerReload();
    }

}
