<div class="controls">
    <h2>Image</h2> 
    <input #imageFile type="file" accept="image/svg+xml" (change)="loadImage(imageFile.files)" />

    <div *ngIf="image">

        <h2>Nodes</h2>

        <table>
            <tr>
                <th>ID</th>
                <th>Status</th>
                <th>Name</th>
                <th>Fill</th>
                <th>Title 1</th>
                <th>Value 1</th>
                <th>Units 1</th>
                <th>Title 2</th>
                <th>Value 2</th>
                <th>Units 2</th>
            </tr>
            <tr *ngFor="let entry of graph.nodeMap | keyvalue">
                <td>{{entry.value.id}}</td>
                <td>
                    <select #status (change)="changeNodeStatus(graph, entry.value, status.value)">
                        <option>on</option>
                        <option>off</option>
                        <option>error</option>
                    </select>
                </td>
                <td *ngIf="entry.value.hasName"  ><input #name   type="text"   (change)="changeNodeProp   (graph, $any(entry.value), 'node_name', name  .value)"                            /></td><td *ngIf="!entry.value.hasName"  >-</td>
                <td *ngIf="entry.value.hasFill"  ><input #fill   type="number" (change)="changeNodeNumProp(graph, $any(entry.value), 'fill'     , fill  .value)" min="0" max="100" step="1" /></td><td *ngIf="!entry.value.hasFill"  >-</td>
                <td *ngIf="entry.value.hasTitle1"><input #title1 type="text"   (change)="changeNodeProp   (graph, $any(entry.value), 'title_1'  , title1.value)"                            /></td><td *ngIf="!entry.value.hasTitle1">-</td>
                <td *ngIf="entry.value.hasValue1"><input #value1 type="number" (change)="changeNodeNumProp(graph, $any(entry.value), 'value_1'  , value1.value)" step="0.01"                /></td><td *ngIf="!entry.value.hasValue1">-</td>
                <td *ngIf="entry.value.hasValue1"><input #units1 type="text"   (change)="changeNodeProp   (graph, $any(entry.value), 'units_1'  , units1.value)"                            /></td><td *ngIf="!entry.value.hasValue1">-</td>
                <td *ngIf="entry.value.hasTitle2"><input #title2 type="text"   (change)="changeNodeProp   (graph, $any(entry.value), 'title_2'  , title2.value)"                            /></td><td *ngIf="!entry.value.hasTitle2">-</td>
                <td *ngIf="entry.value.hasValue2"><input #value2 type="number" (change)="changeNodeNumProp(graph, $any(entry.value), 'value_2'  , value2.value)" step="0.01"                /></td><td *ngIf="!entry.value.hasValue2">-</td>
                <td *ngIf="entry.value.hasValue2"><input #units2 type="text"   (change)="changeNodeProp   (graph, $any(entry.value), 'units_2'  , units2.value)"                            /></td><td *ngIf="!entry.value.hasValue2">-</td>
            </tr>
        </table>


        <h2>Arcs</h2>

        <table>
            <tr>
                <th>ID</th>
                <th>Status</th>
                <th>Reverse</th>
            </tr>
            <tr *ngFor="let entry of graph.arcMap | keyvalue">
                <td>{{entry.value.id}}</td>
                <td>
                    <select #status (change)="changeArcStatus(graph, entry.value, status.value)">
                        <option>on</option>
                        <option>off</option>
                        <option>error</option>
                        <option>hidden</option>
                    </select>
                </td>
                <td><input #reverse type="checkbox" (change)="changeArcReverse(graph, entry.value, reverse.checked)" /></td>
            </tr>
        </table>

    </div>
</div>


<hy-network-graph #graph 
        [image]="image || ''"
        [arcs]="networkArcs" 
        [nodes]="networkNodes" 
        [class.no-image]="!image"
        (imageUpdated)="imageUpdated()">
</hy-network-graph>

<div *ngIf="!image" class="please-select">Please select the image!!</div>