    
<div class="sidebar" (click)="$event.stopPropagation()">
    <div class="header">
        <h2>{{title}}</h2>
        <div class="esc" (click)="service?.close()">
            <span>ESC</span> 
            <svg [innerHTML]="'collapse' | fico"></svg>
        </div>
    </div>

    <div class="content">
        <ng-content></ng-content>
    </div>

    <ng-container *ngIf="footer" [ngTemplateOutlet]="footer"></ng-container>

    <div class="buttons" *ngIf="buttons">
        <ng-container [ngTemplateOutlet]="buttons"></ng-container>
    </div>
</div>