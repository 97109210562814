import { ConfigurationResponseSchema, ScenariosSchema, SiteInfoResponseSchema } from "src/api/models";
import { AlertOperator, AlertRule } from "../util/AlertRule";


export interface CommonDateFilter {
    timestamp?: Date;
    start?: Date;
    end?: Date;
}

/***** ErrorResponse **********************************************************/

export interface ErrorResponse {
    error: string,
    message: string
}
/***** Network */

export interface NetworkNode {
    node_id: number;
    node_name: string;
    status: "on" | "off" | "error";
    fill: number | null;
    value_1: number | null;
    value_2: number | null;
    title_1: string | null;
    title_2: string | null;
    units_1: string | null;
    units_2: string | null;
}

export interface NetworkArc {
    arc_id: number;
    reverse: boolean;
    status: "on" | "off";
}

export interface DataPoint {
    timestamp: string;
    value: string;
    confidence_min: number;
    confidence_max: number;
}

export interface DeviceDataPoint {
    timestamp: string;
    node_on: boolean;
    node_standby: boolean;
}

export interface BasicSeriesStats {
    stat_main: number;
    change_abs: number;
    change_pct: number;
}

export interface SeriesStats extends BasicSeriesStats {
    stats_past_24h: number;
    stats_forecast_24h: number;
}

// ----- NetworkResponse ---------------------------------------------------- //

export interface StorageSeries {
    node_id: number;
    node_name: string;
    units: string;
    stats: SeriesStats;
    data: DataPoint[];
}

export interface DeviceSeries {
    node_id: number;
    node_name: string;
    units: string;
    stats: SeriesStats;
    data: DeviceDataPoint[];
}

export interface NetworkGraphResponse {
    graph_image_url: string;
    nodes: NetworkNode[];
    arcs: NetworkArc[];
    data_timestamp?: string;
}

export interface UnavailableDataRangesEntry {
    start: string,
    end: string
}

export interface NetworkChartsResponse extends UpdateableResponse {
    storage: StorageSeries[];
    devices: DeviceSeries[];
    unavailable_data_ranges: UnavailableDataRangesEntry[];
    show_time?: boolean;
}

// ----- ForecastsResponse -------------------------------------------------- //

export interface ForecastsTimeSeries {
    forecast_id: number;
    forecast_name: string;
}

export interface ForecastsChartTimeSeries {
    forecast_id: number;
    forecast_name: string;
    node_id: number;
    node_name: string;
    data: DataPoint[];
    stats: SeriesStats;
    units: string;
    confidence_available: boolean;
    show_confidence: boolean;
}

export interface ForecastsChart {
    chart_id: number,
    chart_title: string,
    chart_position: number,
    forecasts: ForecastsChartTimeSeries[]
}

export interface ForecastsResponse extends UpdateableResponse {
    titls: string;
    charts: ForecastsChart[];
    available_forecasts: ForecastsTimeSeries[];
    show_time?: boolean;
}

// -----                   -------------------------------------------------- //

export interface ForecastsEditChartBody {
    chart_id: number;
    chart_name: string;
    forecast_ids: number[];
}


// ----- MetricsResponse ---------------------------------------------------- //

export interface MetricsSeries {
    metric: string;
    units: string;
    stats: SeriesStats;
    data: DataPoint[];
}

export interface MetricsResponse extends UpdateableResponse {
    titls: string;
    profit: MetricsSeries[];
    hydrogen: MetricsSeries[];
    show_time?: boolean;
}

// ----- NotificationsResponse ---------------------------------------------- //

export enum NotificationType {
    Error = 'error',
    Warning = 'warning',
    Information = "information"
}

export interface Notification {
    notification_id: number;
    icon_id: number;
    node_id: number;
    signal_id?: number;
    node_name: string;
    is_new: boolean;
    timestamp: string;
    message: string;
    location: string;
    type: NotificationType;
}

export interface NotificationFilterDevice {
    node_id: number,
    node_name: string
}

export interface NotificationsResponse extends UpdateableResponse {
    notifications: Notification[],

    /** list of devices that can be used to filter notifications. */
    devices: NotificationFilterDevice[],
    n_of_notifications: number,
    notifications_per_page: number
}

// ----- StatusNotificationsResponse ---------------------------------------- //

export interface StatusNotificationsResponse extends UpdateableResponse {
    notifications: Notification[],
}



/** Response for data interfaces that have concept of live data and know
 *  time when new values will be available.
 */
export interface UpdateableResponse {
    next_update_time: string;
}

// ----- MonitoringResponse ------------------------------------------------- //

export interface MonitoringSignal {
    signal_id: number;
    signal_name: string;
    //data: DataPoint[];
    stats: BasicSeriesStats,
    units: string;
    alert_rule: AlertRule,
    alert_enabled: boolean;
    alert_ongoing: boolean;
}

export interface MonitoringDevice {
    node_id: number;
    node_name: string;
    signals: MonitoringSignal[];
}

/** @deprecated */
export interface MonitoringResponse extends UpdateableResponse {
    title: string;
}


export interface MonitoringSignalsResponse extends UpdateableResponse {
    title: string;
    devices: MonitoringDevice[];
}

// ----- MonitoringResponse ------------------------------------------------- //

export interface MonitoringAlertUpdate {
    node_id: number;
    signal_id: number;
    operator: AlertOperator;
    value_1: number;
    value_2: number | undefined;
    alert_enabled: boolean;
}

// ----- NotificationEmailSettings ------------------------------------------ //

export interface NotificationEmailSettingsDevice {
    node_id: number;
    node_name: string;
    warning: boolean;
    error: boolean;
}

export interface NotificationEmailSettings {
    devices: NotificationEmailSettingsDevice[];
}

// ----- NotificationEmailSettings ------------------------------------------ //

export interface ScenarioParam {
    name: string,
    value: string | number | boolean,
    unit?: string
}

export interface ScenariosResponse   {
    id: string,
    name: string,
    params: ScenarioParam[],
    min_available_date: string,
    max_available_date: string,
    missing_dates: string[]
}

export interface SuccessMessage {
    message: string
}


export abstract class BackendService  {


    public token: string = "";
    public settingId: string = "";

    /** @deprecated Should be removed, use getConfiguration() instead */
    public abstract sendResetLink(email: string): Promise<any>;
    public abstract resetPassword(newPassword: string, token: string): Promise<any>;

    public abstract getConfiguration(): Promise<ConfigurationResponseSchema>;
    public abstract getNetworkGraph(date: Date, load_next_available?: boolean): Promise<NetworkGraphResponse>;
    public abstract getNetworkCharts(filter: CommonDateFilter): Promise<NetworkChartsResponse>;
    public abstract getMetrics(filter: CommonDateFilter): Promise<MetricsResponse>;

    public abstract getMonitoringSignals(filter: CommonDateFilter): Promise<MonitoringSignalsResponse>;
    public abstract getMonitoringChart(filter: CommonDateFilter, signal_id: number): Promise<any>;
    public abstract updateMonitoringAlert(date: MonitoringAlertUpdate): Promise<SuccessMessage>;
    public abstract clearMonitoringAlert(node_id: number, signal_id: number): Promise<SuccessMessage>;

    public abstract getForecasts(filter: CommonDateFilter): Promise<ForecastsResponse>;
    public abstract addForecastsChart(chartName: string, forecastIds: number[]): Promise<any>;
    public abstract editForecastsChart(chartId: number, chartName: string, forecastIds: number[]): Promise<any>;
    public abstract deleteForecastsChart(chartId: number): Promise<SuccessMessage>;
    /**
     * Defines order of forecast page charts.
     *
     * @param chartIds Order of IDs defines the order of charts
     */
    public abstract reorderForecastsCharts(chartIds: number[]): Promise<SuccessMessage>;

    /**
     * Loads notificatios filtered and paginated.
     *
     * @param page      Page to fetch. Firts page has index 0.
     * @param startDate Date string in ISO format (YYYY-MM-DD) to be used as the initial date to filter notifications
     * @param endDate   Date string in ISO format (YYYY-MM-DD) to be used as the end date to filter notifications
     * @param deviceIds List of node ids that identify for which devices notifications will be loaded. Empty array loads all devices.
     * @param typeIds   List of strings that specify which type of notifications to display. Allowed values are ["information", "warning", "error"]. Empty array loads all devices types.
     */
    public abstract getNotifications(page: number, startDate?: Date, endDate?: Date, deviceIds?: string[], typeIds?: string[]): Promise<NotificationsResponse>;

    public abstract markNotificationsAsRead(notifications: number[]) : Promise<any>;

    public abstract getNotificationEmailSettings() : Promise<NotificationEmailSettings>;
    public abstract updateNotificationEmailSettings(warningDeviceIds: number[], errorDeviceIds: number[]) : Promise<any>;

    public abstract getScenarios() : Promise<ScenariosSchema>;

    /** Sets units setting */
    public abstract setUnits(units: string) : Promise<any>;
    public abstract enableAudioAlert(enable: boolean) : Promise<any>;
    public abstract enableTooltips(enable: boolean) : Promise<any>;

    public abstract changeUserName(userName: string) : Promise<any>;
    public abstract changeUserPassword() : Promise<any>;


    public abstract getSiteInformation() : Promise<SiteInfoResponseSchema>;
}
