/* tslint:disable */
/* eslint-disable */
import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiConfiguration, ApiConfigurationParams } from './api-configuration';

import { UserDetailsService } from './services/user-details.service';
import { GeneralService } from './services/general.service';
import { NetworkService } from './services/network.service';
import { ForecastsService } from './services/forecasts.service';
import { MetricsService } from './services/metrics.service';
import { NotificationsService } from './services/notifications.service';
import { MonitoringService } from './services/monitoring.service';
import { ApiService } from './services/api.service';

/**
 * Module that provides all services and configuration.
 */
@NgModule({
  imports: [],
  exports: [],
  declarations: [],
  providers: [
    UserDetailsService,
    GeneralService,
    NetworkService,
    ForecastsService,
    MetricsService,
    NotificationsService,
    MonitoringService,
    ApiService,
    ApiConfiguration
  ],
})
export class ApiModule {
  static forRoot(params: ApiConfigurationParams): ModuleWithProviders<ApiModule> {
    return {
      ngModule: ApiModule,
      providers: [
        {
          provide: ApiConfiguration,
          useValue: params
        }
      ]
    }
  }

  constructor( 
    @Optional() @SkipSelf() parentModule: ApiModule,
    @Optional() http: HttpClient
  ) {
    if (parentModule) {
      throw new Error('ApiModule is already loaded. Import in your base AppModule only.');
    }
    if (!http) {
      throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
      'See also https://github.com/angular/angular/issues/20575');
    }
  }
}
