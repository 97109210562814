

type FetchDelegate<T> = () => Promise<T>;

export class PromiseValueMatchCache<T> {
    private lastValue: any;
    private lastFetch?: T;

    public fetch(value: any, doFetch: FetchDelegate<T>) {
        if (this.lastValue === value && this.lastFetch) {
            return Promise.resolve(this.lastFetch);
        }

        return doFetch().then(x => {
            this.lastValue = value;
            this.lastFetch = x;

            return x;
        });
    }

    clear() {
        this.lastValue = undefined;
        this.lastFetch = undefined;
    }
}