import { Component, ViewChild } from '@angular/core';
import { ModalDialogComponent } from 'src/app/components/modal-dialog/modal-dialog.component';
import { BackendService, NotificationEmailSettings, NotificationEmailSettingsDevice } from 'src/app/services/backend.service';
import { DoubleFieldInputService } from 'src/app/services/double-field-input.service';
import { ToastMessageService } from 'src/app/services/toast-message.service';

@Component({
  selector: 'email-notifications-dialog',
  templateUrl: './email-notifications-dialog.component.html',
  styleUrls: ['./email-notifications-dialog.component.scss']
})
export class EmailNotificationsDialogComponent {

    @ViewChild(ModalDialogComponent, { static: true })
    dialog!: ModalDialogComponent;

    loading = true;

    settings?: NotificationEmailSettings;
    allWarningsEnabled = false;
    allErrorsEnabled = false;

    constructor(
        private inputService: DoubleFieldInputService,
        private toast: ToastMessageService, 
        private backend: BackendService) { }

    public open() {
        this.loadSettings();
        this.dialog.open();
    }

    public close() {
        this.dialog.close();
    }

    loadSettings() {
        this.loading = true;
        this.backend.getNotificationEmailSettings()
            .then(x => {
                this.settings = x;
                this.loading = false;

                this.allWarningsEnabled = x.devices.every(x => x.warning);
                this.allErrorsEnabled = x.devices.every(x => x.error);
            })
            .catch(this.toast.networErrorHandler);
    }

    toggleWarning(device: NotificationEmailSettingsDevice) {
        device.warning = !device.warning;

        if (device.warning) {
            this.allWarningsEnabled = this.settings?.devices.every(x => x.warning) || false;
        }
    }

    toggleError(device: NotificationEmailSettingsDevice) {
        device.error = !device.error;

        if (device.warning) {
            this.allErrorsEnabled = this.settings?.devices.every(x => x.error) || false;
        }
    }

    setAllWarnings(enabled: boolean) {
        this.settings?.devices.forEach(x => x.warning = enabled);
        this.allWarningsEnabled = enabled;
    }

    setAllErrorss(enabled: boolean) {
        this.settings?.devices.forEach(x => x.error = enabled);
        this.allErrorsEnabled = enabled;
    }

    save() {
        if (!this.settings || this.loading) {
            return;
        }

        this.loading = true;
        this.backend
            .updateNotificationEmailSettings(
                this.settings.devices.filter(x => x.warning === true).map(x => x.node_id),
                this.settings.devices.filter(x => x.error === true).map(x => x.node_id),
            )
            .then(x => {
                this.close();
            })
            .catch(this.toast.networErrorHandler);
    }

}
