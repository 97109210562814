<right-sidebar title="Your Setup" [service]="settingsSidebarService">
    <div class="tabs">
        <div class="tab" [class.selected]="selectedTab === 0" (click)="selectedTab = 0">SETTINGS</div>
        <div class="tab" [class.selected]="selectedTab === 1" (click)="selectedTab = 1">USER DETAILS</div>
    </div>

    <div class="tab-content tab1" *ngIf="selectedTab === 0">
        <h3>SYSTEM OF MEASUREMENT</h3>

        <div class="som-options">

            <div class="som" *ngFor="let option of somOptions" 
                    [class.selected]="option.id === this.config.units"
                    (click)="setSom(option.id)">
                <div class="marker"></div>
                <div class="label">{{option.label}}</div>
                <div class="value">{{option.value}}</div>
            </div>

        </div>

        <div *ngIf="!env.disableNotifications"
                class="radio" 
                [class.on]="config.audioAlertEnabled"
                (click)="config.audioAlertEnabled = !config.audioAlertEnabled">
            <div class="label">Notifications Audio Alert</div>
            <div class="marker"></div>
        </div>

        <div class="radio" 
                [class.on]="config.tooltipsEnabled"
                (click)="config.tooltipsEnabled = !config.tooltipsEnabled">
            <div class="label">Informational Tooltips</div>
            <div class="marker"></div>
        </div>

        <div class="reset-plots" (click)="config.clearChartConfig()">Reset plot widgets to default</div>
    </div>

    <div class="tab-content tab2" *ngIf="selectedTab === 1">
        <div class="hy-settings-field first">
            <div class="left">
                <div class="label">USERNAME</div>
                <div class="value">{{config.userName}}</div>
            </div>
            <div class="edit" (click)="editName()">
                EDIT <span class="ico"></span>
            </div>
        </div>

        <div class="hy-settings-field">
            <div class="left">
                <div class="label">EMAIL ADDRESS</div>
                <div class="value">{{config.userEmail}}</div>
            </div>
        </div>

        <h4 class="password">PASSWORD</h4>
        <div class="hy-btn secondary change-pwd" (click)="changePassword()">CHANGE PASSWORD</div>
    </div>

    <ng-template #buttons>
        <div class="logout" (click)="logout()">
            <span class="ico"></span>
            LOG OUT
        </div>
    </ng-template>

</right-sidebar>

<hy-password-change-info-dialog></hy-password-change-info-dialog>