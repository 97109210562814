/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';

import { ChartSchema } from '../models/chart-schema';
import { DeleteChartSchema } from '../models/delete-chart-schema';
import { EditChartSchema } from '../models/edit-chart-schema';
import { ForecastResponseSchema } from '../models/forecast-response-schema';
import { ReorderChartsSchema } from '../models/reorder-charts-schema';
import { SimpleResponse } from '../models/simple-response';

@Injectable({ providedIn: 'root' })
export class ForecastsService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getForecastsSettingIdForecastsGet()` */
  static readonly GetForecastsSettingIdForecastsGetPath = '/{setting_id}/forecasts';

  /**
   * Get Forecasts.
   *
   * Get forecasts data.
   * Needs a JWT authentication token and a timestamp.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getForecastsSettingIdForecastsGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  getForecastsSettingIdForecastsGet$Response(
    params: {
      setting_id: string;
      start_date?: (string | string);
      end_date?: (string | string);
      timestamp?: (string | string);
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<ForecastResponseSchema>> {
    const rb = new RequestBuilder(this.rootUrl, ForecastsService.GetForecastsSettingIdForecastsGetPath, 'get');
    if (params) {
      rb.path('setting_id', params.setting_id, {});
      rb.query('start_date', params.start_date, {});
      rb.query('end_date', params.end_date, {});
      rb.query('timestamp', params.timestamp, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ForecastResponseSchema>;
      })
    );
  }

  /**
   * Get Forecasts.
   *
   * Get forecasts data.
   * Needs a JWT authentication token and a timestamp.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getForecastsSettingIdForecastsGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getForecastsSettingIdForecastsGet(
    params: {
      setting_id: string;
      start_date?: (string | string);
      end_date?: (string | string);
      timestamp?: (string | string);
    },
    context?: HttpContext
  ): Observable<ForecastResponseSchema> {
    return this.getForecastsSettingIdForecastsGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<ForecastResponseSchema>): ForecastResponseSchema => r.body)
    );
  }

  /** Path part for operation `addForecastsChartSettingIdForecastsAddChartPost()` */
  static readonly AddForecastsChartSettingIdForecastsAddChartPostPath = '/{setting_id}/forecasts/add_chart';

  /**
   * Add Forecasts Chart.
   *
   * Add a new chart in the Forecasts page.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addForecastsChartSettingIdForecastsAddChartPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addForecastsChartSettingIdForecastsAddChartPost$Response(
    params: {
      setting_id: string;
      body: ChartSchema
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<SimpleResponse>> {
    const rb = new RequestBuilder(this.rootUrl, ForecastsService.AddForecastsChartSettingIdForecastsAddChartPostPath, 'post');
    if (params) {
      rb.path('setting_id', params.setting_id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<SimpleResponse>;
      })
    );
  }

  /**
   * Add Forecasts Chart.
   *
   * Add a new chart in the Forecasts page.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `addForecastsChartSettingIdForecastsAddChartPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addForecastsChartSettingIdForecastsAddChartPost(
    params: {
      setting_id: string;
      body: ChartSchema
    },
    context?: HttpContext
  ): Observable<SimpleResponse> {
    return this.addForecastsChartSettingIdForecastsAddChartPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<SimpleResponse>): SimpleResponse => r.body)
    );
  }

  /** Path part for operation `editForecastsChartSettingIdForecastsEditChartPut()` */
  static readonly EditForecastsChartSettingIdForecastsEditChartPutPath = '/{setting_id}/forecasts/edit_chart';

  /**
   * Edit Forecasts Chart.
   *
   * Edit an existing chart in the Forecasts page.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `editForecastsChartSettingIdForecastsEditChartPut()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  editForecastsChartSettingIdForecastsEditChartPut$Response(
    params: {
      setting_id: string;
      body: EditChartSchema
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<SimpleResponse>> {
    const rb = new RequestBuilder(this.rootUrl, ForecastsService.EditForecastsChartSettingIdForecastsEditChartPutPath, 'put');
    if (params) {
      rb.path('setting_id', params.setting_id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<SimpleResponse>;
      })
    );
  }

  /**
   * Edit Forecasts Chart.
   *
   * Edit an existing chart in the Forecasts page.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `editForecastsChartSettingIdForecastsEditChartPut$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  editForecastsChartSettingIdForecastsEditChartPut(
    params: {
      setting_id: string;
      body: EditChartSchema
    },
    context?: HttpContext
  ): Observable<SimpleResponse> {
    return this.editForecastsChartSettingIdForecastsEditChartPut$Response(params, context).pipe(
      map((r: StrictHttpResponse<SimpleResponse>): SimpleResponse => r.body)
    );
  }

  /** Path part for operation `reorderForecastsChartsSettingIdForecastsReorderChartsPut()` */
  static readonly ReorderForecastsChartsSettingIdForecastsReorderChartsPutPath = '/{setting_id}/forecasts/reorder_charts';

  /**
   * Reorder Forecasts Charts.
   *
   * Change the order of the charts.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `reorderForecastsChartsSettingIdForecastsReorderChartsPut()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  reorderForecastsChartsSettingIdForecastsReorderChartsPut$Response(
    params: {
      setting_id: string;
      body: ReorderChartsSchema
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<any>> {
    const rb = new RequestBuilder(this.rootUrl, ForecastsService.ReorderForecastsChartsSettingIdForecastsReorderChartsPutPath, 'put');
    if (params) {
      rb.path('setting_id', params.setting_id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<any>;
      })
    );
  }

  /**
   * Reorder Forecasts Charts.
   *
   * Change the order of the charts.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `reorderForecastsChartsSettingIdForecastsReorderChartsPut$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  reorderForecastsChartsSettingIdForecastsReorderChartsPut(
    params: {
      setting_id: string;
      body: ReorderChartsSchema
    },
    context?: HttpContext
  ): Observable<any> {
    return this.reorderForecastsChartsSettingIdForecastsReorderChartsPut$Response(params, context).pipe(
      map((r: StrictHttpResponse<any>): any => r.body)
    );
  }

  /** Path part for operation `deleteForecastsChartSettingIdForecastsDeleteChartPost()` */
  static readonly DeleteForecastsChartSettingIdForecastsDeleteChartPostPath = '/{setting_id}/forecasts/delete_chart';

  /**
   * Delete Forecasts Chart.
   *
   * Delete an existing chart in the Forecasts page.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteForecastsChartSettingIdForecastsDeleteChartPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  deleteForecastsChartSettingIdForecastsDeleteChartPost$Response(
    params: {
      setting_id: string;
      body: DeleteChartSchema
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<any>> {
    const rb = new RequestBuilder(this.rootUrl, ForecastsService.DeleteForecastsChartSettingIdForecastsDeleteChartPostPath, 'post');
    if (params) {
      rb.path('setting_id', params.setting_id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<any>;
      })
    );
  }

  /**
   * Delete Forecasts Chart.
   *
   * Delete an existing chart in the Forecasts page.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteForecastsChartSettingIdForecastsDeleteChartPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  deleteForecastsChartSettingIdForecastsDeleteChartPost(
    params: {
      setting_id: string;
      body: DeleteChartSchema
    },
    context?: HttpContext
  ): Observable<any> {
    return this.deleteForecastsChartSettingIdForecastsDeleteChartPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<any>): any => r.body)
    );
  }

}
