<ng-lottie *ngIf="type === 'loading'" 
    [options]="{path: 'assets/loader-basic.json', loop: true}" 
    width="200px" 
    height="200px">
</ng-lottie>

<hy-featured-message *ngIf="type === 'no-data'" title="No data for chosen date range">
    Please try choosing different scope of data in a range from a single 
    day up to 7 days.
</hy-featured-message>


<hy-featured-message *ngIf="type === 'failed'" icon="alert-triangle" title="Unable to load data" buttonLabel="TRY AGAIN" (buttonClick)="tryAgain.emit()">
    We could not load data due to a technical issue on our end. Please try 
    reloading this page again.<br>If the issue keeps happening, contact 
    <a href="mailto:info@h2gopower.com">Our Support</a>.
</hy-featured-message>


