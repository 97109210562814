<h1>Find your H2GO Account</h1>

<div class="hy-input">
    <div class="label">Enter your email address used with H2GO account.</div>
    <input #email type="email" />
</div>

<button class="hy-btn secondary" *ngIf="loading">
    <ng-lottie class="loader" 
        [options]="{path: 'assets/loader-basic.json', loop: true}" 
        width="100px" 
        height="60px">
    </ng-lottie>
</button>

<button class="hy-btn secondary" *ngIf="!loading" (click)="sendLink(email.value)">SEND RESET LINK</button>
<button class="hy-btn outline" routerLink="/login">BACK TO LOGIN</button>
