import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { BackendService, MonitoringAlertUpdate, MonitoringDevice, MonitoringSignal } from 'src/app/services/backend.service';
import { CachedBackendService } from 'src/app/services/cached-backend.service';
import { MonitoringAlertEditorSidebarService } from 'src/app/services/monitoring-alert-editor-sidebar.service';
import { AlertOperator } from 'src/app/util/AlertRule';

@Component({
    selector: 'monitoring-alert-editor-sidebar',
    templateUrl: 'monitoring-alert-editor-sidebar.component.html',
    styleUrls: ['monitoring-alert-editor-sidebar.component.scss'],
    host: {
        "[class.hy-sidebar-host]": "true",
        "[class.slide-out]": "service.closing"
    }
})
export class MonitoringAlertEditorSidebarComponent implements OnInit {

    @Input()
    service!: MonitoringAlertEditorSidebarService;

    //alertData!: MonitoringAlertUpdate;

    error?: string;

    showErrors = false;

    loading = false;

    formOperator = new FormControl<AlertOperator>(AlertOperator.LowerThen, [Validators.required]);
    formValue1 = new FormControl<string>("", [Validators.required, floatValidator]);
    formValue2 = new FormControl<string | undefined>(undefined);
    formEnabled = new FormControl<boolean>(true);

    form = new FormGroup({
        operator: this.formOperator,
        value1  : this.formValue1,
        value2  : this.formValue2,
        enabled : this.formEnabled
    });

    @Output()
    public updated = new EventEmitter();

    constructor(private backend: BackendService) {
    }

    get device(): MonitoringDevice {
        return this.service.device!;
    }

    get signal(): MonitoringSignal {
        return this.service.signal!;
    }

    ngOnInit(): void {
        const rule =  this.signal!.alert_rule;

        this.formOperator.setValue(rule.operator);
        this.formValue1.setValue(rule.value_1?.toString());
        this.formValue2.setValue(rule.value_2?.toString());
        this.formEnabled.setValue(true);

        this.onOperatorChange();
    }

    onOperatorChange() {
        const operator = this.formOperator.value;
        if (operator === AlertOperator.OutsideRange) {
            this.formValue2.setValidators([Validators.required]);
        } else {
            this.formValue2.setValidators([]);
        }
        this.formValue2.updateValueAndValidity();
    }

    private isFormValid() {
        if (this.form.valid) {
            return true;
        }
        else {
            this.showErrors = true;
            return false;
        }
    }

    updateAlert() {
        if (!this.isFormValid()) {
            return;
        }
        
        const alertData : MonitoringAlertUpdate = {
            node_id      : this.device.node_id,
            signal_id    : this.signal.signal_id,
            operator     : this.formOperator.value!,
            value_1      : parseFloat(this.formValue1.value!),
            value_2      : this.formValue2.value ? parseFloat(this.formValue2.value) : undefined,
            // Alert is always enabled in the sidebar because we want it to be 
            // enabled when user clicks +Add to create the rule.
            alert_enabled: this.formEnabled.value ?? false,
        };

        this.error = undefined;
        this.loading = true;
        this.backend
            .updateMonitoringAlert(alertData)
            .then(x => {
                if (this.backend instanceof CachedBackendService) {
                    this.backend.clearMonitoringSignalsCache();
                }
                this.updated.emit();
                this.service!.close();
                
            })
            .catch(err => {
                console.log(err);
                this.error = "Failed to save alert rule. Please check form values and try again."
            })
            .finally(() => this.loading = false);
    }
}


function floatValidator(control: AbstractControl) {
    const regex = /^-?[0-9]+(\.[0-9]+)?$/;

    if (!control.value) { 
        return null;
    }

    if (regex.test(control.value)) {
        return null;
    }

    
    return { 'float': true };

}