import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { NetworkArc, NetworkNode } from 'src/app/services/backend.service';

const ArcIdPrefix = "hy-arc-";
const NodeIdPrefix = "hy-node-";
const BusBarIdPrefix = "hy-busbar-";
const NodeFillIdPrefix = "hy-fill-";
const NodeNameIdPrefix = "hy-name-";
const NodeTitle1IdPrefix = "hy-title1-";
const NodeTitle2IdPrefix = "hy-title2-";
const NodeValue1IdPrefix = "hy-value1-";
const NodeValue2IdPrefix = "hy-value2-";
const ArcTerminalIdPrefix = "hy-terminal-";


@Component({
    selector: 'hy-network-graph',
    template: '',
    styleUrls: ['./network-graph.component.scss']
})
export class NetworkGraphComponent implements OnChanges {

    @Input()
    public image: string = "";

    @Input()
    public nodes?: NetworkNode[];

    @Input()
    public arcs?: NetworkArc[];

    // Made availabe public for NetworkGraphConfigComponent
    /** @internal */
    public arcMap: Map<number, GraphArc> = new Map();
    /** @internal */
    public nodeMap: Map<number, NodeUpdateable> = new Map();

    @Output()
    public imageUpdated = new EventEmitter();

    constructor(private elRef: ElementRef<HTMLElement>, public domSanitizer: DomSanitizer) { 
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.image) {
            this.elRef.nativeElement.innerHTML = this.image;

            this.arcMap = NetworkGraphUtil.findArcs(this.elRef.nativeElement);
            this.nodeMap = NetworkGraphUtil.findNodes(this.elRef.nativeElement);
            this.imageUpdated.emit();
        }
        if (changes.arcs) {
            this.applyArcState(changes.arcs.currentValue);
        }
        if (changes.nodes) {
            this.applyNodeState(changes.nodes.currentValue);
        }
    }

    private applyArcState(arcs?: NetworkArc[]) {
        if (!arcs) {
            return;
        }

        arcs.forEach(arc => {
            const graphArc = this.arcMap.get(arc.arc_id);

            if (!graphArc) {
                console.error("Received update for arc with id " + arc.arc_id + ", which desn't exist in graph image");
                return;
            }

            graphArc.update(arc);
        });
    }

    private applyNodeState(nodes?: NetworkNode[]) {
        if (!nodes) {
            return;
        }

        nodes.forEach(node => {
            const graphNode = this.nodeMap.get(node.node_id);

            if (!graphNode) {
                console.error("Received update for node with id " + node.node_id + ", which desn't exist in graph image");
                return;
            }

            graphNode.update(node);
        });
    }


}

export interface NodeUpdateable {
    readonly id: number;
    update(node: NetworkNode): void;

    get hasFill(): boolean;
    get hasName(): boolean;
    get hasTitle1(): boolean;
    get hasTitle2(): boolean;
    get hasValue1(): boolean;
    get hasValue2(): boolean;
}

export class GraphArc {

    public readonly id;

    /** SVG element that represent the arc between two nodes */
    private readonly arcEl: Element;
    private readonly terminalEls: NodeListOf<Element>;

    /** 
     * Clone of the arcEl that is positioned over original arc to cast a dashed 
     * shadow over the original arc to produce an efect of flow through arc. */
    private animationEl: Element;

    constructor(id: number, arcEl: Element, hostEl: Element) {
        this.id = id;
        this.arcEl = arcEl;
        this.animationEl = <Element>arcEl.cloneNode();
        this.animationEl.classList.add("arc-anim");
        
        arcEl.after(this.animationEl);

        this.terminalEls = hostEl.querySelectorAll<Element>("[id^='" + ArcTerminalIdPrefix + id + "-']");
    }

    update(arc: NetworkArc) {
        this.arcEl.setAttribute("class", "arc-" + arc.status);

        this.animationEl.setAttribute("class", "arc-anim-" + arc.status + (arc.reverse ? " reverse" : ""));

        this.terminalEls.forEach(terminalEl => {
            terminalEl.setAttribute("class", "terminal-" + arc.status);
        });
    }
}

export class GraphNode implements NodeUpdateable {

    public readonly id: number;
    private readonly fillMaxHeight = 128;
    private fillTopY = 0;

    private readonly nodeEl: Element;
    private readonly fillEl: SVGRectElement | null;
    private readonly nameEl: Element | null;
    private readonly title1El: Element | null;
    private readonly title2El: Element | null;
    private readonly value1El: Element | null;
    private readonly value2El: Element | null;

    get hasFill() {
        return this.fillEl !== null;
    }

    get hasName() {
        return this.nameEl !== null;
    }

    get hasTitle1() {
        return this.title1El !== null;
    }

    get hasTitle2() {
        return this.title2El !== null;
    }

    get hasValue1() {
        return this.value1El !== null;
    }

    get hasValue2() {
        return this.value2El !== null;
    }

    constructor(id: number, nodeEl: Element, hostEl: Element) {
        this.id = id;
        this.nodeEl = nodeEl;

        this.fillEl   = this.getConnectedFillEl(id, hostEl, NodeFillIdPrefix);
        this.nameEl   = this.getConnectedEl(id, hostEl, NodeNameIdPrefix, "tspan");
        this.title1El = this.getConnectedEl(id, hostEl, NodeTitle1IdPrefix, "tspan");
        this.title2El = this.getConnectedEl(id, hostEl, NodeTitle2IdPrefix, "tspan");
        this.value1El = this.getConnectedValueEl(id, hostEl, NodeValue1IdPrefix);
        this.value2El = this.getConnectedValueEl(id, hostEl, NodeValue2IdPrefix);
    }

    private getConnectedFillEl(id: number, hostEl: Element, prefix: string) {
        const fillEl = <SVGRectElement | null>this.getConnectedEl(id, hostEl, prefix);

        if (!fillEl) {
            return null;
        }

        this.fillTopY = fillEl.y.baseVal.value - (this.fillMaxHeight - fillEl.height.baseVal.value);

        return fillEl;
    }

    private getConnectedValueEl(id: number, hostEl: Element, prefix: string) {
        // Updating values is more complex as we have separate value and units 
        // that need to be layed out one afte the other
        // Figma outputs SVG with value and unit as separate text nodes that 
        // have embaded tspan that is then positioned at right location. This is
        // a problem when we need dynamic layout dependent on value text width.
        // To handle this we remove units <text> node and add second <tspan> to 
        // value node
        //
        // Expected SVG structure:
        // <g id="hy-name-XX">
        //    <text><tspan x y>Value</tspan></text>
        //    <text><tspan x y>Units</tspan></text>
        // </g>
        
        const groupEl = this.getConnectedEl(id, hostEl, prefix);

        if (!groupEl) {
            return null;
        }

        const valueEl = groupEl.querySelector("text:first-child");
        const unitsEl = groupEl.querySelector("text:last-child");

        if (!valueEl) {
            console.error("Found value group for node id " + id + " but value <text> is missing.");
            return null;
        }

        if (!unitsEl) {
            console.error("Found value group for node id " + id + " but units <text> is missing.");
            return null;
        }

        // We remove units <text> tag as we will use one generated for the value
        unitsEl.remove();

        const tspanEl = valueEl.querySelector("tspan");

        if (!tspanEl) {
            console.error("Found value group for node id " + id + " but value <tspan> is missing.");
        }

        return tspanEl;
    }

    private getConnectedEl(id: number, hostEl: Element, prefix: string, subquery?: string) {
        let el = hostEl.querySelector<Element>("[id='" + prefix + id + "']");

        if (subquery && el) {
            el = el.querySelector(subquery);
        }

        return el;
    }

    update(node: NetworkNode) {
        this.nodeEl.classList.remove("node-on", "node-off", "node-error")
        this.nodeEl.classList.add("node-" + node.status);

        if (this.fillEl) {
            this.fillEl.classList.remove("fill-on", "fill-off", "fill-error")
            this.fillEl.classList.add("fill-" + node.status);
        }

        if (this.nameEl) this.nameEl.textContent     = (node.node_name || "").toUpperCase();
        if (this.title1El) this.title1El.textContent = node.title_1;
        if (this.title2El) this.title2El.textContent = node.title_2;

        this.updateValue(this.value1El, node.value_1, node.units_1);
        this.updateValue(this.value2El, node.value_2, node.units_2);

        if (node.fill !== null) this.updateFill(node);
    }

    private updateValue(el: Element | null, value: number | null, units: string | null) {
        if (!el) {
            return;
        }

        if (value !== null) {
            el.innerHTML = (+value.toFixed(1)) + "<tspan font-size=18 font-family=DINPro>" + units + "</tspan>";
        }
        else {
            el.innerHTML = "";
        }
    }

    private updateFill(node: NetworkNode) {
        if (!this.fillEl) {
            console.error("Receive fill data for node id " + node.node_id + " but no there is no matching fill element in graph image");
            return;
        }

        const valuePct = (node.fill || 0) / 100;
        const fillHeight = this.fillMaxHeight * valuePct;

        this.fillEl.y.baseVal.value = this.fillTopY + (this.fillMaxHeight  - fillHeight);
        this.fillEl.height.baseVal.value = fillHeight;
    }

}

export class GraphBusBar implements NodeUpdateable {
    public readonly id: number;
    private readonly nodeEl;
    
    get hasFill()   { return false; }
    get hasName()   { return false; }
    get hasTitle1() { return false; }
    get hasTitle2() { return false; }
    get hasValue1() { return false; }
    get hasValue2() { return false; }
    
    constructor(id: number, nodeEl: Element) {
        this.id = id;
        this.nodeEl = nodeEl;
    }

    update(node: NetworkNode) {
        this.nodeEl.classList.remove("busbar-on", "busbar-off", "busbar-error")
        this.nodeEl.classList.add("busbar-" + node.status);
    }
}

export class NetworkGraphUtil {

    public static findArcs(host: HTMLElement): Map<number, GraphArc> {
        const arcEls = host.querySelectorAll("[id^='" + ArcIdPrefix + "']");

        const arcs = new Map<number, GraphArc>();

        arcEls.forEach(el => {
            const arcId = el.id.substring(ArcIdPrefix.length);
            const arcIdNum = parseInt(arcId);

            if (isNaN(arcIdNum)) {
                console.error("Found arc with invalid ID '" + arcId + "'. ID needs to be a positive integer.")
                return;
            }

            arcs.set(arcIdNum, new GraphArc(arcIdNum, el, host));
        })

        return arcs;
    }

    /**
     * Finds all nodes and busbars in the image.
     * Bussbars are also handled as nodes as their status comes from nodes array 
     * in backend data.
     */
    public static findNodes(host: HTMLElement): Map<number, NodeUpdateable> {
        const nodes = new Map<number, NodeUpdateable>();

        // Find all nodes
        const nodeEls = host.querySelectorAll("[id^='" + NodeIdPrefix + "']");
        nodeEls.forEach(el => {
            const nodeId = el.id.substring(NodeIdPrefix.length);
            const nodeIdNum = parseInt(nodeId);

            if (isNaN(nodeIdNum)) {
                console.error("Found node with invalid ID '" + nodeId + "'. ID needs to be a positive integer.")
                return;
            }

            nodes.set(nodeIdNum, new GraphNode(nodeIdNum, el, host));
        });

        // Find all busbars
        const busbarEls = host.querySelectorAll("[id^='" + BusBarIdPrefix + "']");
        busbarEls.forEach(el => {
            const busbarId = el.id.substring(BusBarIdPrefix.length);
            const busbarIdNum = parseInt(busbarId);

            if (isNaN(busbarIdNum)) {
                console.error("Found busbar with invalid ID '" + busbarId + "'. ID needs to be a positive integer.")
                return;
            }

            nodes.set(busbarIdNum, new GraphBusBar(busbarIdNum, el));
        });

        return nodes;
    }
}