import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@auth0/auth0-angular';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'hy-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent {

    env = environment;

    constructor(public auth: AuthService, public router: Router) { }

    logout() {
        this.auth.logout();
        this.router.navigate(["/login"]);
    }

}
