import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

export type IconType = "x-remove-16"
                     | "tick"
                     | "pin_icon"
                     | "clock-back"
                     | "alert-triangle"
                     | "calendar-minus-01"
                     | "collapse"
                     | "mail-01"
                     | "alert_icon"
                     | "dataflow-01"
                     | "info-circle"
                     | "check";

@Pipe({ name: 'fico' })
export class FicoPipe implements PipeTransform {

    constructor(private sanitizer: DomSanitizer) { }

    transform(icon: IconType): SafeHtml {
        const ret = `<use xlink:href='assets/sprite.symbol.svg#${icon}'></use>`;

        return this.sanitizer.bypassSecurityTrustHtml(ret);
    }
}