import { Subject } from "rxjs";
import { UpdateableResponse } from "../services/backend.service";


type UpdateableDelegate<T> = () => Promise<T>;

/** Class that is used to periodicly load new data. */
export class UpdateableFetcher<T extends UpdateableResponse> {

    private timer?: number;

    constructor(
        /** Fetch telegate that is used to create the fetch request */
        private delegate: UpdateableDelegate<T>,
    ) {
        this.fetch();
    }

    private fetch() {
        this.delegate()
            .then(x => {
                this.setNextUpdateTime(new Date(x.next_update_time));
            })
            .catch(err => {
                // If we get an error, try again in 10 seconds.
                this.setNextUpdateTime(new Date(Date.now() + 15000));
            });
    }

    private setNextUpdateTime(date: Date) {
        const updateInMilis = Date.now() - date.getTime();
        if (updateInMilis > 0) {
            this.timer = setTimeout(() => this.fetch(), updateInMilis);
        }
    }

    public cancel() {
        clearTimeout(this.timer);
        this.timer = undefined;
    }
}