<h1>Reset Your Password</h1>

<div class="hy-input">
    <div class="label">Enter your new password</div>
    <input #pass1 type="password" />
</div>

<div class="hy-input">
    <div class="label">Enter your password one more time</div>
    <input #pass2 type="password" />
</div>

<div class="error" *ngIf="error">{{error}}</div>

<button class="hy-btn secondary" (click)="resetPassword(pass1.value, pass2.value)">RESET PASSWORD</button>

<p class="desc">Resetting your password will log you out<br>of all your active HyAI sessions.</p>
