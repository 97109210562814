import { Injectable } from '@angular/core';

import { AuthService } from './auth.service';
import { AuthService as Auth0Service } from '@auth0/auth0-angular';
import { BackendService } from './backend.service';
import { Router } from '@angular/router';
import { ConfigurationService } from './configuration.service';

@Injectable({
    providedIn: 'root',
})
export class StartupService {


    constructor(
        private backend: BackendService,
        private config: ConfigurationService,
        private auth0: Auth0Service,
        private auth: AuthService,
        private router: Router
    ) { }

    load(): Promise<any> {
        return new Promise((resolve, reject) => {
            // First check if user is authenticated with Auth0
            const subscrption = this.auth0.isAuthenticated$.subscribe((authenticated: boolean) =>{
                subscrption.unsubscribe();
            
                if (authenticated) {
                    // If the user is authenticated, then load configuration and initialize the app
                    this.config.loadConfiguration()
                        .then(() => resolve(true))
                        .catch(error => {
                            this.auth.logout();
                            reject(error);
                        });
                }
                else {
                    this.auth.login({appState: {target: this.router.url}});
                    return reject("User is not authenticated");
                }
            });
        });

    }


}
