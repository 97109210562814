import { ChangeDetectorRef, Component, Input } from '@angular/core';
import { GraphArc, NetworkGraphComponent, NodeUpdateable } from 'src/app/components/network-graph/network-graph.component';
import { NetworkArc, NetworkNode } from 'src/app/services/backend.service';


@Component({
    selector: 'network-graph-config',
    templateUrl: 'network-graph-config.component.html',
    styleUrls: ['network-graph-config.component.scss']
})
export class NetworkGraphConfigComponent {

    public image?: string;

    networkArcs?: NetworkArc[];
    networkNodes?: NetworkNode[];

    constructor(private cd: ChangeDetectorRef) {

    }

    loadImage(files: any) {
        if (files.length === 0) {
            return;
        } 

        const reader = new FileReader();
        reader.onload = () => {
            this.image = reader.result?.toString() || "";
            this.networkArcs = undefined;
            this.networkNodes = undefined;
        };
        reader.readAsText(files[0]);
    }

    changeArcStatus(graph: NetworkGraphComponent, arc: GraphArc, status: string) {
        this.createInitianArcState(graph);

        const networkArc = this.networkArcs!.find(x => x.arc_id == arc.id);
        if (networkArc) {
            networkArc.status = <any>status;
        }
    }

    changeArcReverse(graph: NetworkGraphComponent, arc: GraphArc, reverse: boolean) {
        this.createInitianArcState(graph);

        const networkArc = this.networkArcs!.find(x => x.arc_id == arc.id);
        if (networkArc) {
            networkArc.reverse = reverse;
        }
    }

    changeNodeStatus(graph: NetworkGraphComponent, node: NodeUpdateable, status: string) {
        this.createInitianNodesState(graph);

        const networkNode = this.networkNodes!.find(x => x.node_id == node.id);
        if (networkNode) {
            networkNode.status = <any>status;
        }
    }

    changeNodeProp(
            graph: NetworkGraphComponent, 
            node: GraphArc, 
            prop: "node_name" | "title_1" | "title_2" | "units_1" | "units_2" , 
            status: string) {
        this.createInitianNodesState(graph);

        const networkNode = this.networkNodes!.find(x => x.node_id == node.id);
        if (networkNode) {
            networkNode[prop] = <any>status;
        }
    }

    changeNodeNumProp(
            graph: NetworkGraphComponent, 
            node: GraphArc, 
            prop: "fill" | "value_1" | "value_2", 
            status: string) {
        this.createInitianNodesState(graph);

        const networkNode = this.networkNodes!.find(x => x.node_id == node.id);
        if (networkNode) {
            networkNode[prop] = parseFloat(status);
        }
    }
    
    
    private createInitianArcState(graph: NetworkGraphComponent) {
        if (this.networkArcs) {
            // clone so angular notices change
            this.networkArcs = [...this.networkArcs];
        }
        else {
            this.networkArcs = 
            Array.from(graph.arcMap.values())
                 .map(x => ({ 
                    arc_id: x.id, 
                    status: "on", 
                    reverse: false 
                }));
        }
    }

    private createInitianNodesState(graph: NetworkGraphComponent) {
        if (this.networkNodes) {
            // clone so angular notices change
            this.networkNodes = [...this.networkNodes];
        }
        else {

            this.networkNodes = 
                Array.from(graph.nodeMap.values())
                    .map(x => ({ 
                        node_id: x.id,
                        node_name: "Node " + x.id,
                        status: "on",
                        fill: null,
                        value_1: null,
                        value_2: null,
                        title_1: null,
                        title_2: null,
                        units_1: null,
                        units_2: null
                    }));
        }
    }

    imageUpdated() {
        this.cd.detectChanges();

    }
}
