import { Component } from '@angular/core';
import { FullSizeChartPageComponent } from './full-size-chart-page.component';
import { CommonDateFilter } from 'src/app/services/backend.service';

@Component({
  selector: 'hy-metrics-hydrogen-chart-page',
  templateUrl: './full-size-chart-page.component.html',
  styleUrls: ['./full-size-chart-page.component.scss']
})
export class MetricsHydrogenChartPageComponent extends FullSizeChartPageComponent {

    id = "metrics-hydrogen";

    metricTitle = "PLOT GRAPHS";
    metricKey = "metric";

    backRouterLink = "/metrics";

    protected loadChartDataImpl(filter: CommonDateFilter) {
        return this.backendService
            .getMetrics(filter)
            .then(x => {
                if (!x) {
                    return undefined;
                }

                this.showTimeInTooltips = (x.show_time !== false);
                
                return {
                    data: x.hydrogen,
                    nextUpdateTime: x.next_update_time
                }
            });
    }

}