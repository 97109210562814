/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';

import { NotificationUpdateSettingsSchema } from '../models/notification-update-settings-schema';
import { UpdateNotificationSchema } from '../models/update-notification-schema';

@Injectable({ providedIn: 'root' })
export class NotificationsService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getNotificationsScenarioIdNotificationsGet()` */
  static readonly GetNotificationsScenarioIdNotificationsGetPath = '/{scenario_id}/notifications';

  /**
   * Get Notifications.
   *
   * Get notifications to be displayed in the notification archive.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getNotificationsScenarioIdNotificationsGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  getNotificationsScenarioIdNotificationsGet$Response(
    params: {
      scenario_id: string;
      page_index: number;
      page_size?: number;
      start_date?: (string | string);
      end_date?: (string | string);
      devices?: string;
      type?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<any>> {
    const rb = new RequestBuilder(this.rootUrl, NotificationsService.GetNotificationsScenarioIdNotificationsGetPath, 'get');
    if (params) {
      rb.path('scenario_id', params.scenario_id, {});
      rb.query('page_index', params.page_index, {});
      rb.query('page_size', params.page_size, {});
      rb.query('start_date', params.start_date, {});
      rb.query('end_date', params.end_date, {});
      rb.query('devices', params.devices, {});
      rb.query('type', params.type, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<any>;
      })
    );
  }

  /**
   * Get Notifications.
   *
   * Get notifications to be displayed in the notification archive.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getNotificationsScenarioIdNotificationsGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getNotificationsScenarioIdNotificationsGet(
    params: {
      scenario_id: string;
      page_index: number;
      page_size?: number;
      start_date?: (string | string);
      end_date?: (string | string);
      devices?: string;
      type?: string;
    },
    context?: HttpContext
  ): Observable<any> {
    return this.getNotificationsScenarioIdNotificationsGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<any>): any => r.body)
    );
  }

  /** Path part for operation `updateNotificationViewStatusScenarioIdStatusUpdateNotificationsPut()` */
  static readonly UpdateNotificationViewStatusScenarioIdStatusUpdateNotificationsPutPath = '/{scenario_id}/status/update_notifications';

  /**
   * Update Notification View Status.
   *
   * Update the view status of the notifications specified in ``notification_ids``.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateNotificationViewStatusScenarioIdStatusUpdateNotificationsPut()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateNotificationViewStatusScenarioIdStatusUpdateNotificationsPut$Response(
    params: {
      scenario_id: string;
      body: UpdateNotificationSchema
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<any>> {
    const rb = new RequestBuilder(this.rootUrl, NotificationsService.UpdateNotificationViewStatusScenarioIdStatusUpdateNotificationsPutPath, 'put');
    if (params) {
      rb.path('scenario_id', params.scenario_id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<any>;
      })
    );
  }

  /**
   * Update Notification View Status.
   *
   * Update the view status of the notifications specified in ``notification_ids``.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateNotificationViewStatusScenarioIdStatusUpdateNotificationsPut$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateNotificationViewStatusScenarioIdStatusUpdateNotificationsPut(
    params: {
      scenario_id: string;
      body: UpdateNotificationSchema
    },
    context?: HttpContext
  ): Observable<any> {
    return this.updateNotificationViewStatusScenarioIdStatusUpdateNotificationsPut$Response(params, context).pipe(
      map((r: StrictHttpResponse<any>): any => r.body)
    );
  }

  /** Path part for operation `getNotificationsEmailSettingsScenarioIdNotificationsEmailSettingsGet()` */
  static readonly GetNotificationsEmailSettingsScenarioIdNotificationsEmailSettingsGetPath = '/{scenario_id}/notifications/email_settings';

  /**
   * Get Notifications Email Settings.
   *
   * Get the email settings for the notifications.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getNotificationsEmailSettingsScenarioIdNotificationsEmailSettingsGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  getNotificationsEmailSettingsScenarioIdNotificationsEmailSettingsGet$Response(
    params: {
      scenario_id: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<any>> {
    const rb = new RequestBuilder(this.rootUrl, NotificationsService.GetNotificationsEmailSettingsScenarioIdNotificationsEmailSettingsGetPath, 'get');
    if (params) {
      rb.path('scenario_id', params.scenario_id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<any>;
      })
    );
  }

  /**
   * Get Notifications Email Settings.
   *
   * Get the email settings for the notifications.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getNotificationsEmailSettingsScenarioIdNotificationsEmailSettingsGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getNotificationsEmailSettingsScenarioIdNotificationsEmailSettingsGet(
    params: {
      scenario_id: string;
    },
    context?: HttpContext
  ): Observable<any> {
    return this.getNotificationsEmailSettingsScenarioIdNotificationsEmailSettingsGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<any>): any => r.body)
    );
  }

  /** Path part for operation `updateNotificationEmailSettingsScenarioIdNotificationsUpdateEmailSettingsPut()` */
  static readonly UpdateNotificationEmailSettingsScenarioIdNotificationsUpdateEmailSettingsPutPath = '/{scenario_id}/notifications/update_email_settings';

  /**
   * Update Notification Email Settings.
   *
   * Update the email settings of notifications.
   * .. example::
   * $ curl http://localhost:8000/{scenario_id}/notifications/update_email_settings -X PUT    -H 'Accept: application/json' -H 'Authorization: Bearer <access_token>'     -d '{"warning_ids": [0, 1, 2], "error_ids": [0, 1, 2, 3, 4, 5, 6, 7]}'
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateNotificationEmailSettingsScenarioIdNotificationsUpdateEmailSettingsPut()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateNotificationEmailSettingsScenarioIdNotificationsUpdateEmailSettingsPut$Response(
    params: {
      scenario_id: string;
      body: NotificationUpdateSettingsSchema
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<any>> {
    const rb = new RequestBuilder(this.rootUrl, NotificationsService.UpdateNotificationEmailSettingsScenarioIdNotificationsUpdateEmailSettingsPutPath, 'put');
    if (params) {
      rb.path('scenario_id', params.scenario_id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<any>;
      })
    );
  }

  /**
   * Update Notification Email Settings.
   *
   * Update the email settings of notifications.
   * .. example::
   * $ curl http://localhost:8000/{scenario_id}/notifications/update_email_settings -X PUT    -H 'Accept: application/json' -H 'Authorization: Bearer <access_token>'     -d '{"warning_ids": [0, 1, 2], "error_ids": [0, 1, 2, 3, 4, 5, 6, 7]}'
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateNotificationEmailSettingsScenarioIdNotificationsUpdateEmailSettingsPut$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateNotificationEmailSettingsScenarioIdNotificationsUpdateEmailSettingsPut(
    params: {
      scenario_id: string;
      body: NotificationUpdateSettingsSchema
    },
    context?: HttpContext
  ): Observable<any> {
    return this.updateNotificationEmailSettingsScenarioIdNotificationsUpdateEmailSettingsPut$Response(params, context).pipe(
      map((r: StrictHttpResponse<any>): any => r.body)
    );
  }

}
