import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IconType } from '../pipes/fico.pipe';

@Component({
    selector: 'hy-featured-message',
    templateUrl: './featured-message.component.html',
    styleUrls: ['./featured-message.component.scss'],
    host: {
        "[class.error]": "icon ==='alert-triangle'"
    }
})
export class FeaturedMessageComponent {

    @Input()
    public icon: IconType = "calendar-minus-01";

    @Input()
    public title: string = "";

    @Input()
    public message: string = "";

    @Input()
    public buttonLabel?: string = "";

    @Output()
    public buttonClick = new EventEmitter<void>();

}
