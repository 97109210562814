import { Component, ContentChildren, Input, QueryList } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { ErrorMsgDirective } from './error-msg.directive';

@Component({
  selector: 'hy-form-errors',
  templateUrl: './form-errors.component.html',
  styleUrls: ['./form-errors.component.scss']
})
export class FormErrorsComponent {

    /**
     * Form control for which errors are displayed.
     */
    @Input() 
    public control!: AbstractControl;

    /** Forces display of the error messages */
    @Input()
    public show = false

    @ContentChildren(ErrorMsgDirective)
    public messages!: QueryList<ErrorMsgDirective>;

}
