import { DateRange } from "../layout/time-slider/time-slider.component";
import { DateSelectionHeaderService } from "../services/date-selection-header.service";
import { dateNextDayStart } from "./DateUtils";

export class ChartLoadingSupport {
    public fullRangeStart: Date;
    public fullRangeEnd: Date;

    public zoomStartDate: Date;
    public zoomEndDate: Date;

    constructor(private dateSelectionService: DateSelectionHeaderService) {
        this.fullRangeStart = new Date(dateSelectionService.selectedStartDate);
        this.fullRangeEnd = new Date(dateNextDayStart(dateSelectionService.selectedEndDate ?? dateSelectionService.selectedStartDate));

        const stateZoomStart = history.state.zoomStart;
        const stateZoomEnd = history.state.zoomEnd;

        if (stateZoomEnd && stateZoomStart) {
            this.zoomStartDate = new Date(stateZoomStart);
            this.zoomEndDate = new Date(stateZoomEnd);
        }
        else {
            this.zoomStartDate = this.fullRangeStart;
            this.zoomEndDate = this.fullRangeEnd;
        }
    }

    public getFilter() {
        if (this.dateSelectionService.liveModeSelected) {
            return {
                timestamp: this.dateSelectionService.selectedDate
            };
        }
        else {
            return {
                start: this.zoomStartDate,
                end: this.zoomEndDate
            };
        }
    }

    selectedDateChanged() {
        this.zoomStartDate = this.fullRangeStart = new Date(this.dateSelectionService.selectedStartDate);
        this.zoomEndDate = this.fullRangeEnd = new Date(dateNextDayStart(this.dateSelectionService.selectedEndDate ?? this.dateSelectionService.selectedStartDate));
    }


    onZoomSelection(range: DateRange) {
        this.zoomStartDate = range.start;
        this.zoomEndDate = range.end;
        
    }
}
