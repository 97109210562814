import { Directive, inject } from '@angular/core';
import { DateRange } from 'src/app/layout/time-slider/time-slider.component';
import { LoadingStatus } from 'src/app/modules/shared/loading-status';
import { BackendService, CommonDateFilter } from 'src/app/services/backend.service';
import { DateSelectionHeaderService } from 'src/app/services/date-selection-header.service';
import { ToastMessageService } from 'src/app/services/toast-message.service';
import { AlertRule } from 'src/app/util/AlertRule';
import { ChartLoadingSupport } from 'src/app/util/ChartLoadingSupport';

export interface ChartData {
    data: any,
    nextUpdateTime: string
}

@Directive()
export abstract class FullSizeChartPageComponent {

    private readonly toast: ToastMessageService = inject(ToastMessageService);

    type: "blocks" | "line" = "line";

    extraTitle?: string;
    extraKey?: string;

    loadingStatus: LoadingStatus = LoadingStatus.Loading;

    chartTitle?: string;

    zoomChartData: any;
    chartData: any;

    showLegend = true;

    alertRule?: AlertRule;

    cls: ChartLoadingSupport;

    showTimeInTooltips = true;

    /** 
     * Inherited components can use this property to disable live view styling, 
     * where history is grayed out an future is highlighted. Designed for 
     * monitoring charts which do not have future data.
     */
    disableLiveModeForChart = false;

    constructor(
            protected backendService: BackendService, 
            public dateSelectionService: DateSelectionHeaderService) {

        this.cls = new ChartLoadingSupport(this.dateSelectionService);
    }

    ngOnInit() {
        this.reload();
    }

    get nowDate() : Date | undefined {
        return this.dateSelectionService.selectedDate;
    }

    reload() {
        this.loadChartData(this.cls.getFilter());
    }

    protected abstract loadChartDataImpl(filter: CommonDateFilter): Promise<ChartData | undefined>;

    private loadChartData(filter: CommonDateFilter) {
        this.loadingStatus = LoadingStatus.Loading;
        this.loadChartDataImpl(filter)
            .then(x => {
                if (x) {
                    if (!this.zoomChartData) {
                        this.zoomChartData = x.data;
                    }
                    this.loadingStatus = LoadingStatus.Loaded;
                    this.chartData = x.data;

                    if (x.nextUpdateTime) {
                        this.dateSelectionService.setNextUpdateTime(new Date(x.nextUpdateTime));
                    }
                }
                else {
                    this.loadingStatus = LoadingStatus.NoData;
                    this.chartData = undefined;
                }
            })
            .catch(() => this.loadingStatus = LoadingStatus.Failed);
    }

    selectedDateChanged() {
        this.cls.selectedDateChanged();
        this.zoomChartData = undefined;
        this.reload();
    }


    onZoomSelection(range: DateRange) {
        this.cls.onZoomSelection(range);
        this.reload();
    }

}
