import { formatDate } from "@angular/common";

export const MilisInMinute = 60 * 1000;
export const MilisInHour = 60 * MilisInMinute;
export const MilisInDay = 24 * MilisInHour;
export const MinutesInDay = 24 * 60;

const ShortMonthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

function tow(n: number) {
    return n < 10 ? ("0" + n) : n.toString();
}

function valueOrIfInArray(array: string[], index: number): string {

    if (index >= 0 && index < array.length) {
        return array[index];
    } else {
        return "";
    }
}

export function getShortMonthName(monthIndex: number): string {
    return valueOrIfInArray(ShortMonthNames, monthIndex);
}

/**
 *
 * @param monthIndex
 * @returns Index of short month name, or -1 if name is invalid
 */
export function getShortMonthNameIndex(monthName: string): number {
    return ShortMonthNames.indexOf(monthName);
}

export default class DateUtils {
    /** Returns name of the month for month index (0-11) */
    public static getMonthName(monthIndex: number): string {
        const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

        return valueOrIfInArray(months, monthIndex);
    }

    public static getDayName(dayIndex: number): string {
        const days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];

        if (dayIndex >= 0 && dayIndex < days.length) {
            return days[dayIndex];
        } else {
            return "";
        }
    }

    public static getShortDayName(dayIndex: number): string {
        const days = ["Sun", "Mon", "Tues", "Wed", "Thu", "Fri", "Sat"];

        if (dayIndex >= 0 && dayIndex < days.length) {
            return days[dayIndex];
        } else {
            return "";
        }
    }

    public static isSameDay(d1?: Date, d2?: Date): boolean {
        return !!d1
            && !!d2
            && d1.getUTCFullYear() === d2.getUTCFullYear()
            && d1.getUTCMonth() === d2.getUTCMonth()
            && d1.getUTCDate() === d2.getUTCDate();
    }

    public static formatTime(date: Date) {
        const hours = date.getUTCHours();
        const minutes = date.getUTCMinutes();

        return tow(hours)
            + ':'
            + tow(minutes);
    }

    /** Format example: Fri, 11 Mar 2022 */
    public static formatDateWithDayName(date: Date) {
        return this.getShortDayName(date.getDay())
            + ", "
            + date.getUTCDate()
            + " "
            + DateUtils.getMonthName(date.getUTCMonth())
            + " "
            + date.getUTCFullYear();
    }

    public static formatTimeWithSeconds(date: Date) {
        return tow(date.getUTCHours())
            + ":"
            + tow(date.getUTCMinutes())
            + ":"
            + tow(date.getSeconds());
    }

    /**
     *
     * Format example: Jun 20, 14:30
     */
    public static formatDateAndTime(date: Date) {
        return DateUtils.getMonthName(date.getUTCMonth())
            + " "
            + date.getUTCDate()
            + ", "
            + tow(date.getUTCHours())
            + ":"
            + tow(date.getUTCMinutes());
    }

    /** Format: 2021-09-22 19:00:00+00:00 */
    public static formatAsDataSource(date: Date) {
        return date.getUTCFullYear()
            + "-"
            + tow(date.getUTCMonth() + 1)
            + "-"
            + tow(date.getUTCDate())
            + " "
            + tow(date.getUTCHours())
            + ":"
            + tow(date.getUTCMinutes())
            + ":"
            + tow(date.getUTCSeconds())
            + "+00:00";
    }


}


export function dateEndOfDay(date: Date) {
    return newUtc(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), 23, 59, 59, 999);
}


export function dateNextDayStart(date: Date) {
    return newUtc(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate() + 1, 0, 0, 0, 0);
}

export function dateStartOfDay(date: Date) {
    return newUtc(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), 0, 0, 0, 0);
}

/** Returns true if date represents zero millisecond of the UTC day. */
export function dateIsStartOfDay(date: Date) {
    return date.getUTCHours() == 0 
        && date.getUTCMinutes() == 0 
        && date.getUTCSeconds() == 0 
        && date.getUTCMilliseconds() == 0;
}

export function dateIsToday(date: Date) {
    return DateUtils.isSameDay(date, new Date());
}

export function dateIsValid(date: Date) {
    return !isNaN(date.getTime());
}

export function newUtc(year: number, monthIndex: number, date?: number, hours?: number, minutes?: number, seconds?: number, ms?: number) {
    return new Date(Date.UTC.apply(Date, <any>arguments));
}

export function dateIsSameMonth(date1?: Date, date2?: Date) {
    if (!date1 || !date2) {
        return false;
    }
    
    return date1.getUTCMonth() === date2.getUTCMonth()
        && date1.getUTCFullYear() === date2.getUTCFullYear();
}
