import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';

@Component({
    selector: 'login-page',
    templateUrl: 'login-page.component.html',
    styleUrls: ['login-page.component.scss']
})
export class LoginPageComponent {

    constructor(public auth: AuthService, private router: Router) {
    }

    login(): void {
      console.log(this.router.url);
      this.auth.login({appState: {target: this.router.url}});
    }
}
