<hy-notifications-and-account *ngIf="showNotificationsAndAccount"></hy-notifications-and-account>

<div class="title" *ngIf="title">
    <a class="location" target="_blank" [href]="'https://www.google.com/maps?t=k&q=' + config.coordinates[0] + ',' + config.coordinates[1]">{{config.locationName}}</a>
    <div class="page">{{title}}</div>
</div>

<div class="update" *ngIf="service.liveModeSelected">
    <span class="last">{{service.selectedDate | date:'MMM d, HH:mm' : "+0000"}}</span>
    <span class="label">System Info Update: </span>
    <span class="next">{{service.nextUpdate | countdown | async }}</span>
</div>

<div class="select">
    <div *ngIf="_liveViewEnabled" class="live" [class.selected]="service.liveModeSelected"  (click)="selectLiveMode()">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12 19C7.58716 19 3.7512 16.1487 2.07213 12C3.7512 7.85127 7.58716 5 12 5C16.4128 5 20.2488 7.85128 21.9279 12C20.2488 16.1487 16.4128 19 12 19Z" stroke="#656C7C" stroke-width="2"/>
            <circle cx="12" cy="12" r="3" fill="#656C7C"/>
        </svg>

        <span>Live View</span>
    </div>
    <div class="date"
        [class.selected]="!service.liveModeSelected"
        [class.outlined]="dateRangeSelectorVisible"
        (click)="openDateRangeSelector()">
        <svg width="18" height="20" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6 9H4V11H6V9ZM10 9H8V11H10V9ZM14 9H12V11H14V9ZM16 2H15V0H13V2H5V0H3V2H2C0.89 2 0.00999999 2.9 0.00999999 4L0 18C0 19.1 0.89 20 2 20H16C17.1 20 18 19.1 18 18V4C18 2.9 17.1 2 16 2ZM16 18H2V7H16V18Z" fill="#788292"/>
        </svg>

        <span>{{activeDatesLabel}}</span>
    </div>
    <div *ngIf="env.enableScenarios" class="date selected" (click)="dateRangeSelectorVisible=false; scenarioSidebar.open()">
        <svg [innerHTML]="'dataflow-01' | fico"></svg>
        <span>{{config.selectedScenarioName}}</span>
    </div>
</div>

<hy-date-range-selector #dateRange
    *ngIf="dateRangeSelectorVisible"
    [selectedStart]="service.selectedStartDate"
    [selectedEnd]="service.selectedEndDate"
    [minDate]="service.minSelectableDate"
    [maxDate]="service.maxSelectableDate"
    [unavailableDates]="service.unavailableDates"
    (selectionApplyed)="updateSelectedDates($event)"
    (selectionCanceled)="cloaseDateRangeSelector()">
</hy-date-range-selector>
