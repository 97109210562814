import { PromiseValueMatchCache } from "../util/PromiseValueMatchCache";
import { BackendOpenApiService } from "./backend-openapi.service";
import { CommonDateFilter, ForecastsResponse, MetricsResponse, MonitoringResponse, NetworkChartsResponse } from "./backend.service";

export class CachedBackendService extends BackendOpenApiService  {

    networkCache = new PromiseValueMatchCache<NetworkChartsResponse>();
    forecastsCache = new PromiseValueMatchCache<ForecastsResponse>();
    metricsCache = new PromiseValueMatchCache<MetricsResponse>();
    monitoringSignalsCache = new PromiseValueMatchCache<MonitoringResponse>();

    public getNetworkCharts(filter: CommonDateFilter): Promise<NetworkChartsResponse> {
        return this.networkCache.fetch(
            this.commonFilterCacheIndex(filter),
            () => super.getNetworkCharts(filter)
        );
    }

    public getForecasts(filter: CommonDateFilter): Promise<ForecastsResponse> {
        return this.forecastsCache.fetch(
            this.commonFilterCacheIndex(filter),
            () => super.getForecasts(filter)
        );
    }

    public getMetrics(filter: CommonDateFilter): Promise<MetricsResponse> {
        return this.metricsCache.fetch(
            this.commonFilterCacheIndex(filter),
            () => super.getMetrics(filter)
        );
    }

    public getMonitoringSignals(filter: CommonDateFilter): Promise<MonitoringResponse> {
        return this.monitoringSignalsCache.fetch(
            this.commonFilterCacheIndex(filter),
            () => super.getMonitoringSignals(filter)
        );
    }

    public clearForecastsCache() {
        this.forecastsCache.clear();
    }
    
    public clearMetricsCache() {
        this.metricsCache.clear();
    }

    public clearMonitoringSignalsCache() {
        this.monitoringSignalsCache.clear();
    }

        
    private commonFilterCacheIndex(filter: CommonDateFilter): string {
        if (filter.timestamp) {
            return this.settingId + "-T" + filter.timestamp.getTime();
        } else {
            return this.settingId + "-S" + filter.start?.getTime() + "E" + filter.end?.getTime();
        }
    }
}

