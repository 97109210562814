import { Component } from '@angular/core';
import { FullSizeChartPageComponent } from './full-size-chart-page.component';
import { CommonDateFilter } from 'src/app/services/backend.service';

@Component({
  selector: 'hy-network-devices-chart-page',
  templateUrl: './full-size-chart-page.component.html',
  styleUrls: ['./full-size-chart-page.component.scss']
})
export class NetworkDevicesChartPageComponent extends FullSizeChartPageComponent {

    id = "network-devices-line";

    type: "blocks" | "line" = "line";

    metricTitle = "DEVICE";
    metricKey = "node_name"

    backRouterLink = "/network";

    protected loadChartDataImpl(filter: CommonDateFilter) {
        return this.backendService
        .getNetworkCharts(filter)
        .then(x => {
            if (!x) {
                return undefined;
            }

            this.showTimeInTooltips = (x.show_time !== false);
            
            return {
                data: x.devices,
                nextUpdateTime: x.next_update_time
            }
        });
    }

}
