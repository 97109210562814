import { MonitoringDevice, MonitoringSignal } from './backend.service';
import { SidebarBaseService } from './sidebar-base.service';

/** Service that controles the state of alert editor sidebar on monitoring page. */
export class MonitoringAlertEditorSidebarService extends SidebarBaseService {

    device?: MonitoringDevice;
    signal?: MonitoringSignal;

    public openForSignal(device: MonitoringDevice, signal: MonitoringSignal) {
        super.open();
        this.device = device;
        this.signal = signal;
    }
}