import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { NotificationFilterDevice } from 'src/app/services/backend.service';
import { SidebarBaseService } from 'src/app/services/sidebar-base.service';

import { ActivatedRoute, ActivatedRouteSnapshot, Router } from '@angular/router';
import dayjs from 'dayjs/esm';

const DateFilterFormat = "MMM D, YYYY";

/**
 * Filter sidebar for notifications page.
 * 
 * ----- Internal:
 * Component has double state variables. Active state and currently state.
 * When modifying data in the sidebar, current state is being changed. Clicking 
 * "Apply" button current state becomes active state. On the other hand, when 
 * "Cancel" button is clicked current state is dumped and active state is 
 * preserved.
 * 
 * 
 */
@Component({
  selector: 'notifications-filter-sidebar',
  templateUrl: './notifications-filter-sidebar.component.html',
  styleUrls: ['./notifications-filter-sidebar.component.scss'],
  host: {
      "[class.hy-sidebar-host]": "true",
      "[class.slide-out]": "service.closing"
  }
})
export class NotificationsFilterSidebarComponent implements OnChanges {
    @Input()
    public service!: SidebarBaseService;

    @Output()
    public dateState?: DateState;

    @Input()
    public devices?: NotificationFilterDevice[];

    @Output()
    public filterChange = new EventEmitter();

    cDateState?: DateState;

    @Output()
    devicesState: TrackedState = new TrackedState([]);

    @Output()
    typesState: TrackedState = new TrackedState([
        { name: "Information", id: "information", selected: true },
        { name: "Warning"    , id: "warning"    , selected: true },
        { name: "Error"      , id: "error"      , selected: true }
    ]);

    ranges: any = {
        'Today'       : [dayjs(), dayjs()],
        'Yesterday'   : [dayjs().subtract(1, 'days'), dayjs().subtract(1, 'days')],
        'Last 7 Days' : [dayjs().subtract(6, 'days'), dayjs()],
        'Last 30 Days': [dayjs().subtract(29, 'days'), dayjs()],
        'This Month'  : [dayjs().startOf('month'), dayjs().endOf('month')],
        'Last Month'  : [dayjs().subtract(1, 'month').startOf('month'), dayjs().subtract(1, 'month').endOf('month')],
    }

    private urlFilterApplyed = false;

    defaultDateRange = { startDate:  dayjs().subtract(6, 'days'), endDate: dayjs() }

    constructor(private route: ActivatedRoute) {
    }

    ngOnChanges(changes: SimpleChanges): void {
        const devicesChange = changes['devices'];
        if (devicesChange && devicesChange.currentValue) {
            // update device states from datasource
            const devices = <NotificationFilterDevice[]>devicesChange.currentValue;

            this.devicesState.setActive(
                devices.map(x => ({
                    id: x.node_id.toString(),
                    name: x.node_name,
                    selected: this.devicesState.isSelectedInActiveState(x.node_id.toString())
                }))
            );

            if (!this.urlFilterApplyed) {
                const query = this.route.snapshot.queryParams;

                let hasEffect = this.typesState.selectOnly(query.type);
                if (this.devicesState.selectOnly(query.device)) {
                    hasEffect = true;
                }

                this.urlFilterApplyed = true;

                if (hasEffect) {
                    this.filterChange.emit();
                }
            }
            
        }
    }

    cancel() {
        this.devicesState.discardCurrentState();
        this.typesState.discardCurrentState();
        this.cDateState = this.dateState;
        this.service.close();
    }

    get dateDisplayState() {
        return this.cDateState || this.defaultDateRange;
    }

    apply() {
        let hasChange = false;

        // Promote current state to active state
        if (this.devicesState.promoteCurrentState()) hasChange = true;
        if (this.typesState  .promoteCurrentState()) hasChange = true;


        if (this.hasDateFilterChanged()) {
            this.dateState = this.cDateState;
            hasChange = true;
        }

        if (hasChange) {
            this.filterChange.emit();
        }

        this.service.close();
    }

    hasDateFilterChanged() {
        return !(   
            this.cDateState?.startDate.isSame(this.dateState?.startDate, 'day')
            && this.cDateState?.endDate.isSame(this.dateState?.endDate, 'day')
        )
    }

    selectAllTime() {
        this.cDateState = undefined;
    }

    trackById(index: number, a: CheckState) {
        return a.id;
    }

    datesUpdated(event: any) {
        const startDate = <dayjs.Dayjs | undefined>event.startDate;
        const endDate = <dayjs.Dayjs | undefined>event.endDate;

        if (startDate?.isValid() && endDate?.isValid()) {
            this.cDateState = { startDate, endDate };
        }
    }

    public clearDateFilter() {
        this.dateState = undefined;
        this.cDateState = undefined;
        this.filterChange.emit();
    }

    /** Clears all filters. */
    public clearAll() {
        this.dateState = undefined;
        this.cDateState = undefined;

        this.devicesState.activeSelectAll();
        this.typesState.activeSelectAll();

        this.filterChange.emit();
    }

    public formatedActiveDateFilter() {
        if (this.dateState) {
            return this.dateState.startDate.format(DateFilterFormat)
                + " - "
                + this.dateState.endDate.format(DateFilterFormat)
        }

        return "";
    }

    public unselectDevice(state: CheckState) {
        this.devicesState.activeUnselect(state);
        this.filterChange.emit();
    }

    public unselectType(state: CheckState) {
        this.typesState.activeUnselect(state);
        this.filterChange.emit();
    }

}

interface DateState {
    startDate: dayjs.Dayjs;
    endDate: dayjs.Dayjs;
}

interface CheckState {
    id: string,
    name: string,
    selected: boolean
}

class TrackedState {
    public active: CheckState[];
    public current?: CheckState[];

    /** True if all items in active state are selected. */
    public allSelected = true;

    /** True if all items in current state are selected. */
    public _currentAllSelected = true;

    public set currentAllSelected(a: boolean) {
        this._currentAllSelected = a;
    }

    public get currentAllSelected() {
        return this._currentAllSelected;
    }

    constructor(initialState: CheckState[]) {
        this.active = initialState;
    }

    /**
     * UI is displaying the state as it is being modified, that is current 
     * state. If no modifications have been done yet, we show active state.
     */
    public get uiState() {
        return this.current || this.active;
    }

    /**
     * Returns state of "allSelected" variable depending on which state we are 
     * displaying.
     */
    public get uiAllSelected() {
        return this.current ? this.currentAllSelected : this.allSelected;
    }

    public setActive(active: CheckState[]) {
        this.active = active;
        this.allSelected = active.every(x => x.selected);
        this.current = undefined;
    }

    /** Unselect item in active state */
    public activeUnselect(state: CheckState) {
        state.selected = false;
    
        // if all items are unselected, we remove filtering by device such 
        // that we select all items
        const allUnselected = this.active.every(x => !x.selected);
        if (allUnselected) {
            // select all
            this.activeSelectAll();
        }
    }

    public activeSelectAll() {
        this.active.forEach(x => x.selected = true);
        this.allSelected = true;
    }

    public selectAll() {
        // if there is no current state object, we create it by duplicating the 
        // active state and we do the toggle on the current state.
        if (this.current) {
            this.current.forEach(x => x.selected = true);
            this.currentAllSelected = true;
        }
        else {
            const hasInactive = this.active.findIndex(x => x.selected) >= 0;

            if (hasInactive) {
                this.current = this.active.map(x => ({ 
                    id: x.id,
                    name: x.name,
                    selected: true
                }));
                this.currentAllSelected = true;
            }
            else {
                // nothing to do, all are selected in current state
            }
        }
    }

    public deselectAll() {
        this.current = this.active.map(x => ({ 
            id: x.id,
            name: x.name,
            selected: false
        }));
        this.currentAllSelected = false;
    }

    public toggleState(state: CheckState) {
        // if there is no current state object, we create it by duplicating the 
        // active state and we do the toggle on the current state.
        if (!this.current) {
            this.current = this.active.map(x => ({ 
                id: x.id, 
                name: x.name, 
                selected: x.id === state.id ? !x.selected : x.selected 
            }));
        }
        else {
            // current state exists, that means that state argument is form UI 
            // and is from current state
            state.selected = !state.selected;
        }

        this.currentAllSelected = this.current.every(x => x.selected);
    }

    public selectOnly(id: string) {
        const state = this.active.find(x => x.id == id);

        if (state) {
            this.active.forEach(x => x.selected = false);
            state.selected = true;
            this.allSelected = false;

            return true;
        } 
        else {
            return false
        }
    }

    /** 
     * Check if item is selected in active state. Returns true also if state 
     * is empty (not initialized yet). 
     * */
     public isSelectedInActiveState(deviceId: string): boolean {
        const deviceState = this.active.find(x => x.id === deviceId);

        return deviceState ? deviceState.selected : true;
    }

    /** Returns an array of selectd IDs in active state. */
    getSelectedIds() {
        if (this.allSelected) {
            return undefined;
        }
        return this.active
            .filter(x => x.selected)
            .map(x => x.id);
    }

    public discardCurrentState() {
        this.current = undefined;
    }

    /** 
     * Promotes current state to active state. Returns true if active state was 
     * modified.
     */
    public promoteCurrentState() {
        if (this.current) {
            this.active = this.current;
            this.allSelected = this.active.every(x => x.selected);

            this.current = undefined;
            
            return true;
        }
        else {
            return false;
        }
    }
}