/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';

import { NetworkGraphResponseSchema } from '../models/network-graph-response-schema';
import { NetworkResponseSchema } from '../models/network-response-schema';

@Injectable({ providedIn: 'root' })
export class NetworkService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getNetworkGraphSettingIdNetworkGraphGet()` */
  static readonly GetNetworkGraphSettingIdNetworkGraphGetPath = '/{setting_id}/network/graph';

  /**
   * Get Network Graph.
   *
   * Get the data about the different network components.
   * Needs a JWT authentication token and a timestamp.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getNetworkGraphSettingIdNetworkGraphGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  getNetworkGraphSettingIdNetworkGraphGet$Response(
    params: {
      setting_id: string;
      start_date?: (string | string);
      end_date?: (string | string);
      timestamp?: (string | string);
      load_next_available?: boolean;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<NetworkGraphResponseSchema>> {
    const rb = new RequestBuilder(this.rootUrl, NetworkService.GetNetworkGraphSettingIdNetworkGraphGetPath, 'get');
    if (params) {
      rb.path('setting_id', params.setting_id, {});
      rb.query('start_date', params.start_date, {});
      rb.query('end_date', params.end_date, {});
      rb.query('timestamp', params.timestamp, {});
      rb.query('load_next_available', params.load_next_available, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<NetworkGraphResponseSchema>;
      })
    );
  }

  /**
   * Get Network Graph.
   *
   * Get the data about the different network components.
   * Needs a JWT authentication token and a timestamp.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getNetworkGraphSettingIdNetworkGraphGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getNetworkGraphSettingIdNetworkGraphGet(
    params: {
      setting_id: string;
      start_date?: (string | string);
      end_date?: (string | string);
      timestamp?: (string | string);
      load_next_available?: boolean;
    },
    context?: HttpContext
  ): Observable<NetworkGraphResponseSchema> {
    return this.getNetworkGraphSettingIdNetworkGraphGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<NetworkGraphResponseSchema>): NetworkGraphResponseSchema => r.body)
    );
  }

  /** Path part for operation `getNetworkChartsSettingIdNetworkChartsGet()` */
  static readonly GetNetworkChartsSettingIdNetworkChartsGetPath = '/{setting_id}/network/charts';

  /**
   * Get Network Charts.
   *
   * Get the data about the different network components.
   * Needs a JWT authentication token and a timestamp.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getNetworkChartsSettingIdNetworkChartsGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  getNetworkChartsSettingIdNetworkChartsGet$Response(
    params: {
      setting_id: string;
      start_date?: (string | string);
      end_date?: (string | string);
      timestamp?: (string | string);
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<NetworkResponseSchema>> {
    const rb = new RequestBuilder(this.rootUrl, NetworkService.GetNetworkChartsSettingIdNetworkChartsGetPath, 'get');
    if (params) {
      rb.path('setting_id', params.setting_id, {});
      rb.query('start_date', params.start_date, {});
      rb.query('end_date', params.end_date, {});
      rb.query('timestamp', params.timestamp, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<NetworkResponseSchema>;
      })
    );
  }

  /**
   * Get Network Charts.
   *
   * Get the data about the different network components.
   * Needs a JWT authentication token and a timestamp.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getNetworkChartsSettingIdNetworkChartsGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getNetworkChartsSettingIdNetworkChartsGet(
    params: {
      setting_id: string;
      start_date?: (string | string);
      end_date?: (string | string);
      timestamp?: (string | string);
    },
    context?: HttpContext
  ): Observable<NetworkResponseSchema> {
    return this.getNetworkChartsSettingIdNetworkChartsGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<NetworkResponseSchema>): NetworkResponseSchema => r.body)
    );
  }

}
