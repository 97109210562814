import { Injectable } from '@angular/core';
import { EscKeyScopeService, EscKeySubscription } from './esc-key-scope.service';

/** Base service implementation with comon logic for sidebar control. */
@Injectable()
export class SidebarBaseService {

    /** Indicates if sidebar is opend or not. */
    public opened: boolean = false;

    /** Controles animation for closing the sidebar */
    public closing: boolean = false;

    /** Subscription to EscKeyScopeService scope */
    private escKeySubscription?: EscKeySubscription;

    constructor(private service: EscKeyScopeService) {
	}

    /** Opens the sidebar */
    open() {
        if (this.opened) {
            return;
        }
        this.opened = true;

        this.escKeySubscription = this.service.subscribe(() => this.close());
    }

    /** Closes the sidebar. */
    close() {
        if (!this.opened || this.closing) {
            return;
        }

        this.escKeySubscription?.unsubscribe();
        this.escKeySubscription = undefined;
        this.closing = true;


        setTimeout(() => {
            this.opened = false;
            this.closing = false;
        }, 250); 
    }

}