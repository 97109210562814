import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { AuthService as Auth0Service } from '@auth0/auth0-angular';
import {tap, switchMap} from 'rxjs/operators';
import {from} from 'rxjs';
import { HttpInterceptor, HttpHandler, HttpRequest} from '@angular/common/http';


@Injectable({ providedIn: 'root' })
export class AuthGuard  {
    constructor(
        private router: Router,
        private auth0: Auth0Service,
        private auth: AuthService
    ) {}

    canActivateChild() {
      return this.auth0.isAuthenticated$.pipe(
        tap((isAuthenticated) => {
          if (!isAuthenticated){
            this.auth.login({appState: {target: this.router.url}});
          }
        })
      );
    }
}

//     canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
//         if (this.auth.authorized) {
//             // authorised so return true
//             return true;
//         }
//         else {
//             // not logged in so redirect to login page with the return url
//             this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
//             return false;
//         }
//
//     }
