<hy-date-selection-header #header
    [title]="env.enableScenarios ? 'Custom Plots' : 'Forecasts'"
    (selectedDateChanged)="selectedDateChanged($event)"
    stickyClass="stuck"
    class="sticky">
</hy-date-selection-header>

<div class="stuck-placeholder"></div>

<div class="top-buttons">
    <div class="text">
        <ng-container *ngIf="data">
            <ng-container *ngIf="data.charts.length == 1">Now displaying 1 {{env.enableScenarios ? 'Custom Plot' : 'Forecast Plot'}}</ng-container>
            <ng-container *ngIf="data.charts.length > 1">Now displaying {{data.charts.length}} {{env.enableScenarios ? 'Custom Plots' : 'Forecast Plots'}}</ng-container>
        </ng-container>
    </div>
    <div *ngIf="numCharts > 0" class="hy-btn dark add" (click)="editor.openForNew(data!.available_forecasts)"><span class="ico-24"></span>Add {{env.enableScenarios ? 'Custom Plot' : 'Forecast Plot'}}</div>
</div>

<!-- Show hy-request-info only if we have no charts loaded. If charts are 
     loaded, they will show status messages -->
<hy-request-info
    *ngIf="loadingStatus !== 'loaded' && numCharts === 0" 
    class="hy-fadein"
    [type]="loadingStatus"
    (tryAgain)="reload()">
</hy-request-info>

<hy-featured-message 
        *ngIf="loadingStatus === 'loaded' && numCharts === 0"
        title="No {{env.enableScenarios ? 'Custom Plots' : 'Forecast Plots'}}" 
        icon="alert-triangle" 
        buttonLabel="ADD {{env.enableScenarios ? 'CUSTOM PLOT' : 'FORECAST PLOT'}}" 
        (buttonClick)="editor.openForNew(data!.available_forecasts)">
    There are no {{env.enableScenarios ? 'Custom Plots' : 'Forecast Plots'}} created. Start creating by adding a first plot.
</hy-featured-message>

<div class="charts" [nxtSortablejs]="data?.charts" [config]="dragOptions">
    <hy-line-chart-block 
        *ngFor="let chart of data?.charts; index as i; trackBy:trackByChartId"
        id="forecast-{{chart.chart_id}}"
        [title]="chart?.chart_title"
        [zoomData]="$any(zoomChartData?.charts?.[i]?.forecasts) || []" 
        [data]="$any(chart?.forecasts) || []" 
        [live]="dateSelectionService.liveModeSelected"
        [nowDate]="dateSelectionService.liveModeSelected ? dateSelectionService.selectedDate : undefined"
        [fullRangeStart]="cls.fullRangeStart"
        [fullRangeEnd]="cls.fullRangeEnd"
        [zoomStart]="cls.zoomStartDate"
        [zoomEnd]="cls.zoomEndDate"
        [loadingStatus]="loadingStatus"
        [showTimeInTooltips]="showTimeInTooltips"
        metricTitle="TIME SERIES"
        metricKey="forecast_name" 
        expandRouterLink="/{{env.enableScenarios ? 'custom-plots' : 'forecasts'}}/chart/{{chart.chart_id}}"
        [minLegendRows]="minLegendRows"
        [showEditBtn]="true"
        [showMoveBtn]="true"
        (edit)="editor.openForChart(chart, data!.available_forecasts)"
        (zoomSelection)="onZoomSelection($event)">
    </hy-line-chart-block>
</div>


<forecast-chart-editor-sidebar #editor (updated)="reload()"></forecast-chart-editor-sidebar>