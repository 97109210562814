import { Component, ElementRef, ViewChild } from '@angular/core';
import { DoubleFieldInputService } from 'src/app/services/double-field-input.service';
import { NotificationsSidebarService } from 'src/app/services/notifications-sidebar.service';
import { SettingsSidebarService } from 'src/app/services/settings-sidebar.service';
import { ToastMessageService } from 'src/app/services/toast-message.service';

@Component({
  selector: 'hy-app-layout',
  templateUrl: './app-layout.component.html',
  styleUrls: ['./app-layout.component.scss']
})
export class AppLayoutComponent {

    @ViewChild("contentEl", { static: true })
    contentEl!: ElementRef<HTMLDivElement>;


    constructor(
        public toast: ToastMessageService,
        public settingsSidebarService: SettingsSidebarService,
        public notificationsSidebarService: NotificationsSidebarService,
        public inputService: DoubleFieldInputService) { }

    dismissToast(toast: string) {
        this.toast.dismiss(toast);
    }

    onRouteActivate() {
        this.contentEl.nativeElement.scrollTop = 0;
    }

}
 