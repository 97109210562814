import { Component, HostBinding, HostListener, Input } from '@angular/core';
import { EscKeyScopeService, EscKeySubscription } from 'src/app/services/esc-key-scope.service';

@Component({
  selector: 'hy-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss']
})
export class DialogComponent {


    @HostBinding('class.visible')
    _visible: boolean = false;

    private escSubscription?: EscKeySubscription;

    constructor(private escKeyService: EscKeyScopeService) {
    }
    

    @Input()
    public set visible(visible: boolean) {
        if (visible == this._visible) {
            return;
        }

        if (visible) {
            this.escSubscription = this.escKeyService.subscribe(() => this.visible = false );
        } else {
            this.escSubscription?.unsubscribe();
        }
        
        this._visible = visible;
    }

    public get visible() {
        return this._visible;
    }

    @HostListener("click")
    public close() {
        this.visible = false;
    }

    public show() {
        this.visible = true;
    }
}
