

export enum AlertOperator {
    LowerThen = "<",
    HigherThen = ">",
    EqualsThen = "=",
    OutsideRange = "outside range"
}

export interface AlertRule {
    operator: AlertOperator;
    value_1: number;
    value_2: number;
}