import { Component, HostListener } from '@angular/core';
import { DoubleFieldInputService } from 'src/app/services/double-field-input.service';

@Component({
  selector: 'hy-double-field-input-dialog',
  templateUrl: './double-field-input-dialog.component.html',
  styleUrls: ['./double-field-input-dialog.component.scss']
})
export class DoubleFieldInputDialogComponent {

    error?: string;

    constructor(public inputService: DoubleFieldInputService) { }

    
    @HostListener('click')
    cancel() {
        this.inputService.closeDialog();
    }

    done(v1: string, v2: string) {
        this.inputService.doneDelegate(v1, v2)
            .then(close => {
                if (close) {
                    this.inputService.closeDialog();
                }
            })
            .catch(error => {
                if ("message" in error) {
                    this.error = error.message;
                }
            });
    }

}
