import { Injectable } from '@angular/core';
import { UpdateableFetcher } from '../util/UpdateableFetcher';

import { BackendService, Notification, NotificationsResponse, NotificationType } from './backend.service';
import { ConfigurationService } from './configuration.service';
import { environment } from 'src/environments/environment';

/**
 * Notification service manages state of the main Notification icon. Service
 * reloads notifications based on `next_update_time` from notifications response.
 * I will also triger audio alert when new notification is loaded.
 */
@Injectable({
    providedIn: 'root',
})
export class NotificationsService {

    public notifications: Notification[] = [];

    /**
     * If we have new notifications this property contains severity of new
     * notifications. If there is at least one error this will be error,
     * otherwise if there is at least one warning, otherwise if will be
     * information. If there are no new notifications this will be undefined.  */
    public newSeverity?: NotificationType;

    /** Defines if blink animation should be active. Animation is activated when
     *  new notification is detected. */
    public blinkNotification = false;

    public updateableFetcher?: UpdateableFetcher<NotificationsResponse>;

    constructor(
            private backend: BackendService,
            private config: ConfigurationService) {

        if (!environment.disableNotifications) {
            this.updateableFetcher = new UpdateableFetcher(
                () => this.loadNotifications()
            )
        }
    }

    private loadNotifications() {
        return this.backend.getNotifications(0)
            .then(x => {
                this.notifications = x.notifications;

                const firstNew = this.findFirstNew(x.notifications);
                this.newSeverity = this.findNewSeverity(this.notifications);

                // If we received a new notification we need to trigger an audio
                // alter and start blink notification
                if (firstNew !== undefined && firstNew.notification_id !== this.config.lastAudioAlertNotificationId) {
                    this.blinkNotification = true;
                    // blinkNotification=true applyeas a class to icon which
                    // triggers a blink animation, we remove the class after 5s
                    setTimeout(() => this.blinkNotification = false, 5000);

                    this.config.lastAudioAlertNotificationId = firstNew.notification_id;

                    if (this.config.audioAlertEnabled) {
                        var audio = new Audio('assets/h2go_notif_sound.mp3');
                        audio.play();
                    }
                }

                return x;
            });
    }

    private findFirstNew(notifications: Notification[]) {
        return notifications.find(n => n.is_new);
    }

    private findNewSeverity(notifications: Notification[]) {
        const hasError = notifications.find(x => x.is_new && x.type == NotificationType.Error);
        if (hasError) {
            return NotificationType.Error;
        }

        const hasWarning = notifications.find(x => x.is_new && x.type == NotificationType.Warning);
        if (hasError) {
            return NotificationType.Error;
        }

        const hasNew = notifications.find(x => x.is_new);

        return hasNew ? NotificationType.Information : undefined;
    }

    public markNewAsRead(notifications: Notification[]) {
        const newIds = notifications.filter(x => x.is_new).map(x => x.notification_id);

        return this.backend.markNotificationsAsRead(newIds);
    }

}
