import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './guards/auth.guard';
import { AppLayoutComponent } from './layout/app-layout/app-layout.component';
import { LoginLayoutComponent } from './layout/login-layout/login-layout.component';
import { AboutUsComponent } from './pages/about-us/about-us.component';
import { NetworkGraphConfigPageComponent } from './pages/dev/network-graph-config-page/network-graph-config-page.component';
import { ForecastsPageComponent } from './pages/forecasts-page/forecasts-page.component';
import { ForgotPasswordPageComponent } from './pages/forgot-password-page/forgot-password-page.component';
import { ForgotPasswordSentPageComponent } from './pages/forgot-password-sent-page/forgot-password-sent-page.component';
import { ForecastsChartPageComponent } from './pages/full-size-chart-page/forecasts-chart-page.component';
import { MetricsHydrogenChartPageComponent } from './pages/full-size-chart-page/metrics-hydrogen-chart-page.component';
import { MetricsProfitChartPageComponent } from './pages/full-size-chart-page/metrics-profit-chart-page.component';
import { MonitoringChartPageComponent } from './pages/full-size-chart-page/monitoring-chart-page.component';
import { NetworkDevicesChartPageComponent } from './pages/full-size-chart-page/network-devices-chart-page.component';
import { NetworkStorageChartPageComponent } from './pages/full-size-chart-page/network-storage-chart-page.component';
import { LoginPageComponent } from './pages/login-page/login-page.component';
import { MetricsPageComponent } from './pages/metrics-page/metrics-page.component';
import { MonitoringPageComponent } from './pages/monitoring-page/monitoring-page.component';
import { NetworkStatusPageComponent } from './pages/network-status-page/network-status-page.component';
import { NotificationsPageComponent } from './pages/notifications-page/notifications-page.component';
import { ResetPasswordDonePageComponent } from './pages/reset-password-done-page/reset-password-done-page.component';
import { ResetPasswordPageComponent } from './pages/reset-password-page/reset-password-page.component';


const routes: Routes = [
    {path: '', component: AppLayoutComponent, canActivateChild: [AuthGuard],
      children: [
        { path: '',  pathMatch: 'full', redirectTo: '/network' },
        { path: 'network'                    , component: NetworkStatusPageComponent                 , data: { title: 'Network Status' }},
        { path: 'network/storage'            , component: NetworkStorageChartPageComponent           , data: { title: 'Storage Chart'  }},
        { path: 'network/devices'            , component: NetworkDevicesChartPageComponent           , data: { title: 'Devices Chart'  }},

        { path: 'forecasts'                  , component: ForecastsPageComponent                     , data: { title: 'Forecasts'      }},
        { path: 'custom-plots'               , component: ForecastsPageComponent                     , data: { title: 'Custom Plots'   }},
        { path: 'forecasts/chart/:chartId'   , component: ForecastsChartPageComponent                , data: { title: 'Forecasts Chart'}},
        { path: 'custom-plots/chart/:chartId', component: ForecastsChartPageComponent                , data: { title: 'Custom plot'}},

        { path: 'metrics'                    , component: MetricsPageComponent                       , data: { title: 'Metrics'        }},
        { path: 'metrics/profit'             , component: MetricsProfitChartPageComponent            , data: { title: 'Profit Chart'   }},
        { path: 'metrics/hydrogen'           , component: MetricsHydrogenChartPageComponent          , data: { title: 'Hydrogen Chart' }},


        { path: 'notifications'              , component: NotificationsPageComponent                 , data: { title: 'Notifications'  }},

        { path: 'monitoring'                 , component: MonitoringPageComponent                    , data: { title: 'Monitoring'     }},
        { path: 'monitoring/chart/:nodeId/:signalId', component: MonitoringChartPageComponent        , data: { title: 'Monitoring'     }},

        { path: 'about-us'                   , component: AboutUsComponent                           , data: { title: 'About Us'       }},


    ]},
    {path: '', component: LoginLayoutComponent,
      children: [
        { path: 'login'               , component: LoginPageComponent             , data: { title: 'Login'           }},
        { path: 'forgot-password'     , component: ForgotPasswordPageComponent    , data: { title: 'Forgot Password' }},
        { path: 'forgot-password-sent', component: ForgotPasswordSentPageComponent, data: { title: 'Forgot Password' }},
        { path: 'reset-password'      , component: ResetPasswordPageComponent     , data: { title: 'Reset Password'  }},
        { path: 'reset-password-done' , component: ResetPasswordDonePageComponent , data: { title: 'Reset Password'  }}
      ]
    },
    { path: 'dev/network-graph-config', component: NetworkGraphConfigPageComponent, data: { title: 'Network Graph Config'}}
  ];

@NgModule({
  imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
