<div *ngIf="label" class="hy-e-title">{{label}}</div>
<div class="hy-input-e" [class.disabled]="disabled">
    <input
        [type]="type" 
        [placeholder]="placeholder || ''" 
        [(ngModel)]="value" 
        [disabled]="disabled"
        (ngModelChange)="_onChange($event)" 
        (blur)="_onTouched()"
        [autocomplete]="autocomplete">
</div>
<div *ngIf="hint" class="hy-e-hint">{{hint}}</div>
