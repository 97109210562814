import { Component, Input } from '@angular/core';
import { ConfigurationService } from 'src/app/services/configuration.service';

@Component({
  selector: 'simple-page-header',
  templateUrl: './simple-page-header.component.html',
  styleUrls: ['./simple-page-header.component.scss']
})
export class SimplePageHeaderComponent {

    @Input()
    public title: string = "";

    constructor(public config: ConfigurationService) {
    }
}
