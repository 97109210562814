<div *ngIf="!env.disableNotifications" 
    [class]="notificationsService.newSeverity"
        [class.notifications]="true"  
        [class.new]="notificationsService.newSeverity"  
        [class.blink-anim]="notificationsService.blinkNotification"
        (click)="notificationsSidebar.open()">
        
    <svg width="36" height="36" viewBox="0 0 36 36" fill="none">
        <path d="M11.3983 24.4802H24.6031C25.2193 24.4797 25.8216 24.2966 26.3338 23.954C26.846 23.6114 27.2452 23.1247 27.4809 22.5553C27.7167 21.986 27.7784 21.3595 27.6583 20.7551C27.5383 20.1507 27.2417 19.5954 26.8063 19.1594L24.8407 17.1938V14.0402C24.8407 12.2261 24.12 10.4863 22.8373 9.20359C21.5545 7.92084 19.8147 7.2002 18.0007 7.2002C16.1866 7.2002 14.4468 7.92084 13.164 9.20359C11.8813 10.4863 11.1607 12.2261 11.1607 14.0402V17.1938L9.19505 19.1594C8.75956 19.5954 8.46305 20.1507 8.34297 20.7551C8.22289 21.3595 8.28462 21.986 8.52037 22.5553C8.75613 23.1247 9.15533 23.6114 9.66754 23.954C10.1798 24.2966 10.782 24.4797 11.3983 24.4802ZM10.7215 20.6858L13.3207 18.0866V14.0402C13.3207 12.799 13.8137 11.6086 14.6914 10.7309C15.5691 9.85327 16.7594 9.3602 18.0007 9.3602C19.2419 9.3602 20.4322 9.85327 21.3099 10.7309C22.1876 11.6086 22.6807 12.799 22.6807 14.0402V18.0866L25.2799 20.6858C25.4136 20.8197 25.5047 20.9903 25.5416 21.176C25.5785 21.3616 25.5596 21.5541 25.4872 21.729C25.4147 21.9039 25.2921 22.0534 25.1347 22.1586C24.9774 22.2638 24.7924 22.3201 24.6031 22.3202H11.3983C11.209 22.3201 11.0239 22.2638 10.8666 22.1586C10.7092 22.0534 10.5866 21.9039 10.5142 21.729C10.4417 21.5541 10.4228 21.3616 10.4597 21.176C10.4966 20.9903 10.5877 20.8197 10.7215 20.6858Z" fill="#CFDFF6"/>
        <path d="M20.8811 26.6401H15.1211V28.8001H20.8811V26.6401Z" fill="#CFDFF6"/>
        <circle cx="29" cy="6" r="3" fill="#EBFF00"/>
    </svg>
</div>

<div class="account" (click)="settingsSidebarService.open()">
    <span class="name">{{config.userName}}</span>
</div>