import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RequestInfoComponent } from './request-info/request-info.component';
import { FicoPipe } from './pipes/fico.pipe';
import { LottieModule } from 'ngx-lottie';
import { InputGroupComponent } from './input-group/input-group.component';
import { FormsModule } from '@angular/forms';
import { FormErrorsComponent } from './form-errors/form-errors.component';
import { ErrorMsgDirective } from './form-errors/error-msg.directive';
import { DialogComponent } from './dialog/dialog.component';
import { SafeHtmlPipe } from './pipes/safe-html.pipe';
import { FeaturedMessageComponent } from './featured-message/featured-message.component';



@NgModule({
  declarations: [
    FicoPipe,
    SafeHtmlPipe,
    RequestInfoComponent,
    InputGroupComponent,
    FormErrorsComponent,
    ErrorMsgDirective,
    DialogComponent,
    FeaturedMessageComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    LottieModule
  ],
  exports: [
    FicoPipe,
    SafeHtmlPipe,
    RequestInfoComponent,
    InputGroupComponent,
    FormErrorsComponent,
    ErrorMsgDirective,
    DialogComponent,
    FeaturedMessageComponent
  ]
})
export class SharedModule { }
