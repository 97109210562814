import DateUtils, { dateIsValid, dateStartOfDay, newUtc } from "src/app/util/DateUtils";
import { DateRangeSelectorComponent } from "./date-range-selector.component";
import { PresetDate } from "src/api/models";

export interface DateRangeOption {
    get name(): string;
    get isSelected(): boolean;
    
    select(comp: DateRangeSelectorComponent) : void;
    updateIsSelected(comp: DateRangeSelectorComponent) : void;
    containedInAvailableRange(comp: DateRangeSelectorComponent) : boolean;
    clearSelected(): void;
}

interface StartAndEnd {
    start: Date;
    end: Date;
}


abstract class BaseDateRangeOption implements DateRangeOption {
    public isSelected = false;

    constructor(public name: string) {
    }

    protected abstract calcStartAndEnd(comp: DateRangeSelectorComponent) : StartAndEnd | undefined;

    select(comp: DateRangeSelectorComponent): void {
        const dates = this.calcStartAndEnd(comp);

        if (!dates) {
            return;
        }
        
        comp.selectedStart = dates.start;
        comp.selectedEnd = dates.end;
        this.isSelected = true;
    }

    updateIsSelected(comp: DateRangeSelectorComponent): void {
        const dates = this.calcStartAndEnd(comp);

        if (!dates) {
            return;
        }

        this.isSelected = DateUtils.isSameDay(dates.start, comp.selectedStart)
                       && DateUtils.isSameDay(dates.end  , comp.selectedEnd);
    }

    clearSelected(): void {
        this.isSelected = false;
    }

    public containedInAvailableRange(comp: DateRangeSelectorComponent) : boolean {
        const dates = this.calcStartAndEnd(comp);

        if (!dates) {
            return false;
        }

        const avStart = comp.dateSelectionService.minSelectableDate;
        const avEnd = comp.dateSelectionService.minSelectableDate;

        if (!avStart || !avEnd) {
            return true;
        }

        return (dates.start.getTime() >= avStart.getTime() && dates.start.getTime() <= avEnd.getTime())
            || (dates.end.getTime()  >= avStart.getTime() && avEnd.getTime() <= avEnd.getTime());
        
    }
}

export class LastDaysDateRangeOption extends BaseDateRangeOption {

    constructor(name: string, public daysToSelect: number) {
        super(name);
    }

    override calcStartAndEnd(comp: DateRangeSelectorComponent) {
        const now = new Date();

        return {
            start: newUtc(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate() - this.daysToSelect + 1),
            end  : newUtc(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate())
        };
    }
}

export class ThisYearRangeOption extends BaseDateRangeOption {

    constructor() {
        super("This year (Jan - Today)");
    }

    override calcStartAndEnd(comp: DateRangeSelectorComponent) {
        const now = new Date();

        return {
            start: newUtc(now.getUTCFullYear(), 0, 1),
            end  : newUtc(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate())
        };
    }
}

export class LastCalendarYearRangeOption extends BaseDateRangeOption {

    constructor() {
        super("Last calendar year");
    }

    override calcStartAndEnd(comp: DateRangeSelectorComponent) {
        const now = new Date();

        return {
            start: newUtc(now.getUTCFullYear() - 1, 0, 1),
            end  : newUtc(now.getUTCFullYear() - 1, 11, 31)
        };
    }
}

export class CompleteAvailableRangeOption extends BaseDateRangeOption {

    constructor() {
        super("Complete available range");
    }

    override calcStartAndEnd(comp: DateRangeSelectorComponent) {
        const minDate = comp.dateSelectionService.minSelectableDate;
        const maxDate = comp.dateSelectionService.maxSelectableDate ?? new Date();

        if (!minDate) {
            console.error("Date range minDate not defined.")
            return;
        }

        return {
            start: newUtc(minDate.getUTCFullYear(), minDate.getUTCMonth(), minDate.getUTCDate()),
            end  : newUtc(maxDate.getUTCFullYear(), maxDate.getUTCMonth(), maxDate.getUTCDate())
        };
    }
}
export class FixedDatesRangeOption extends BaseDateRangeOption {

    constructor(name: string, private start: Date, private end: Date) {
        super(name);
    }

    override calcStartAndEnd(comp: DateRangeSelectorComponent) {
        return { 
            start: this.start, 
            end: this.end 
        };
    }

    static createFromPreset(preset: PresetDate): FixedDatesRangeOption | undefined {
        let start = new Date(preset.start);
        let end = new Date(preset.end);

        if (!dateIsValid(start) || !dateIsValid(end)) {
            console.error("Invalid preset date, cannot parse dates.", preset);
            return;
        }

        start = dateStartOfDay(start);
        end = dateStartOfDay(end);

        return new FixedDatesRangeOption(preset.name, start, end);
    }
}


export function createRangesFromPresetDates(presetDates: PresetDate[]): DateRangeOption[] {
    return presetDates
        .map(preset => FixedDatesRangeOption.createFromPreset(preset))
        .filter((item): item is FixedDatesRangeOption => !!item);
}