import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { DateRange, TimeSliderComponent } from 'src/app/layout/time-slider/time-slider.component';
import { LoadingStatus } from 'src/app/modules/shared/loading-status';
import { BackendService, NetworkGraphResponse, UnavailableDataRangesEntry } from 'src/app/services/backend.service';
import { ConfigurationService } from 'src/app/services/configuration.service';
import { DateSelectionHeaderService } from 'src/app/services/date-selection-header.service';
import { ToastMessageService } from 'src/app/services/toast-message.service';
import { ChartLoadingSupport } from 'src/app/util/ChartLoadingSupport';
import { MilisInMinute, dateIsValid } from 'src/app/util/DateUtils';
import { environment } from 'src/environments/environment';


@Component({
    selector: 'network-status-page',
    templateUrl: 'network-status-page.component.html',
    styleUrls: ['network-status-page.component.scss']
})
export class NetworkStatusPageComponent implements OnInit {

    @ViewChild(TimeSliderComponent)
    graphSlider?: TimeSliderComponent;
    
    env = environment;

    graphDataStatus: LoadingStatus = LoadingStatus.Loading;
    chartsLoadingStatus: LoadingStatus = LoadingStatus.Loading;
    
    zoomChartData?: any;
    chartsData?: any;
    graphData?: NetworkGraphResponse;

    graphImageUrl?: string;
    graphImage?: string;

    graphSelectedDate: Date;

    public unavailableDateRanges?: DateRange[];

    showTimeInTooltips = true;

    cls: ChartLoadingSupport;

    constructor(
            private backendService: BackendService, 
            private toast: ToastMessageService, 
            private configurationServce: ConfigurationService,
            private http: HttpClient,
            public dateSelectionService: DateSelectionHeaderService) {

        this.graphSelectedDate = dateSelectionService.selectedDate;
        this.cls = new ChartLoadingSupport(this.dateSelectionService);
    }

    ngOnInit() {
        this.loadChartsData();
        this.loadGraphData(true);
    }

    async loadChartsData() {
        this.chartsLoadingStatus = LoadingStatus.Loading;
        
        await this.backendService.getNetworkCharts(this.cls.getFilter())
            .then(data => {
                if (data) {
                    // we only update zoom chart and availability when we re loading full data range
                    if (!this.zoomChartData) {
                        this.zoomChartData = data;
                    }
                    if (!this.unavailableDateRanges) {
                        this.unavailableDateRanges = parseUnavailableDateRanges(
                            data.unavailable_data_ranges,
                            this.configurationServce.granularity
                        );
                    }
                    this.chartsLoadingStatus = LoadingStatus.Loaded;
                    this.chartsData = data;
                    this.showTimeInTooltips = (data.show_time !== false);
                    this.dateSelectionService.setNextUpdateTime(new Date(data.next_update_time));

                }
                else {
                    this.chartsLoadingStatus = LoadingStatus.NoData;
                    this.chartsData = undefined;
                }
            })
            .catch(() => this.chartsLoadingStatus = LoadingStatus.Failed);
    }

    async loadGraphData(loadNextAvailable: boolean) {
        this.graphDataStatus = LoadingStatus.Loading;
        await this.backendService.getNetworkGraph(this.graphSelectedDate, loadNextAvailable)
            .then(data => {
                if (data) {
                    this.graphData = data;

                    if (data.graph_image_url != this.graphImageUrl) {
                        this.graphImageUrl = data.graph_image_url;
                        this.graphImage = undefined;
                    }
                    

                    if (this.graphSlider && data.data_timestamp) {
                        const dataTimestamp = new Date(data.data_timestamp);
                        
                        if (dateIsValid(dataTimestamp)) {
                            this.graphSelectedDate = dataTimestamp;
                            this.graphSlider.selectDate(dataTimestamp, false);
                        }
                    }

                    return this.loadGraphImage();
                }
                else {
                    this.graphDataStatus = LoadingStatus.NoData;
                    this.graphData = undefined;

                    return;
                }
            })
            .catch(() => this.graphDataStatus = LoadingStatus.Failed);
    }

    private loadGraphImage() {
        if (!this.graphImageUrl) {
            console.error("Graph image not defined.");
            return;
        }

        return firstValueFrom(this.http
            .get(this.graphImageUrl, { responseType: 'text'}))
            .then(resp => {
                this.graphDataStatus = LoadingStatus.Loaded;
                this.graphImage = resp;
            });
    }

    selectedDateChanged(date: any) {
        this.graphSelectedDate = date;

        this.cls.selectedDateChanged();
        this.zoomChartData = undefined;
        this.unavailableDateRanges = undefined;

        this.loadChartsData();
        // we need to load next available date instead of selected data when 
        // selecting range in historical overview so that initially user gets  
        // some data displayed. In other cases we show no-data message if there 
        // is no data in database
        this.loadGraphData(!this.dateSelectionService.liveModeSelected);
    }

    onZoomSelection(range: DateRange) {
        this.cls.onZoomSelection(range);
        this.loadChartsData();
    }

    onGraphSliderChange(date: Date) {
        this.graphSelectedDate = date;
        this.loadGraphData(false);
    }
    
    onChartClick(date: Date) {
        if (!this.graphSlider) {
            return;
        }

        this.graphSlider.selectDate(date);
    }

}


function parseUnavailableDateRanges(entries: UnavailableDataRangesEntry[], granularity: number) {
    return entries
        .map(x => ({
            start: new Date(new Date(x.start).getTime() - granularity * MilisInMinute),
            end: new Date(x.end)
        }))
        .filter(x => dateIsValid(x.start) && dateIsValid(x.end));
}
