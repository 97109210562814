import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'hy-reset-password-done-page',
  templateUrl: './reset-password-done-page.component.html',
  styleUrls: ['./reset-password-done-page.component.scss']
})
export class ResetPasswordDonePageComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
