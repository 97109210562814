import { Component, ContentChild, HostListener, Input, TemplateRef } from '@angular/core';
import { SidebarBaseService } from 'src/app/services/sidebar-base.service';


@Component({
    selector: 'right-sidebar',
    templateUrl: 'right-sidebar.component.html',
    styleUrls: ['right-sidebar.component.scss'],
    host: {
        "[class.slide-out]": "service.closing"
    }
})
export class RightSidebarComponent {
    @Input()
    service?: SidebarBaseService;

    @Input()
    title?: string;

    /** 
     * Template that is position at the bottom of the sidebar. It is has fixed 
     * position no metter content size. 
     * */
    @ContentChild("footer") 
    footer!: TemplateRef<unknown>;

    /** 
     * Buttons templet is position beneath the footer and is expected to contain
     * .hy-btn buttons, which are most common footer content of the sidebar. 
     * */
    @ContentChild("buttons") 
    buttons!: TemplateRef<unknown>;

    @HostListener("click")
    close() {
        this.service?.close();
    }
}
