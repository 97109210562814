<div class="availability">
    <div *ngFor="let range of availabilityRanges"
            class="range"
            [class.available]="range.available" 
            [style.flex]="range.size">
    </div>
</div>

<div class="ticks" 
        (click)="onTicksClick($event)"
        (mousemove)="onTicksMousemove($event)"
        (mouseout)="onTicksMouseout($event)">
    <div *ngFor="let tick of ticks; index as i" 
            class="tick"
            [class.selected]="tick.selected" 
            [style.flex]="tick.size">
        <span class="label" (click)="focusToTick(tick, $event)">{{tick.label}}</span>
    </div>
</div>

<div class="handle" 
        [style.left.%]="handleOffsetPercent"
        [class.dragged]="isBeingDragged"
        [attr.data-tooltip]="selectedDate | date: 'ccc, MMM dd HH:mm' : '+0000'"
        (mousedown)="onMousedown($event)"
        (touchstart)="onTouchstart($event)"
        (click)="$event.stopPropagation()"></div>

<div *ngIf="ghostHandleOffsetPercent"
        class="handle ghost" 
        [style.left.%]="ghostHandleOffsetPercent"
        [attr.data-tooltip]="ghostHandleLabel">
</div>