<right-sidebar *ngIf="service.opened" [service]="service" title="Filters">

    <div class="filter-group">
        <div class="group-title">
            <div>TIME</div>
        </div>

        <div class="date-parent">
            <div class="date-all" (click)="selectAllTime()" [class.active]="!this.cDateState">
                All time
            </div>
            <div class="date">
                <input #dateFilter type="text" class="date-input" ngxDaterangepickerMd 
                    [class.active]="this.cDateState"
                    [showCustomRangeLabel]="true"
                    [alwaysShowCalendars]="true"
                    [showCancel]="true"
                    [ranges]="ranges"
                    [opens]="'left'"
                    [locale]="({displayFormat:'MMM D, YYYY'})" 
                    readonly
                    [ngModel]="dateDisplayState"
                    (datesUpdated)="datesUpdated($event)" />
            </div>
        </div>
    </div>

    <div class="filter-group">
        <div class="group-title">
            <div>DEVICE</div>
            <div *ngIf="!devicesState.uiAllSelected" (click)="devicesState.selectAll()">SELECT ALL</div>
            <div *ngIf="devicesState.uiAllSelected"  (click)="devicesState.deselectAll()">DESELECT ALL</div>
        </div>
        <div *ngFor="let device of devicesState.uiState; let index = index; trackBy: trackById" 
            [class.active]="device.selected" 
            class="hy-check-row"
            (click)="devicesState.toggleState(device)">
            <div class="hy-check"></div>
            {{device.name}}
        </div>
    </div>
    <div class="filter-group">
        <div class="group-title">
            <div>TYPE</div>
            <div *ngIf="!typesState.uiAllSelected" (click)="typesState.selectAll()">SELECT ALL</div>
            <div *ngIf="typesState.uiAllSelected"  (click)="typesState.deselectAll()">DESELECT ALL</div>
        </div>
        <div *ngFor="let type of typesState.uiState; let index = index; trackBy: trackById" 
            [class.active]="type.selected" 
            class="hy-check-row"
            (click)="typesState.toggleState(type)">
            <div class="hy-check"></div>
            {{type.name}}
        </div>
    </div>
    <ng-template #buttons>
        <div class="hy-btn secondary" (click)="cancel()">CANCEL</div>
        <div class="hy-btn"           (click)="apply()">APPLY FILTERS</div>
    </ng-template>
</right-sidebar>