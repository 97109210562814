import { Component } from '@angular/core';
import { ConfigurationService } from 'src/app/services/configuration.service';
import { NotificationsSidebarService } from 'src/app/services/notifications-sidebar.service';
import { NotificationsService } from 'src/app/services/notifications.service';
import { SettingsSidebarService } from 'src/app/services/settings-sidebar.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'hy-notifications-and-account',
  templateUrl: './notifications-and-account.component.html',
  styleUrls: ['./notifications-and-account.component.scss']
})
export class NotificationsAndAccountComponent {

    env = environment

    constructor(
            public settingsSidebarService: SettingsSidebarService, 
            public notificationsService: NotificationsService,
            public notificationsSidebar: NotificationsSidebarService,
            public config: ConfigurationService) { 
    }
    
}
