import { Component, EventEmitter, forwardRef, HostBinding, HostListener, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
    selector: 'hy-toggle',
    template: "",
    styleUrls: ['./toggle.component.scss'],
    providers: [
        {
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => ToggleComponent),
        multi: true
        }
    ]
})
export class ToggleComponent implements ControlValueAccessor {

    @HostBinding('class.checked')
    public checked: boolean = false;

    private onChange: any = () => {};

    @Output()
    public change = new EventEmitter<boolean>();


    @HostListener('click', ['$event'])
    public toggle($event: MouseEvent) {
        this.onModelChange(!this.checked);

        $event.stopPropagation();
    }

    // ----- ControlValueAccessor ------------------------------------------- //

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
    }
    

    writeValue(checked: boolean) {
        this.checked = checked;
    }

    onModelChange(e: boolean) {
        this.checked = e;

        this.onChange(e);
        this.change.emit(e);
    }
}