<h1>User Login</h1>

<!--<div class="hy-input" [class.error]="error">-->
<!--    <div class="label">Email Address</div>-->
<!--    <input #email type="text" />-->
<!--</div>-->

<!--<div class="hy-input" [class.error]="error">-->
<!--    <div class="label">Password</div>-->
<!--    <input #password type="password" />-->
<!--</div>-->
<!--<a class="forgot" routerLink="/forgot-password">Forgot password?</a>-->

<!--<div class="error" *ngIf="error">-->
<!--    {{error}}-->
<!--</div>-->

<button class="hy-btn secondary" >LOG IN</button>
