import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BackendService, CommonDateFilter } from 'src/app/services/backend.service';
import { DateSelectionHeaderService } from 'src/app/services/date-selection-header.service';
import { FullSizeChartPageComponent } from './full-size-chart-page.component';

@Component({
  selector: 'hy-forecasts-chart-page',
  templateUrl: './full-size-chart-page.component.html',
  styleUrls: ['./full-size-chart-page.component.scss']
})
export class ForecastsChartPageComponent extends FullSizeChartPageComponent {

    id = "forecast";

    metricTitle = "TIME SERIES";
    metricKey = "forecast_name"

    backRouterLink = "/forecasts";

    private chartId;

    constructor(
            backendService: BackendService, 
            dateSelectionService: DateSelectionHeaderService,
            route: ActivatedRoute) {
        
        super(backendService, dateSelectionService);
        
        this.chartId = route.snapshot.params.chartId;

        this.id = "forecast-" + this.chartId;
    }


    protected loadChartDataImpl(filter: CommonDateFilter) {
        return this.backendService
            .getForecasts(filter)
            .then(x => {
                if (!x) {
                    return undefined;
                }

                const chart = x.charts.find(x => x.chart_id == this.chartId);

                this.showTimeInTooltips = (x.show_time !== false);
                this.chartTitle = chart?.chart_title;

                return {
                    data: chart?.forecasts,
                    nextUpdateTime: x.next_update_time
                };
            });
    }

}
