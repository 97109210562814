<right-sidebar title="Notifications" [service]="sidebarService">

    <div class="tabs">
        <div *ngFor="let tab of tabs; trackBy: tabTrackBy" 
                [class.active]="tab.type === selectedTabType"
                (click)="selectTab(tab)"
                class="tab">
            {{tab.title}}
        </div>
    </div>

    <div class="scrollable">

        <ng-lottie class="loader" *ngIf="loading" 
            [options]="{path: 'assets/loader-plain.json', loop: true}" 
            width="200px" 
            height="200px">
        </ng-lottie>

        <div *ngIf="!loading && newCount > 0" class="new-count">
            <div class="count">{{newCount}} NEW</div>
        </div>

        <ng-container *ngIf="!loading">

            <div class="no-items" *ngIf="!data?.notifications || data!.notifications.length === 0">
                There are no notifications.
            </div>

            <div *ngFor="let notification of data?.notifications" class="row" [class.new]="notification.is_new">
                <td class="icon-cell">
                    <div class="device-icon" [class]="'device-' +  notification.icon_id"></div>
                </td>
                <div class="right">
                    <div class="line">
                        <td class="device">{{notification.node_name}}</td>
                        <td class="date">{{notification.timestamp | date:'MMM d, YYYY HH:mm' : "+0000"}}</td>
                    </div>
                    <div class="line">
                        <td [class.type]="true" [class]="notification.type">{{notification.type | titlecase}}</td>
                        <td class="message">{{notification.message}}</td>
                    </div>
                </div>
                <div class="dot">
                    <span></span>
                </div>
            </div>
        </ng-container>

    </div>

    <ng-template #footer>
        <div class="footer-desc">Displaying last 20 notifications</div>
        <div class="hy-btn secondary view-all" routerLink="/notifications" (click)="sidebarService.close()">
            VIEW ALL NOTIFICATIONS
        </div>
    </ng-template>

</right-sidebar>