import { Directive, Input, TemplateRef } from "@angular/core";

@Directive({
    selector: "[hy-errmsg]"
})
export class ErrorMsgDirective {

    @Input() 
    public key: string = "";

    constructor(public readonly template: TemplateRef<any>) {
    }
}