/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';

import { AudioAlerts } from '../models/audio-alerts';
import { ChangeName } from '../models/change-name';
import { ChangeUnits } from '../models/change-units';
import { ConfigurationResponseSchema } from '../models/configuration-response-schema';
import { ToolTip } from '../models/tool-tip';

@Injectable({ providedIn: 'root' })
export class UserDetailsService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `configurationConfigurationGet()` */
  static readonly ConfigurationConfigurationGetPath = '/configuration';

  /**
   * Configuration.
   *
   * Gets a configuration JSON file with all user info and settings.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `configurationConfigurationGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  configurationConfigurationGet$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<ConfigurationResponseSchema>> {
    const rb = new RequestBuilder(this.rootUrl, UserDetailsService.ConfigurationConfigurationGetPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ConfigurationResponseSchema>;
      })
    );
  }

  /**
   * Configuration.
   *
   * Gets a configuration JSON file with all user info and settings.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `configurationConfigurationGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  configurationConfigurationGet(
    params?: {
    },
    context?: HttpContext
  ): Observable<ConfigurationResponseSchema> {
    return this.configurationConfigurationGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<ConfigurationResponseSchema>): ConfigurationResponseSchema => r.body)
    );
  }

  /** Path part for operation `changeNameUserDetailsChangeNamePut()` */
  static readonly ChangeNameUserDetailsChangeNamePutPath = '/user_details/change_name';

  /**
   * Change Name.
   *
   * Change the name of the current user.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `changeNameUserDetailsChangeNamePut()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  changeNameUserDetailsChangeNamePut$Response(
    params: {
      body: ChangeName
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<any>> {
    const rb = new RequestBuilder(this.rootUrl, UserDetailsService.ChangeNameUserDetailsChangeNamePutPath, 'put');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<any>;
      })
    );
  }

  /**
   * Change Name.
   *
   * Change the name of the current user.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `changeNameUserDetailsChangeNamePut$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  changeNameUserDetailsChangeNamePut(
    params: {
      body: ChangeName
    },
    context?: HttpContext
  ): Observable<any> {
    return this.changeNameUserDetailsChangeNamePut$Response(params, context).pipe(
      map((r: StrictHttpResponse<any>): any => r.body)
    );
  }

  /** Path part for operation `changePasswordUserDetailsChangePasswordGet()` */
  static readonly ChangePasswordUserDetailsChangePasswordGetPath = '/user_details/change_password';

  /**
   * Change Password.
   *
   * Change the name of the current user.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `changePasswordUserDetailsChangePasswordGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  changePasswordUserDetailsChangePasswordGet$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<any>> {
    const rb = new RequestBuilder(this.rootUrl, UserDetailsService.ChangePasswordUserDetailsChangePasswordGetPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<any>;
      })
    );
  }

  /**
   * Change Password.
   *
   * Change the name of the current user.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `changePasswordUserDetailsChangePasswordGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  changePasswordUserDetailsChangePasswordGet(
    params?: {
    },
    context?: HttpContext
  ): Observable<any> {
    return this.changePasswordUserDetailsChangePasswordGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<any>): any => r.body)
    );
  }

  /** Path part for operation `changeUnitsUserSettingsChangeUnitPut()` */
  static readonly ChangeUnitsUserSettingsChangeUnitPutPath = '/user_settings/change_unit';

  /**
   * Change Units.
   *
   * Change the units the current user will use for the charts. (metric, imperial, etc)
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `changeUnitsUserSettingsChangeUnitPut()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  changeUnitsUserSettingsChangeUnitPut$Response(
    params: {
      body: ChangeUnits
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<any>> {
    const rb = new RequestBuilder(this.rootUrl, UserDetailsService.ChangeUnitsUserSettingsChangeUnitPutPath, 'put');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<any>;
      })
    );
  }

  /**
   * Change Units.
   *
   * Change the units the current user will use for the charts. (metric, imperial, etc)
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `changeUnitsUserSettingsChangeUnitPut$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  changeUnitsUserSettingsChangeUnitPut(
    params: {
      body: ChangeUnits
    },
    context?: HttpContext
  ): Observable<any> {
    return this.changeUnitsUserSettingsChangeUnitPut$Response(params, context).pipe(
      map((r: StrictHttpResponse<any>): any => r.body)
    );
  }

  /** Path part for operation `enableTooltipsUserSettingsEnableTooltipsPut()` */
  static readonly EnableTooltipsUserSettingsEnableTooltipsPutPath = '/user_settings/enable_tooltips';

  /**
   * Enable Tooltips.
   *
   * Enable or disable tooltips for the current user.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `enableTooltipsUserSettingsEnableTooltipsPut()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  enableTooltipsUserSettingsEnableTooltipsPut$Response(
    params: {
      body: ToolTip
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<any>> {
    const rb = new RequestBuilder(this.rootUrl, UserDetailsService.EnableTooltipsUserSettingsEnableTooltipsPutPath, 'put');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<any>;
      })
    );
  }

  /**
   * Enable Tooltips.
   *
   * Enable or disable tooltips for the current user.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `enableTooltipsUserSettingsEnableTooltipsPut$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  enableTooltipsUserSettingsEnableTooltipsPut(
    params: {
      body: ToolTip
    },
    context?: HttpContext
  ): Observable<any> {
    return this.enableTooltipsUserSettingsEnableTooltipsPut$Response(params, context).pipe(
      map((r: StrictHttpResponse<any>): any => r.body)
    );
  }

  /** Path part for operation `enableAudioAlertUserSettingsEnableAudioAlertPut()` */
  static readonly EnableAudioAlertUserSettingsEnableAudioAlertPutPath = '/user_settings/enable_audio_alert';

  /**
   * Enable Audio Alert.
   *
   * Enable or disable audio alerts for the current user.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `enableAudioAlertUserSettingsEnableAudioAlertPut()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  enableAudioAlertUserSettingsEnableAudioAlertPut$Response(
    params: {
      body: AudioAlerts
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<any>> {
    const rb = new RequestBuilder(this.rootUrl, UserDetailsService.EnableAudioAlertUserSettingsEnableAudioAlertPutPath, 'put');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<any>;
      })
    );
  }

  /**
   * Enable Audio Alert.
   *
   * Enable or disable audio alerts for the current user.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `enableAudioAlertUserSettingsEnableAudioAlertPut$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  enableAudioAlertUserSettingsEnableAudioAlertPut(
    params: {
      body: AudioAlerts
    },
    context?: HttpContext
  ): Observable<any> {
    return this.enableAudioAlertUserSettingsEnableAudioAlertPut$Response(params, context).pipe(
      map((r: StrictHttpResponse<any>): any => r.body)
    );
  }

  /** Path part for operation `resetToDefaultUserSettingsResetToDefaultPut()` */
  static readonly ResetToDefaultUserSettingsResetToDefaultPutPath = '/user_settings/reset_to_default';

  /**
   * Reset To Default.
   *
   * Reset the configurations of the current user to default.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `resetToDefaultUserSettingsResetToDefaultPut()` instead.
   *
   * This method doesn't expect any request body.
   */
  resetToDefaultUserSettingsResetToDefaultPut$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<any>> {
    const rb = new RequestBuilder(this.rootUrl, UserDetailsService.ResetToDefaultUserSettingsResetToDefaultPutPath, 'put');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<any>;
      })
    );
  }

  /**
   * Reset To Default.
   *
   * Reset the configurations of the current user to default.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `resetToDefaultUserSettingsResetToDefaultPut$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  resetToDefaultUserSettingsResetToDefaultPut(
    params?: {
    },
    context?: HttpContext
  ): Observable<any> {
    return this.resetToDefaultUserSettingsResetToDefaultPut$Response(params, context).pipe(
      map((r: StrictHttpResponse<any>): any => r.body)
    );
  }

}
