<modal-dialog #dialog title="Email notifications">
    <ng-lottie class="loader" *ngIf="loading" 
        [options]="{path: 'assets/loader-plain.json', loop: true}" 
        width="200px" 
        height="200px">
    </ng-lottie>

    <div class="table" *ngIf="!loading">
        <div class="device-title">COMPONENT</div>
        <div class="all-action" *ngIf="allWarningsEnabled"  (click)="setAllWarnings(false)">DISABLE ALL</div>
        <div class="all-action" *ngIf="!allWarningsEnabled" (click)="setAllWarnings(true)" >ENABLE ALL </div>
        <div class="all-action" *ngIf="allErrorsEnabled"    (click)="setAllErrorss(false)" >DISABLE ALL</div>
        <div class="all-action" *ngIf="!allErrorsEnabled"   (click)="setAllErrorss(true)"  >ENABLE ALL </div>
        
        <ng-container *ngFor="let device of settings?.devices">
            <div class="device-name">{{device.node_name}}</div>
            <div class="hy-check-row" (click)="toggleWarning(device)" [class.active]="device.warning">
                <div class="hy-check"></div>Warnings
            </div>
            <div class="hy-check-row" (click)="toggleError(device)" [class.active]="device.error">
                <div class="hy-check"></div>Errors
            </div>
        </ng-container>
    </div>

    <ng-template #buttons>
        <div class="hy-btn secondary" (click)="close()">CANCEL</div>
        <div class="hy-btn" (click)="save()">SAVE</div>
    </ng-template>
</modal-dialog>