<simple-page-header title="Alert Center"></simple-page-header>

<div class="top">
    <div class="left">
        <div class="filters">
            <div *ngIf="sidebar.dateState" class="filter-term" (click)="sidebar.clearDateFilter()">
                <span>{{sidebar.formatedActiveDateFilter()}}</span>
            </div>

            <ng-container *ngIf="!sidebar.devicesState.allSelected">
                <ng-container *ngFor="let item of sidebar.devicesState.active">
                    <div *ngIf="item.selected" class="filter-term" (click)="sidebar.unselectDevice(item)">
                        <span>{{item.name}}</span>
                    </div>
                </ng-container>
            </ng-container>
            
            <ng-container *ngIf="!sidebar.typesState.allSelected">
                <ng-container *ngFor="let item of sidebar.typesState.active" >
                    <div *ngIf="item.selected" class="filter-term" (click)="sidebar.unselectType(item)">
                        <span>{{item.name}}</span>
                    </div>
                </ng-container>
            </ng-container>

            <div class="filter-term clear" *ngIf="filterApplyed" (click)="sidebar.clearAll()">
                <span>Clear All</span>
            </div>
        </div>
        <div class="label" [class.offset]="filterApplyed">
            <span *ngIf="data">Displaying {{firstIndex(data)}}-{{lastIndex(data)}} out of {{data.n_of_notifications}}</span>
        </div>
    </div>
    <div class="hy-btn dark filter" (click)="sidebarService.open()"><span class="ico-24"></span>Filters</div>
    <div class="hy-btn dark email"  (click)="emailDialog.open()"><span class="ico-24"></span>Email notifications</div>
</div>

<ng-lottie class="loader" *ngIf="loading" 
    [options]="{path: 'assets/loader-plain.json', loop: true}" 
    width="200px" 
    height="200px">
</ng-lottie>



<table class="table" *ngIf="!loading">
    <thead>
        <tr>
            <td></td>
            <td>Device</td>
            <td></td>
            <td>Type</td>
            <td>Message</td>
            <td>Date</td>
            <td>Time</td>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let notification of data?.notifications" 
                [class.new]="notification.is_new"
                [class.has-link]="notification.signal_id"
                (click)="openMonitoring(notification)">
            <td class="icon-cell">
                <div class="device-icon" [class]="'device-' +  notification.icon_id"></div>
            </td>
            <td>{{notification.node_name}}</td>
            <td class="type-icon" [class]="'type-' + notification.type"></td>
            <td class="type">{{notification.type | titlecase}}</td>
            <td class="message">{{notification.message}}</td>
            <td class="date">{{formatDateWithDayName(notification.timestamp)}}</td>
            <td class="type">{{formatTimeWithSeconds(notification.timestamp)}}</td>
        </tr>
    </tbody>
</table>

<div *ngIf="!loading && data?.notifications?.length == 0" class="no-items">
    There are no notifications.
</div>

<hy-pagination *ngIf="data" 
    [collectionSize]="data.n_of_notifications" 
    [pageSize]="data.notifications_per_page" 
    [page]="page + 1"
    (pageChange)="loadPage($event - 1)">
</hy-pagination>

<notifications-filter-sidebar #sidebar
    [service]="sidebarService"
    [devices]="data?.devices"
    (filterChange)="filterChanged()"></notifications-filter-sidebar>

<email-notifications-dialog #emailDialog></email-notifications-dialog>