import { Injectable } from "@angular/core";
import { Subject } from "rxjs";
import { CommonDateFilter } from "./backend.service";
import { environment } from "src/environments/environment";
import { newUtc } from "../util/DateUtils";

/**
 * Service to keep state of date selection header throughout the site.
 */
@Injectable({
    providedIn: 'root',
})
export class DateSelectionHeaderService {

    public reloadNeeded = new Subject();


    public minSelectableDate?: Date;
    public maxSelectableDate?: Date;
    public unavailableDates: Date[] = [];

    public selectedStartDate: Date;
    public selectedEndDate?: Date;

    /** 
     * Defines if live mode is selected. If we are not in live mode, we are 
     * then in history view. 
     */
    public liveModeSelected: boolean; 

    /** Date and time selected in  time slider. */
    public selectedDate: Date;

    public nextUpdate?: Date;

    private nextUpdateTimer?: any

    constructor() {
        const now = new Date();

        this.selectedStartDate = newUtc(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate() - 6);
        this.selectedEndDate = newUtc(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate());

        // When scenarios are enabled we are defaulting to Historical Overview as Live view is not available
        if (environment.enableScenarios) {
            this.liveModeSelected = false;
            this.selectedDate = this.selectedStartDate;
        }
        else {
            this.liveModeSelected = true;
            this.selectedDate = now;
        }
    }

    public triggerReload() {
        this.reloadNeeded.next(undefined);
    }

    public setNextUpdateTime(date: Date) {
        this.clearNextUpdateTime();
        this.nextUpdate = date;
        
        const updateInMilis = date.getTime() - Date.now();
        if (updateInMilis > 0) {
            this.nextUpdateTimer = setTimeout(() => this.triggerReload(), updateInMilis);
        }
    }

    public clearNextUpdateTime() {
        this.nextUpdate = undefined;
        if (this.nextUpdateTimer) {
            clearInterval(this.nextUpdateTimer);
            this.nextUpdateTimer = undefined;
        }
    }
    
    public setLiveMode() {
        this.liveModeSelected = true;
        this.selectedDate = new Date();
    }

    public setHistoricOverview(start: Date, end?: Date) {
        this.liveModeSelected = false;
        this.selectedStartDate = newUtc(start.getUTCFullYear(), start.getUTCMonth(), start.getUTCDate());
        this.selectedEndDate = end;
        this.selectedDate = start;
    }

    public getCommonDateFilter() : CommonDateFilter {
        if (this.liveModeSelected) {
            return {
                timestamp: this.selectedDate
            };
        }
        else {
            return {
                start: this.selectedStartDate,
                end: this.selectedEndDate
            };
        }
    }
}

