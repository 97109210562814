import { Component, HostListener } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter, map } from 'rxjs/operators';
import { ConfigurationService } from './services/configuration.service';
import { EscKeyScopeService } from './services/esc-key-scope.service';
import { ToastMessageService } from './services/toast-message.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'hy-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  host: {
      '[class.tooltips-enabled]': 'config.tooltipsEnabled',
      '[class.scenarios-enabled]': '_env.enableScenarios'
  }
})
export class AppComponent {
  title = 'hyai-frontend';

  _env = environment;

  constructor(
    public config: ConfigurationService,
    private escKeyScopeService: EscKeyScopeService,
    private titleService: Title,
    private router: Router,
    private activatedRoute: ActivatedRoute) { }

    ngOnInit() {
        const appTitle = this.titleService.getTitle();
        this.router
            .events.pipe(
                filter(event => event instanceof NavigationEnd),
                map(() => {
                    const child = this.activatedRoute.firstChild?.firstChild;

                    if (child && child.snapshot.data['title']) {
                        return child.snapshot.data['title'];
                    }

                    return appTitle;
                })
            )
            .subscribe((title: string) => {
                this.titleService.setTitle(title + " - HyAI");
            }
        );
    }

    @HostListener('document:keydown.escape')
    escKey() {
        this.escKeyScopeService.execute();
    }
}
