
<hy-line-chart-block
    [id]="id"
    [title]="chartTitle"
    [zoomData]="zoomChartData"
    [data]="chartData" 
    [live]="dateSelectionService.liveModeSelected"
    [nowDate]="nowDate"
    [fullRangeStart]="cls.fullRangeStart"
    [fullRangeEnd]="cls.fullRangeEnd"
    [zoomStart]="cls.zoomStartDate"
    [zoomEnd]="cls.zoomEndDate"
    [loadingStatus]="loadingStatus"
    [metricTitle]="metricTitle"
    [metricKey]="metricKey"
    [expandRouterLink]="backRouterLink"
    [showLegend]="showLegend"
    [alertRule]="alertRule"
    [showTimeInTooltips]="showTimeInTooltips"
    (tryAgain)="reload()"
    (zoomSelection)="onZoomSelection($event)"
    class="full">
</hy-line-chart-block>
