import { Component, EventEmitter, Output } from '@angular/core';
import { BackendService, ForecastsChart, ForecastsTimeSeries } from 'src/app/services/backend.service';
import { EscKeyScopeService } from 'src/app/services/esc-key-scope.service';
import { SidebarBaseService } from 'src/app/services/sidebar-base.service';
import { ToastMessageService } from 'src/app/services/toast-message.service';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'forecast-chart-editor-sidebar',
  templateUrl: './forecast-chart-editor-sidebar.component.html',
  styleUrls: ['./forecast-chart-editor-sidebar.component.scss']
})
export class ForecastChartEditorSidebarComponent extends SidebarBaseService {

    env = environment;

  //@Input()
  //public sidebarService!: SidebarBaseService;
    chartId?: number;

    chartTitle: string ="";
    forecastsIds: number[] = [];

    /** All available time series that system supports */
    allTimeseries: ForecastsTimeSeries[] = [];

    /** Timeseries that are already selected */
    selectedTimeseries: ForecastsTimeSeries[] = [];

    /** Timeseries that are available for selection. */
    availableTimeseries: ForecastsTimeSeries[] = [];

    error?: string;

    /** Indicates that the backend request is in progress. */
    loading = false;

    /** Event emitted on successfule forecasts chart change on the backend. */
    @Output()
    public updated = new EventEmitter();

    get saveDisabled() {
        return this.loading || !this.chartTitle || this.selectedTimeseries.length === 0;
    }

    constructor(
            private backendService: BackendService,
            escKeyService: EscKeyScopeService) {
        super(escKeyService) 
    }

    public open() {
        throw "not supported, use openForChart()"
    }

    public openForChart(chart: ForecastsChart, timeseries: ForecastsTimeSeries[]) {
        super.open();

        this.error = undefined;

        this.chartId = chart.chart_id;
        this.chartTitle = chart.chart_title;

        this.allTimeseries = timeseries;
        this.selectedTimeseries = [];
        this.availableTimeseries = [];

        timeseries.forEach(series => {
            const selected = chart.forecasts.find(x => x.forecast_id === series.forecast_id);

            if (selected) {
                this.selectedTimeseries.push(series);
            }
            else {
                this.availableTimeseries.push(series);
            }
        });
    }

    public openForNew(timeseries: ForecastsTimeSeries[]) {
        super.open();

        this.error = undefined;
        
        this.chartId = undefined;
        this.chartTitle = "";

        this.allTimeseries = timeseries;
        this.selectedTimeseries = [];
        this.availableTimeseries = [...timeseries];
    }

    save() {
        if (this.saveDisabled) {
            return;
        }

        let action;
        if (this.chartId === undefined) { 
            action = this.backendService
                .addForecastsChart(
                    this.chartTitle,
                    this.selectedTimeseries.map(x => x.forecast_id)
                );
        } 
        else {
            action = this.backendService
                .editForecastsChart(
                    this.chartId,
                    this.chartTitle,
                    this.selectedTimeseries.map(x => x.forecast_id)
                );
        }

        this.executeBackendAction(action);
    }

    delete() {
        if (!this.chartId) {
            return;
        }

        this.executeBackendAction(
            this.backendService.deleteForecastsChart(this.chartId)
        );
    }

    private executeBackendAction(action: Promise<any>) {
        this.loading = true;
        this.error = undefined;

        return action
            .then(() => {
                this.close();
                this.updated.emit();
            })
            .catch(err => this.error = err.message)
            .finally(() => this.loading = false);
    }

    removeSeries(series: ForecastsTimeSeries) {
        this.selectedTimeseries = this.selectedTimeseries.filter(x => x != series);

        // we do not just push `series` to the array, because we want to keep 
        // the order of series as they were provided by the backend.
        this.availableTimeseries = this.allTimeseries.filter(x => this.selectedTimeseries.indexOf(x) < 0);
    }

    addTimeseries(inId: string) {
        const id = parseInt(inId);
        const series = this.allTimeseries.find(x => x.forecast_id == id);
        
        if (series) {
            this.selectedTimeseries.push(series);
        }

        this.availableTimeseries = this.availableTimeseries.filter(x => x.forecast_id != id);
    }


}
