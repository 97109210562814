<right-sidebar [service]="service" title="Alert Editor" class="has-header-border">

    <div class="content" [formGroup]="form" [class.show-input-errors]="showErrors">
        <h2>{{device.node_name}}</h2>

        <hy-input-group label="SIGNAL" [disabled]="true" [value]="signal.signal_name"></hy-input-group>

        <div class="operator-input-group">
            <div class="hy-e-title">OPERATOR</div>
            <div class="hy-select-e">
                <select #operator formControlName="operator" (change)="onOperatorChange()">
                    <option value="&lt;">lower than</option>
                    <option value="&gt;">higher than</option>
                    <option value="=">value equals</option>
                    <option value="outside range">outside range</option>
                </select>
            </div>
        </div>

        <hy-form-errors [control]="formOperator" [show]="showErrors">
            <ng-template hy-errmsg key="required">
                Please select an operator for a chosen signal
            </ng-template>
        </hy-form-errors>

        <ng-container *ngIf="operator.value !== 'outside range'">
            <hy-input-group 
                    label="VALUE" 
                    formControlName="value1">
            </hy-input-group>

            <hy-form-errors [control]="formValue1" [show]="showErrors">
                <ng-template hy-errmsg key="required">
                    Please input a valid value determined by a signal
                </ng-template>
                <ng-template hy-errmsg key="float">
                    Please input a valid number
                </ng-template>
            </hy-form-errors>
        </ng-container>



        <div class="min-max" *ngIf="operator.value === 'outside range'">
            <div>
                <hy-input-group label="MIN VALUE" formControlName="value1"></hy-input-group>

                <hy-form-errors [control]="formValue1" [show]="showErrors">
                    <ng-template hy-errmsg key="required">
                        Please input a valid value determined by a signal
                    </ng-template>
                    <ng-template hy-errmsg key="float">
                        Please input a valid number
                    </ng-template>
                </hy-form-errors>
            </div>
            <div>
                <hy-input-group label="MAX VALUE" formControlName="value2"></hy-input-group>

                <hy-form-errors [control]="formValue2" [show]="showErrors">
                    <ng-template hy-errmsg key="required">
                        Please input a valid value determined by a signal
                    </ng-template>
                    <ng-template hy-errmsg key="float">
                        Please input a valid number
                    </ng-template>
                </hy-form-errors>
            </div>
        </div>


        <div class="status">
            <div class="s-label">
                <div class="title">Alert status</div>
                <div class="subtitle">Enable or disable this alert</div>
            </div>
            <div class="toggle">
                <hy-toggle formControlName="enabled"></hy-toggle>
            </div>
        </div>
    </div>

    <ng-template #footer>
        <div class="error">{{error}}</div>
    </ng-template>

    <ng-template #buttons>
        <div class="hy-btn secondary" (click)="service.close()">CANCEL</div>
        <div class="hy-btn" (click)="updateAlert()" [class.disabled]="loading">DONE</div>
    </ng-template>

</right-sidebar>
