import { Component, ElementRef, EventEmitter, HostListener, Input, OnChanges, Output, SimpleChanges, ViewChild } from '@angular/core';
import { PresetDate } from 'src/api/models';
import { ConfigurationService } from 'src/app/services/configuration.service';
import DateUtils, { dateIsSameMonth, getShortMonthNameIndex, newUtc } from 'src/app/util/DateUtils';
import { environment } from 'src/environments/environment';
import { CompleteAvailableRangeOption, DateRangeOption, LastCalendarYearRangeOption, LastDaysDateRangeOption, ThisYearRangeOption, createRangesFromPresetDates } from './date-range-option';
import { formatDate } from '@angular/common';
import { LeftCalendarMonth, RightCalendarMonth } from './calendar-month';
import { DateSelectionHeaderService } from 'src/app/services/date-selection-header.service';


export interface DateSelection {
    start: Date;
    end?: Date;
}

export interface DayItem {
    label: any;
    type: string;
    /** Defines if date is available for selection. */
    available: boolean;
    date?: Date;
}



@Component({
  selector: 'hy-date-range-selector',
  templateUrl: './date-range-selector.component.html',
  styleUrls: ['./date-range-selector.component.scss']
})
export class DateRangeSelectorComponent implements OnChanges {

    readonly DateInputFormat = 'MMM d, yyyy';

    leftMonth = new LeftCalendarMonth(this);
    rightMonth= new RightCalendarMonth(this);

    _dateRanges: DateRangeOption[] = [
        new LastDaysDateRangeOption("Last 7 days" , 7),
        new LastDaysDateRangeOption("Last 30 days", 30),
        new LastDaysDateRangeOption("Last 90 days", 90),
        new ThisYearRangeOption(),
        new LastCalendarYearRangeOption(),
        new CompleteAvailableRangeOption()
    ];

    @ViewChild("startInput")
    startInput!: ElementRef<HTMLInputElement>;

    @ViewChild("endInput")
    endInput!: ElementRef<HTMLInputElement>;

    /** First date that can be selected */
    @Input()
    minDate? : Date;

    /** Last date that can be selected */
    @Input()
    maxDate? : Date;

    /** Dates between minDate and maxDate that cannot be selected. */
    @Input()
    unavailableDates: Date[] = [];

    /** Selected dates are the ones that are visually selected */
    @Input()
    selectedStart!: Date;

    @Input()
    selectedEnd?: Date;

    @Output()
    selectionCanceled = new EventEmitter<void>();

    @Output()
    selectionApplyed = new EventEmitter<DateSelection>();

    env = environment;

    constructor(public configurationService: ConfigurationService, 
                public dateSelectionService: DateSelectionHeaderService) {

        const presetDates = configurationService.presetDates;
        if (presetDates && presetDates.length > 0) {
            this._dateRanges = createRangesFromPresetDates(presetDates);
        }
        else {
            this._dateRanges = this._dateRanges.filter(range => range.containedInAvailableRange(this));
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (dateIsSameMonth(this.selectedStart, this.selectedEnd) || !this.selectedEnd) {
            this.setCurMonth(this.selectedStart.getUTCMonth(), this.selectedStart.getUTCFullYear());
        }
        else {
            this.leftMonth.setMonth(this.selectedStart.getUTCMonth(), this.selectedStart.getUTCFullYear());
            this.rightMonth.setMonth(this.selectedEnd.getUTCMonth(), this.selectedEnd.getUTCFullYear());
        }
        
        this._dateRanges.forEach(r => r.updateIsSelected(this));
    }

    private updateSelectedDateRange() {
        this._dateRanges.forEach(r => r.updateIsSelected(this));
    }

    private refreshCalendars(): void {
        this.leftMonth.refresh();
        this.rightMonth.refresh();
    }

    private setCurMonth(month: number, year: number): void {
        
        let leftMonth;
        let rightMonth;
        if (environment.enableScenarios) {
            leftMonth = newUtc(year, month);
            rightMonth = newUtc(year, month + 1);
        }
        else {
            leftMonth = newUtc(year, month - 1);
            rightMonth = newUtc(year, month);
        }

        this.leftMonth.setMonth(leftMonth.getUTCMonth(), rightMonth.getUTCFullYear());
        this.rightMonth.setMonth(rightMonth.getUTCMonth(), rightMonth.getUTCFullYear());
    }

    applySelection(): void {
        this.selectionApplyed.emit({
            start: this.selectedStart,
            end: this.selectedEnd
        });
    }

    @HostListener('click')
    cancelSelection(): void {
        this.refreshCalendars();
        this.selectionCanceled.emit();
    }

    selectItem(item: DayItem): void {
        if (!item.date) {
            return
        }

        if (!this.selectedStart) {
            // first select of the date selects the start
            this.selectedStart = item.date;
            item.type = "in selected";
        } else if (!this.selectedEnd) {
            const diffTime = item.date.getTime() - this.selectedStart.getTime();
            const diffDays = Math.floor(Math.abs(diffTime) / (1000 * 60 * 60 * 24));

            if (diffDays === 0) {
                // selected already selected date
                return;
            }
            else if (diffTime < 0) {
                // new select is after start date, so we set it as start date
                // and previous start is set as end date
                this.selectedEnd = this.selectedStart;
                this.selectedStart = item.date;
            }
            else {
                // selected date is in range of 7 days after start so we set it
                // as a end date
                this.selectedEnd = item.date;
            }

        } else {
            // we already have selected start and end, so we reset selection and
            // set this as a new start
            this.selectedStart = item.date;
            this.selectedEnd = undefined;
        }

        this._dateRanges.forEach(r => r.updateIsSelected(this));
        this.refreshCalendars();
    }

    selectRange(range: DateRangeOption) : void {
        this._dateRanges.forEach(r => r.clearSelected());
        range.select(this);

        this.updateCalendarsToMatchSelection();
    }

    private updateCalendarsToMatchSelection(): void {
        const start = this.selectedStart;
        const end = this.selectedEnd ?? this.selectedStart;

        // might be better to do differently for live dash
        const isStartAndEndSameMonth = start.getUTCMonth() === end.getUTCMonth()
                                    && start.getUTCFullYear() === end.getUTCFullYear();

        this.leftMonth.setMonth(start.getUTCMonth(), start.getUTCFullYear());
        if (isStartAndEndSameMonth) {
            const nextMonth = newUtc(start.getUTCFullYear(), start.getUTCMonth() + 1);
            
            this.rightMonth.setMonth(nextMonth.getUTCMonth(), nextMonth.getUTCFullYear());
        }
        else {
            this.rightMonth.setMonth(end.getUTCMonth(), end.getUTCFullYear());
        }
    }

    setStartInput(inputEl: HTMLInputElement): void {
        const inputDate = parseInputDate(inputEl.value);

        if (!inputDate) {
            // format is invalid, so reset value in ui
            this.resetStartInput();
            return;
        }

        this.setStartDate(inputDate);
    }


    setStartDate(date: Date): void {
        if (DateUtils.isSameDay(date, this.selectedStart)) {
            // no update needed
            return;
        }

        if (this.minDate && date.getTime() < this.minDate.getTime()) {
            this.resetStartInput();
            return;
        }

        if (this.selectedEnd && date.getTime() > this.selectedEnd.getTime()) {
            // if start is after end, we set the end instead and reset the start
            this.setEndDate(date);
        }
        else {
            this.selectedStart = date;

            this.updateCalendarsToMatchSelection();
            this.updateSelectedDateRange();
        }

        this.resetStartInput();
    }

    setEndInput(inputEl: HTMLInputElement): void {
        const inputDate = parseInputDate(inputEl.value);

        if (!inputDate) {
            // format is invalid, so reset value in ui
            this.resetEndInput();
            return;
        }

        this.setEndDate(inputDate)
    }

    setEndDate(date: Date): void {
        if (DateUtils.isSameDay(date, this.selectedEnd)) {
            // no update needed
            return;
        }

        if (this.maxDate && date.getTime() > this.maxDate.getTime()) {
            this.resetEndInput();
            return;
        }

        if (date.getTime() < this.selectedStart.getTime()) {
            this.setStartDate(date);
        }
        else {
            this.selectedEnd = date;

            this.updateCalendarsToMatchSelection();
            this.updateSelectedDateRange();
        }

        this.resetEndInput();
    }

    resetStartInput() {
        this.startInput.nativeElement.value = formatDate(this.selectedStart, this.DateInputFormat, 'en', "+0000");
    }

    resetEndInput() {
        this.endInput.nativeElement.value = formatDate(this.selectedEnd || this.selectedStart, this.DateInputFormat, 'en', "+0000");
    }
    /** Returns appropriate css class of the date cell, depending if it is
     *  selected or not, and taking into account visuals like is it first or last
     *  in selection  */
    public _selectionClass(date: Date, today: Date) {
        var time = date.getTime();

        let startTime = this.selectedStart.getTime();
        let endTime = this.selectedEnd ? this.selectedEnd.getTime() : startTime;

        let clazz = "";

        if (DateUtils.isSameDay(date, today)) {
            clazz += " today";
        }

        if (time >= startTime && time <= endTime) {
            clazz += " selected";
        }
        if (time === startTime) {
            clazz += " first";
        }
        if (time === endTime) {
            clazz += " last";
        }

        return clazz;
    }

    private isSelectedDate(date: Date) : boolean {
        return DateUtils.isSameDay(date, this.selectedStart)
            || DateUtils.isSameDay(date, this.selectedEnd);
    }

    public _isDateAvailable(date: Date) : boolean {
        if (this.minDate && date.getTime() < this.minDate.getTime()) {
            return false;
        }
        if (this.maxDate && date.getTime() > this.maxDate.getTime()) {
            return false;
        }
        if (this.unavailableDates.some(d => DateUtils.isSameDay(d, date))) {
            return false;
        }

        return true;
    }

}

function parseInputDate(input: string): Date | undefined {
    // supported format: Oct 7, 2008
    const parts = input.split(/[ .]+/);

    if (parts.length < 3) {
        return;
    }

    const monthPart = parts[0];
    const dayPart   = parts[1];
    const yearPart  = parts[2];

    const monthIndex = getShortMonthNameIndex(monthPart);
    const day = parseInt(dayPart, 10);
    const year = parseInt(yearPart, 10);

    if (monthIndex < 0) {
        return;
    }
    if (!(year > 1000 && year < 3000)) {
        return;
    }
    if (!(day > 0 && day < 32)) {
        return;
    }

    return newUtc(year, monthIndex, day);

}
