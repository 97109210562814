import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BackendService, CommonDateFilter } from 'src/app/services/backend.service';
import { DateSelectionHeaderService } from 'src/app/services/date-selection-header.service';
import { FullSizeChartPageComponent } from './full-size-chart-page.component';

@Component({
  selector: 'hy-monitoring-chart-page',
  templateUrl: './full-size-chart-page.component.html',
  styleUrls: ['./full-size-chart-page.component.scss']
})
export class MonitoringChartPageComponent extends FullSizeChartPageComponent {

    id = "monitoring";

    metricTitle = "";
    metricKey = "signal_name"

    backRouterLink = "/monitoring";

    showLegend = false;

    /** Monitoring data does not have future data, so disable live mode styling 
     *  where history data is grayed out. */
    disableLiveModeForChart = true;

    constructor(
            backendService: BackendService, 
            dateSelectionService: DateSelectionHeaderService,
            private route: ActivatedRoute) {

        super(backendService, dateSelectionService);
    }

    override get nowDate() {
        return undefined;
    }


    private getSignalById(nodeId: number, signal_id: number) {
        return this.backendService.getMonitoringSignals(this.dateSelectionService.getCommonDateFilter())
            .then(data => {

                const device = data.devices.find(x => x.node_id == nodeId);
                const signal = device?.signals.find(x => x.signal_id == signal_id);

                return signal;
            })
    }


    protected loadChartDataImpl(filter: CommonDateFilter) {
        const nodeId = this.route.snapshot.params.nodeId;
        const signalId = this.route.snapshot.params.signalId;
        
        return this.getSignalById(nodeId, signalId)
            .then(signal => 
                this.backendService
                    .getMonitoringChart(filter, signalId)
                    .then(data => {

                        const chartData = {
                            ...signal,
                            ...data
                        }

                        console.log("signal", signal);
        
                        return {
                            data: [chartData],
                            nextUpdateTime: data.next_update_time
                        };
                    })
            );
    }

}
