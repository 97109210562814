import { Component, HostBinding, OnInit } from '@angular/core';
import { DateRange } from 'src/app/layout/time-slider/time-slider.component';
import { LoadingStatus } from 'src/app/modules/shared/loading-status';
import { BackendService, CommonDateFilter, ForecastsResponse } from 'src/app/services/backend.service';
import { CachedBackendService } from 'src/app/services/cached-backend.service';
import { DateSelectionHeaderService } from 'src/app/services/date-selection-header.service';
import { ToastMessageService } from 'src/app/services/toast-message.service';
import { ChartLoadingSupport } from 'src/app/util/ChartLoadingSupport';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'hy-forecasts-page',
  templateUrl: './forecasts-page.component.html',
  styleUrls: ['./forecasts-page.component.scss']
})
export class ForecastsPageComponent implements OnInit {

    env = environment;

    loadingStatus: LoadingStatus = LoadingStatus.Loading;

    zoomChartData?: ForecastsResponse;
    data?: ForecastsResponse;

    minLegendRows = 0;

    @HostBinding("class.single-chart")
    isSingleChartPage = false;

    @HostBinding("class.double-charts-page")
    isDoubleChartPage = false;

    @HostBinding("class.chart-grid")
    isChartGrid = false;

    dragOptions = {
        handle: ".move",
        onUpdate: (event: any) => {
            if (!this.data) {
                return;
            }
            const ids = this.data.charts.map(x => x.chart_id);

            this.backendService
                .reorderForecastsCharts(ids)
                .catch(err => this.toast.networErrorHandler);
        }
    };

    showTimeInTooltips = true;

    cls: ChartLoadingSupport;


    get numCharts() {
        return this.data?.charts?.length ?? 0
    }

    constructor(
            private backendService: BackendService, 
            private toast: ToastMessageService, 
            public dateSelectionService: DateSelectionHeaderService) {

        this.cls = new ChartLoadingSupport(this.dateSelectionService);
    }

    ngOnInit() {
        this.loadChartData();
    }

    private loadChartData() { 
        this.loadChartDataImpl(this.cls.getFilter());

    }

    private loadChartDataImpl(filter: CommonDateFilter) {
        this.loadingStatus = LoadingStatus.Loading;
        this.backendService
            .getForecasts(filter)
            .then(data => {
                if (data) {
                    if (!this.zoomChartData) {
                        this.zoomChartData = data;
                    }
                    this.loadingStatus = LoadingStatus.Loaded;
                    this.data = data;
                    this.dateSelectionService.setNextUpdateTime(new Date(data.next_update_time));
                }
                else {
                    this.loadingStatus = LoadingStatus.NoData;
                    this.data = undefined;
                    return;
                }

                this.isSingleChartPage = data.charts.length === 1;
                this.isDoubleChartPage = data.charts.length === 2;
                this.isChartGrid = data.charts.length > 2;
                this.showTimeInTooltips = (data.show_time !== false);
                //this.minLegendRows = Math.max(data.demand_generation.length, data.prices.length);


                // order charts by chart_position
                data.charts = data.charts.sort((a, b) => a.chart_position - b.chart_position);
            })
            .catch(() => this.loadingStatus = LoadingStatus.Failed);
    }

    reload() {
        if (this.backendService instanceof CachedBackendService) {
            this.backendService.clearForecastsCache();
        }
        this.loadChartData();
    }

    selectedDateChanged(date: any) {
        this.cls.selectedDateChanged();
        this.zoomChartData = undefined;
        this.loadChartData();
    }


    onZoomSelection(range: DateRange) {
        this.cls.onZoomSelection(range);
        this.loadChartData();
    }

    trackByChartId(index: number, chart: any) {
        return chart.chart_id;
    }

}