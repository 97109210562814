import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, Router } from '@angular/router';
import { DaterangepickerDirective } from 'ngx-daterangepicker-material';
import { BackendService, Notification, NotificationsResponse } from 'src/app/services/backend.service';
import { NotificationsService } from 'src/app/services/notifications.service';
import { SidebarBaseService } from 'src/app/services/sidebar-base.service';
import { ToastMessageService } from 'src/app/services/toast-message.service';
import DateUtils from 'src/app/util/DateUtils';
import { NotificationsFilterSidebarComponent } from './notifications-filter-sidebar/notifications-filter-sidebar.component';

@Component({
  selector: 'hy-notifications-page',
  templateUrl: './notifications-page.component.html',
  styleUrls: ['./notifications-page.component.scss'],
  providers: [SidebarBaseService]
})
export class NotificationsPageComponent implements OnInit {

    page = 0;
    loading = false;
    data?: NotificationsResponse;

    @ViewChild(NotificationsFilterSidebarComponent, { static: true })
    filterSidebar!: NotificationsFilterSidebarComponent;

    constructor(
        private backendService: BackendService,
        private toast: ToastMessageService,
        private router: Router,
        public sidebarService: SidebarBaseService,
        public notificationsService: NotificationsService,
        public cd: ChangeDetectorRef) {
    }

    /** Is true if atleast one filter is applied. */
    get filterApplyed() {
        return !this.filterSidebar.devicesState.allSelected
            || !this.filterSidebar.typesState.allSelected
            || this.filterSidebar.dateState;
    }

    ngOnInit(): void {
        this.loadPage(0);
    }

    formatDateWithDayName(date: string) {
        return DateUtils.formatDateWithDayName(new Date(date));
    }

    formatTimeWithSeconds(date: string) {
        return DateUtils.formatTimeWithSeconds(new Date(date));
    }

    firstIndex(data: NotificationsResponse) {
        return this.page * data.notifications_per_page + 1;
    }

    lastIndex(data: NotificationsResponse) {
        const lastPageMax = (this.page + 1) * data.notifications_per_page;
        return Math.min(lastPageMax, data.n_of_notifications);
    }

    loadPage(page: number) {
        this.page = page;
        this.loading = true;
        this.backendService
            .getNotifications(
                page,
                this.filterSidebar.dateState?.startDate?.toDate(),
                this.filterSidebar.dateState?.endDate?.toDate(),
                this.getFilterDeviceIds(),
                this.getFilterTypeIds()
            )
            .then(data => {
                this.loading = false;
                this.data = data;
                this.notificationsService.markNewAsRead(data.notifications);
                //this.dateSelectionService.setNextUpdateTime(new Date(data.next_update_time));
            })
            .catch(this.toast.networErrorHandler);
    }

    /** Called when sidebare filter is applayed. */
    filterChanged() {
        this.cd.detectChanges();
        this.loadPage(this.page);
    }

    openMonitoring(n: Notification) {
        if (!n.signal_id) {
            return;
        }
        this.router.navigate(
            ["/monitoring"],
            { queryParams: {
                op: 'signal',
                node_id: n.node_id,
                signal_id: n.signal_id,
                timestamp: n.timestamp
            }
        });
    }

    /** Formats array of selected device IDs from filter sidebar. */
    private getFilterDeviceIds(): string[] | undefined {
        if (!this.filterSidebar) {
            return;
        }
        return this.filterSidebar.devicesState.getSelectedIds();
    }

    /** Formats array of selected type IDs from filter sidebar. */
    private getFilterTypeIds(): string[] | undefined {
        if (!this.filterSidebar) {
            return;
        }
        return this.filterSidebar.typesState.getSelectedIds();
    }
}
