import { formatDate } from "@angular/common";
import { Component, Input } from "@angular/core";
import { LineSeriesOption, PiecewiseVisualMapComponentOption, YAXisComponentOption } from "echarts";
import { XAXisOption } from "echarts/types/dist/shared";
import { AlertOperator, AlertRule } from "src/app/util/AlertRule";
import { ChartBlockComponent, defaultXAxis } from "./chart-block.component";
import { formatLargeNumber } from "src/app/pipes/large-number.pipe";

// Bug: Connected nulls in timeline:
// https://github.com/apache/echarts/issues/15694


@Component({
    selector: 'hy-line-chart-block',
    templateUrl: './chart-block.component.html',
    styleUrls: ['./chart-block.component.scss']
})
export class LineChartBlockComponent extends ChartBlockComponent {
    /** Alert rule that will be used to visualize values outside accepted range. */
    @Input()
    public alertRule?: AlertRule;

    protected override updateZoomChartOptions(): void {
        if (!this.zoomData || !this.zoomData[this.pinnedSeriesIndex]) {
            console.error("No zoom data");
            return;
        }
        const data = this.zoomData[this.pinnedSeriesIndex].data;
        const seriesData = data.map(x =>  [x.timestamp, x.value]);

        this.zoomChartOptions = {
            height: 0,
            useUTC: true,
            animation: false,
            grid: {
                left: 0,
                right: 0,
                top: 0,
                bottom: 0
            },
            xAxis: {
                show: false,
                type: 'time',
                min: this.fullRangeStart,
                max: this.fullRangeEnd
            },
            yAxis: {
                show: false,
                type: 'value'
            },
            series: [{
                type: 'line',
                color: 'transparent',
                data: seriesData
            }],
            dataZoom: [{
                type: 'slider',
                xAxisIndex: 0,
                throttle: 0,
                top: 0,
                bottom: 8,
                left: 3,
                right: 11,
                //realtime: false,
                borderColor: "transparent",
                handleIcon: "image://data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='59' height='59' viewBox='0 0 59 59' fill='none'%3E%3Cpath d='M30 0H29V9.0164C25.091 9.27369 22 12.5259 22 16.5V41.5C22 45.4741 25.091 48.7263 29 48.9836V59H30V48.9836C33.909 48.7263 37 45.4741 37 41.5V16.5C37 12.5259 33.909 9.27369 30 9.0164V0Z' fill='%234F5662'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M27 21V37H28L28 21H27ZM31 21L31 37H32L32 21H31Z' fill='%23CFDFF6'/%3E%3C/svg%3E",
                moveHandleStyle: {
                    color: "#4f5662"
                },
                moveHandleSize: 8,
                moveHandleIcon: "image://data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8' fill='none'%3E%3Crect y='2' x='2' width='1' height='4' fill='%23CFDFF6'/%3E%3Crect y='2' x='5' width='1' height='4' fill='%23CFDFF6'/%3E%3C/svg%3E",
                selectedDataBackground: {
                    lineStyle: {
                        color: this.pinnedColor,
                        width: 1
                    },
                    areaStyle: {
                        opacity: 0
                    }
                },
                dataBackground: {
                    lineStyle: {
                        //color: this.pinnedColor,
                        color: "#4F5662",
                        width: 1
                    },
                    areaStyle: {
                        opacity: 0
                    }
                },
                minValueSpan: this.config.granularity * 3 * 60 * 1000, // 3 * granularity
                emphasis: {
                    moveHandleStyle: {
                        color: "#4F5662CC"
                    },
                    handleStyle: {
                        opacity: 0.8
                    }
                },
                
                fillerColor: "rgba(57, 61, 72, 0.10)",
                brushStyle: {
                    color: "rgba(57, 61, 72, 0.20)"
                },
                showDetail: false,
                rangeMode: ['value', 'value'],
                startValue: this.zoomStart,
                endValue: this.zoomEnd,
                filterMode: 'none'
            }],
        }
    }

    protected override updateChartOptions(): void {
        this.updateZoomChartOptions();
        const data = this.data;

        this.yAxis = [];
        this.yAxisMap = {};

        let {series, visualMaps} = this.createChartSeries();

        this.chartOptions = {
            useUTC: true,
            grid: {
                containLabel: true,
                left: 20,
                right: 30,
                top: 10,
                bottom: 13,
            },
            dataset: {
                source: this.createDataSource(data),
            },
            tooltip: {
                show: true,
                trigger: 'axis',
                borderWidth: 0,
                borderRadius: 4,
                backgroundColor: "rgba(31,35,44,0.8)",
                textStyle: {
                    fontFamily: "DINPro",
                    fontSize: 15,
                    color: "#CFDFF6"
                },
                formatter: this.showTimeInTooltips ? tooltipFormatterWithTime : tooltipFormatterWithoutTime,
                className: "tooltip"

            },
            visualMap: visualMaps,
            xAxis: defaultXAxis(),
            yAxis: this.yAxis,
            series,
            textStyle: {
                fontFamily: 'Blender Pro'
            }
        };

        if (!this.live) {
            const xAxis = <XAXisOption>this.chartOptions.xAxis!;

            xAxis.min = this.zoomStart;
            // -1 to not show last label on axis
            xAxis.max = this.zoomEnd.getTime() - 1;
            //xAxis.max = calcAxisMax(this.fullRangeStart, this.fullRangeEnd);
        }

        this.addMarkLines(this.chartOptions);
    }

    private createChartSeries() {
        const activeSeries = this.getEffectiveActiveSeries();

        const series: LineSeriesOption[] = [];
        const visualMaps: PiecewiseVisualMapComponentOption[] = [];

        let inChartSeriesIndex = 0;

        activeSeries.forEach(seriesIndex => {
            const chartData = this.data[seriesIndex];

            if (chartData.show_confidence) {
                const color = this.getSeriesColor(seriesIndex);

                // Add transparent lower line of confidences band
                series.push({
                    type: 'line',
                    encode: { x: 0, y: 1 + 4 * seriesIndex + 2 },
                    lineStyle: { opacity: 0 },
                    tooltip: { show: false },
                    stack: 'confidence-' + seriesIndex,
                });

                // Add area of confidences band
                series.push({
                    type: 'line',
                    encode: { x: 0, y: 1 + 4 * seriesIndex + 3 },
                    lineStyle: { opacity: 0 },
                    tooltip: { show: false },
                    areaStyle: {
                        color: color + "33"
                    },
                    stack: 'confidence-' + seriesIndex

                });

                inChartSeriesIndex += 2;
            }

            const def: LineSeriesOption = {
                type: 'line',
                name: (<any>chartData)[this.metricKey],
                encode: { x: 0, y: 1 + 4 * seriesIndex },
                yAxisIndex: this.getAxisIndex(chartData.units),
                symbol: "circle",
                symbolSize: 12,
                color: this.getSeriesColor(seriesIndex),
                itemStyle: {
                    opacity: 0,
                },
                emphasis: {
                    itemStyle: {
                        opacity: 1
                    }
                },
                z: 10 // over marklines
            };

            this.applayAlertMarklineToSeries(def);

            series.push(def);

            const map = this.createVisualMap(inChartSeriesIndex, seriesIndex);
            if (map) {
                visualMaps.push(map);
            }

            inChartSeriesIndex++
        });

        return {series, visualMaps};
    }

    private yAxis: YAXisComponentOption[] = []
    private yAxisMap: { [key: string ]: YAXisComponentOption & { index?: number } } = {};

    private getAxisIndex(units: string) {
        if (!this.yAxisMap[units]) {
            const axis: YAXisComponentOption = {
                type: 'value',
                name: units + "   ",
                axisLabel: {
                    formatter: yAxisFormatter,
                    fontFamily: 'Blender Pro',
                    color: "#CFDFF6",
                    fontSize: 12,
                    fontWeight: 400
                },
                splitLine: {
                    lineStyle: {
                        type: this.yAxis.length === 0 ? [10, 10] : "solid"
                        
                    }
                },
                nameGap: 20,
                nameLocation: "start",
                nameTextStyle: {
                    fontSize: 12,
                    color: "#CFDFF6",
                    align: this.yAxis.length === 0 ? "right" : "left"
                }
            };

            this.yAxisMap[units] = axis;
            this.yAxisMap[units].index = this.yAxis.length;
            this.yAxis.push(axis);
        }

        return this.yAxisMap[units].index;
    }


    
    /**
     * Create chart data source for "storage" key. data source is array of item
     * arrays. In item array first element is timestamp, other elements are
     * values from storage nodes. Index of the value is same as storage node
     * index.
     */
    private createDataSource(data: any) {
        const all: any = {};
        const nodes = data.length;

        // first merge all entries to "all" object so that we can use timestamp
        // as a unique key and group values by timestamp.
        for (let index = 0; index < data.length; index++) {
            const series = data[index];
            const entries = data[index].data;

            for (let i=0; i < entries.length; i++) {
                const entry = entries[i];

                if (!all[entry.timestamp]) {
                    all[entry.timestamp] = new Array(nodes);
                }

                all[entry.timestamp][4 * index    ] = (entry.value === null || entry.value === undefined) ? null : entry.value.toFixed(2); 
                all[entry.timestamp][4 * index + 1] = series.units;

                if (entry.confidence_min && entry.confidence_max) {
                    all[entry.timestamp][4 * index + 2] = (entry.confidence_min).toFixed(2);
                    all[entry.timestamp][4 * index + 3] = (entry.confidence_max - entry.confidence_min).toFixed(2);
                }
            }
        }


        // Generate headers for the data source
        let fakeHeaderCount = 2;
        for (const item in all) {
            fakeHeaderCount = all[item].length + 1;
            break;
        }
        const fakeHeaders = new Array(fakeHeaderCount).fill(0).map((_, i) => String.fromCharCode(10 + i));

        // convert "all" object to array, where key (timestamp) is first element
        // in item array
        const ds = [];
        ds.push(fakeHeaders); // headers we do not need, so we push empty array
        for (const item in all) {
            ds.push([item, ...all[item]]);
        }

        return ds;
    }

    private applayAlertMarklineToSeries(series: LineSeriesOption) {
        if (!this.alertRule) {
            return;
        }
        let lineData = [];
        let areaData;



        switch (this.alertRule.operator) {
            case AlertOperator.LowerThen:
                lineData = [{ yAxis: this.alertRule.value_1 }];
                areaData = [[{ yAxis: this.alertRule.value_1 }, { yAxis: Number.NEGATIVE_INFINITY }]];
                break;

            case AlertOperator.HigherThen:
                lineData = [{ yAxis: this.alertRule.value_1 }];
                areaData = [[{ yAxis: this.alertRule.value_1 }, { yAxis:Number.POSITIVE_INFINITY }]];
                break;

            case AlertOperator.EqualsThen:
                lineData = [{ yAxis: this.alertRule.value_1 }];
                break;

            case AlertOperator.OutsideRange:
                lineData = [{ yAxis: this.alertRule.value_1 }, { yAxis: this.alertRule.value_2 }];
                areaData = [[{ yAxis: this.alertRule.value_1 }, { yAxis: Number.NEGATIVE_INFINITY }], [{ yAxis: this.alertRule.value_2 }, { yAxis: Number.POSITIVE_INFINITY }]];
                break;
        }

        series.markLine = {
            silent: true,
            symbol: 'none',
            data: lineData,
            lineStyle: {
                type: [2, 4],
                width: 2,
                color: "#FF0000"
            },
            label: {
                show: false
            }
        };

        if (areaData) {
            series.markArea = {
                silent: true,
                itemStyle: {
                    color: '#FF00001A'
                },
                data: <any>areaData
            }
        }
    }

    private createVisualMap(inChartSeriesIndex: any, seriesIndex: any): PiecewiseVisualMapComponentOption | undefined {
        if (isAlertRuleDefined(this.alertRule)) {
            // For alert rules we color in red line outside thresholds
            return this.createAlertVisualMap(inChartSeriesIndex, seriesIndex);
        }
        else if (this.live) {
            // in live mode we grey out items in history
            return this.createLiveViewVisualMap(inChartSeriesIndex, seriesIndex);
        }

        return;
    }

    private createLiveViewVisualMap(inChartSeriesIndex: any, seriesIndex: any): PiecewiseVisualMapComponentOption {
        return {
            seriesIndex: inChartSeriesIndex,
            type: 'piecewise',
            show: false,
            dimension: 0,
            max: this.nowDate ? new Date().getTime() : 0,
            inRange: { color: this.getSeriesColor(seriesIndex) + "4C" },
            outOfRange: { color: this.getSeriesColor(seriesIndex) }
        }
    }

    private createAlertVisualMap(inChartSeriesIndex: any, seriesIndex: any): PiecewiseVisualMapComponentOption | undefined {
        let map: PiecewiseVisualMapComponentOption = {
            seriesIndex: inChartSeriesIndex,
            type: 'piecewise',
            show: false,
            dimension: 1 + 2 * seriesIndex,
            outOfRange: { color: "#FF0000" },
            inRange: { color: this.getSeriesColor(seriesIndex) }
        };

        switch (this.alertRule?.operator) {
            case AlertOperator.LowerThen:
                map.min = this.alertRule.value_1;
                map.max = 10**99;
                break;
            case AlertOperator.HigherThen:
                map.min = -(10**99);
                map.max = this.alertRule.value_1;
                break;
            case AlertOperator.EqualsThen:
                return; // We have nothing to do here
            case AlertOperator.OutsideRange:
                map.min = this.alertRule.value_1;
                map.max = this.alertRule.value_2;
                break;
        }

        return map;
    }
}

function tooltipFormatterWithTime(params: any) {
    return tooltipFormatterImpl(params, "MMMM d, HH:mm");
}
function tooltipFormatterWithoutTime(params: any) {
    return tooltipFormatterImpl(params, "MMMM d");
}



function tooltipFormatterImpl(params: any, format: string) {
    const date = formatDate(params[0].axisValue, format, "en-UK", "+0000");

    let html = `<div class='date'>${date}</div>`
        + "<div class='values'>";

    for (let series of params) {
        const value = series.value[series.encode.y[0]];
        const units = series.value[series.encode.y[0] + 1];

        const valueLabel = value === null ? 'N/A' : formatLargeNumber(value);

        html += `<span class='symbol'>${series.marker}</span>`
            + `<span class='name'>${series.seriesName}</span>`
            + "<span>"
            + `<span class='value'>${valueLabel}</span>`
            +   `<span class='units'>${units}</span>`
            + "</span>";
    }

    html += "</div>";

    return html;
}


function isAlertRuleDefined(rule: AlertRule | undefined) {
    return rule && rule.operator;
}

function yAxisFormatter(value: any)  {
    if (value >= 1000000) {
        return Math.round(value / 1000000) + 'M';
    }
    if (value >= 1000) {
        return Math.round(value / 1000) + 'k';
    }
    return value;
}


