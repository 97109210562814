
<div class="dialog" (click)="$event.stopPropagation()">
    <!-- stopPropagation() so that click inside dialog doesn't propagate to backdrop and closes the dialog -->
    <div class="content">
        <div class="esc" (click)="cancel()">
            <span>ESC</span> 
            <svg [innerHTML]="'collapse' | fico"></svg>
        </div>

        <h2>{{inputService.dialogTitle}}</h2>
        <div class="subtitle">{{inputService.dialogSubtitle}}</div>

        <div class="hy-input first" [class.error]="error">
            <div class="label">{{inputService.firstFieldTitle}}</div>
            <input #first type="{{inputService.firstFieldType}}" [value]="inputService.firstFieldValue" (keydown)="error = undefined" />
        </div>

        <div class="hy-input" [class.error]="error" [class.no-display]="!inputService.secondFieldTitle">
            <div class="label">{{inputService.secondFieldTitle}}</div>
            <input #second type="{{inputService.secondFieldType}}" [value]="inputService.secondFieldValue" (keydown)="error = undefined" />
        </div>
    </div>

    <div class="error-msg">
        {{error}}
    </div>

    <div class="footer">
        <div class="hy-btn secondary" (click)="cancel()">CANCEL</div>
        <div class="hy-btn" (click)="done(first.value, second.value)">UPDATE</div>
    </div>

</div>