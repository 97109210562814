import { Injectable } from '@angular/core';
import {
  HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse, HttpEventType
} from '@angular/common/http';

import { tap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { AuthService } from '../services/auth.service';

/** Pass untouched request through to the next request handler. */
@Injectable()
export class ExpiredTokenInterceptor implements HttpInterceptor {
    
    constructor(private router: Router, private auth: AuthService) {
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>>{
        return next.handle(req).pipe(tap(
            event => {
                if (event.type == HttpEventType.Response) {
                    const resp = <HttpResponse<any>>event;
                    const body = event.body;

                    if (typeof body === "string") {
                        return;
                    }

                    if (body && "status_code" in body && body.status_code === 401 && body.error === "InvalidTokenError") {
                        this.auth.logout();
                        this.router.navigate(["/login"]);
                    }
                }
                
            }
        ));
    }
}