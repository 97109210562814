import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { BackendService } from 'src/app/services/backend.service';
import { ToastMessageService } from 'src/app/services/toast-message.service';

@Component({
    selector: 'forgot-password-page',
    templateUrl: 'forgot-password-page.component.html',
    styleUrls: ['forgot-password-page.component.scss']
})
export class ForgotPasswordPageComponent {
    loading: boolean = false;

    constructor(
        private backend: BackendService, 
        private toast: ToastMessageService,
        private router: Router) {
    }

    sendLink(email: string) {
        this.loading = true;
        this.backend
            .sendResetLink(email)
            .finally(() => {
                this.loading = false;
                this.router.navigate(["/forgot-password-sent"]);
            });
            //.catch(this.toast.networErrorHandler);
    }
}
