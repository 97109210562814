import { Injectable } from '@angular/core';
import { BackendService, CommonDateFilter, MonitoringAlertUpdate, NotificationEmailSettings, SuccessMessage } from './backend.service';

import { formatDate } from '@angular/common';
import { Observable, firstValueFrom } from 'rxjs';
import { ScenariosSchema } from 'src/api/models';
import { ForecastsService, GeneralService, MetricsService, MonitoringService, NetworkService, NotificationsService, UserDetailsService } from 'src/api/services';
import { dateNextDayStart } from '../util/DateUtils';

interface CommonEndpointInput {
    setting_id: string,
    timestamp?: string,
    start_date?: string,
    end_date?: string,
}


@Injectable({
  providedIn: 'root',
})
export class BackendOpenApiService extends BackendService {

    constructor(
            private userDetailsService: UserDetailsService,
            private networkService: NetworkService,
            private metricsService: MetricsService,
            private monitoringService: MonitoringService,
            private forecastsService: ForecastsService,
            private generalService: GeneralService,
            private notificationsService: NotificationsService) {
        super();
     }

    public sendResetLink(email: string): Promise<any> {
        throw "Should no longer be used - replaced with Auth0"
    }

    public resetPassword(newPassword: string, token: string): Promise<any> {
        throw "Should no longer be used - replaced with Auth0"
    }

    public getConfiguration() {
        // TODO fix response type
        return firstValueFrom(this.userDetailsService.configurationConfigurationGet());
    }

    public getNetworkGraph(timestamp?: Date, load_next_available?: boolean) {
        // TODO fix response type
        return <any>handle422(this.networkService.getNetworkGraphSettingIdNetworkGraphGet({
            setting_id: this.settingId, 
            timestamp: formatDateTimeForBackend(timestamp),
            load_next_available
        }));
    }

    public getNetworkCharts(filter: CommonDateFilter) {
        // TODO fix response type
        return <any>handle422(this.networkService.getNetworkChartsSettingIdNetworkChartsGet(this.getCommonInput(filter)));
    }

    public getMetrics(filter: CommonDateFilter) {
        // TODO fix response type
        return <any>handle422(this.metricsService.getMetricsSettingIdMetricsGet(this.getCommonInput(filter)));
    }

    public getMonitoringSignals(filter: CommonDateFilter): Promise<any> {
        return <any>handle422(this.monitoringService.getMonitoringSignalsSettingIdMonitoringSignalsGet(this.getCommonInput(filter)));
    }

    public getMonitoringChart(filter: CommonDateFilter, signal_id: number): Promise<any> {
        const params = {
            signal_id,
            ... this.getCommonInput(filter)
        }
        return <any>handle422(this.monitoringService.getMonitoringChartSettingIdMonitoringChartGet(params));
    }

    public updateMonitoringAlert(body: MonitoringAlertUpdate): Promise<SuccessMessage> {
        return <any>firstValueFrom(this.monitoringService.editMonitoringAlertsSettingIdMonitoringAlertEditorPost({
            setting_id: this.settingId,
            body
        }));
    }

    public clearMonitoringAlert(node_id: number, signal_id: number): Promise<SuccessMessage> {
        return <any>firstValueFrom(this.monitoringService.clearMonitoringAlertSettingIdMonitoringClearAlertPost({
            setting_id: this.settingId,
            body: { node_id, signal_id }
        }));
    }

    public getForecasts(filter: CommonDateFilter) {
        return <any>handle422(this.forecastsService.getForecastsSettingIdForecastsGet(this.getCommonInput(filter)));
    }


    public editForecastsChart(chartId: number, chartName: string, forecastIds: number[]) {
        return firstValueFrom(this.forecastsService.editForecastsChartSettingIdForecastsEditChartPut({
            setting_id: this.settingId,
            body: {
                chart_id: chartId,
                chart_name: chartName,
                forecast_ids: forecastIds
            }
        }));
    }

    public addForecastsChart(chartName: string, forecastIds: number[]) {
        return <any>firstValueFrom(this.forecastsService.addForecastsChartSettingIdForecastsAddChartPost({
            setting_id: this.settingId,
            body: {
                chart_name: chartName,
                forecast_ids: forecastIds
            }
        }));
    }

    public deleteForecastsChart(chartId: number) {
        return <any>firstValueFrom(this.forecastsService.deleteForecastsChartSettingIdForecastsDeleteChartPost({
            setting_id: this.settingId,
            body: {
                chart_id: chartId
            }
        }));
    }

    public reorderForecastsCharts(chartIds: number[]) {
        return <any>firstValueFrom(this.forecastsService.reorderForecastsChartsSettingIdForecastsReorderChartsPut({
            setting_id: this.settingId,
            body: {
                chart_ids: chartIds
            }
        }));
    }

    public getNotifications(page_index: number, startDate?: Date, endDate?: Date, deviceIds?: string[], typeIds?: string[]) {
        type GetNotificationsParams = Parameters<NotificationsService["getNotificationsScenarioIdNotificationsGet"]>[0];

        const params: GetNotificationsParams = {
            scenario_id: this.settingId,
            page_index
        };

        if (startDate) {
            params.start_date = formatDateTimeForBackend(startDate);
        }
        if (endDate) {
            params.end_date = formatDateTimeForBackend(endDate);
        }
        if (deviceIds && deviceIds.length > 0) {
            params.devices = deviceIds.join(",");
        }
        if (typeIds && typeIds.length > 0) {
            params.type = typeIds.join(",");
        }

        // TODO fix response type
        return firstValueFrom(this.notificationsService.getNotificationsScenarioIdNotificationsGet(params))
            .then(x => (x ? x : { notifications: [], devices: [], n_of_notifications: 0, next_update_time: "", notifications_per_page: 0 })); // if no body, return empty data;
    }

    public markNotificationsAsRead(notifications: number[]) : Promise<any> {
        return firstValueFrom(this.notificationsService.updateNotificationViewStatusScenarioIdStatusUpdateNotificationsPut({
            scenario_id: this.settingId,
            body: { notification_ids: notifications.map(x => x.toString()) }
        }));

    }

    public getNotificationEmailSettings() : Promise<NotificationEmailSettings> {
        // TODO fix response type
        return firstValueFrom(this.notificationsService.getNotificationsEmailSettingsScenarioIdNotificationsEmailSettingsGet({scenario_id: this.settingId}));
    }

    public updateNotificationEmailSettings(warningDeviceIds: number[], errorDeviceIds: number[]) : Promise<any> {
        return firstValueFrom(this.notificationsService.updateNotificationEmailSettingsScenarioIdNotificationsUpdateEmailSettingsPut({
            scenario_id: this.settingId,
            body: { warning_ids: warningDeviceIds, error_ids: errorDeviceIds }
        }));
    }

    public getScenarios() : Promise<ScenariosSchema> {
        return firstValueFrom(this.generalService.getScenariosScenariosGet());
/*
        return Promise.resolve([
            {
                id: "1",
                name: "Scenario B (hidrogen powered plant)",
                params: [
                    { name: "Electricity price"     , value: 50        , unit: "US$ / MWh" },
                    { name: "Hydrogen price"        , value: 5         , unit: "US$ / kg"  },
                    { name: "Export grid connection", value: "TRUE"    , unit: ""          },
                    { name: "Electrolyser type"     , value: "Alkaline", unit: ""          }
                ]
            },
            {
                id: "2",
                name: "Scenario 5 Remote",
                params: [
                    { name: "Electricity price", value: 50        , unit: "US$ / MWh" },
                    { name: "Electrolyser type", value: "Alkaline", unit: ""          }
                ]
            },
            {
                id: "3",
                name: "Extended Hydrogen Structure v02",

                params: [
                    { name: "Electricity price"      , value: 50        , unit: "US$ / MWh" },
                    { name: "Hydrogen Compressor"    , value: 35        , unit: "bar"       },
                    { name: "Compressed gas storage" , value: 300       , unit: "kg"        },
                    { name: "Solar farm"             , value: 90        , unit: "kW"        },
                    { name: "Export grid connection" , value: "TRUE"    , unit: ""          },
                    { name: "NTS Input"              , value: 150       , unit: "kg/h"      },
                    { name: "Turbine stack flow rate", value: 150       , unit: "kg/h"      },
                    { name: "Solid state storage"    , value: 400       , unit: "kg"        },
                    { name: "Electrolyser type"      , value: "Alkaline", unit: ""          },
                    { name: "Hydrogen price"         , value: 5         , unit: "US$ / kg"  },
                ]
            }
        ] as ScenariosResponse[])
*/
    }

    public setUnits(units: string) : Promise<any> {
        return firstValueFrom(this.userDetailsService.changeUnitsUserSettingsChangeUnitPut({
            body: { units }
        }));
    }

    public enableAudioAlert(enable: boolean) : Promise<any> {
        return firstValueFrom(this.userDetailsService.enableAudioAlertUserSettingsEnableAudioAlertPut({
            body: { audio_alert: enable }
        }));
    }

    public enableTooltips(enable: boolean) : Promise<any> {
        return firstValueFrom(this.userDetailsService.enableTooltipsUserSettingsEnableTooltipsPut({
            body: { tooltip_enabled: enable }
        }));
    }

    public changeUserName(userName: string) : Promise<any>  {
        return firstValueFrom(this.userDetailsService.changeNameUserDetailsChangeNamePut({
            body: { new_name: userName }
        }));
    }

    public changeUserPassword() : Promise<any> {
        return firstValueFrom(this.userDetailsService.changePasswordUserDetailsChangePasswordGet());
    }

    public getSiteInformation() : Promise<any> {
        return firstValueFrom(this.generalService.getScenarioInformationSettingIdSiteInformationGet({setting_id: this.settingId}));
    }

    private getCommonInput(filter: CommonDateFilter) {
        const params: CommonEndpointInput = {
            setting_id: this.settingId,
        }

        if (filter.timestamp) {
            params.timestamp = formatDateTimeForBackend(filter.timestamp);
            // TODO remove hardcoded
            //params.timestamp = "2023-08-03T00:30:00";
        }
        else {
            params.start_date = formatDateTimeForBackend(filter.start);
            params.end_date = formatDateTimeForBackend(filter.end);
        }

        return params;
    }


}

function formatDateTimeForBackend(date?: Date) {
    return formatDate(date || Date.now(), "yyyy-MM-ddTHH:mm", "en-UK", "+0000");
}

function handle422<T>(o: Observable<T>) : Promise<T | undefined> {
    return firstValueFrom(o)
        .catch(err => {
            if (err.status == 422 || err.status == 204) {
                return undefined;
            } else {
                throw err;
            }
        });
}
