import { Component, OnInit } from '@angular/core';
import { BackendService, NotificationsResponse } from 'src/app/services/backend.service';
import { NotificationsSidebarService } from 'src/app/services/notifications-sidebar.service';
import { NotificationsService } from 'src/app/services/notifications.service';
import { ToastMessageService } from 'src/app/services/toast-message.service';

interface Tab {
    title: string;
    type?: string;
}

@Component({
  selector: 'hy-notifications-sidebar',
  templateUrl: './notifications-sidebar.component.html',
  styleUrls: ['./notifications-sidebar.component.scss']
})
export class NotificationsSidebarComponent implements OnInit {

    readonly tabs: Tab[] = [
        { title: "ALL"    , type: undefined     },
        { title: "INFO"   , type: "information" },
        { title: "WARNING", type: "warning"     },
        { title: "ERROR"  , type: "error"       }
    ];

    newCount = 0;

    loading = true;

    selectedTabType?: string;

    data?: NotificationsResponse;

    constructor(
            public sidebarService: NotificationsSidebarService,
            public notificationsService: NotificationsService,
            private backendService: BackendService,
            private toast: ToastMessageService) {
    }

    ngOnInit() {
        this.loadNotifications();
    }

    selectTab(tab: Tab) {
        this.selectedTabType = tab.type;
        this.loadNotifications(tab.type);
    }

    tabTrackBy(index: number, tab: Tab) {
        return tab.title;
    }

    private loadNotifications(type?: string) {
        let typeFilter = type ? [type] : undefined;
        this.loading = true;
        this.backendService
            .getNotifications(0, undefined, undefined, undefined, typeFilter)
            .then(data => {
                this.loading = false;
                this.data = data;
                this.newCount = data.notifications.filter(x => x.is_new).length;
                this.notificationsService.markNewAsRead(data.notifications);

            })
            .catch(this.toast.networErrorHandler);
    }

}
