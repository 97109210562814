import { Component, OnInit } from '@angular/core';
import { SiteInfoResponseSchema, TabSchema } from 'src/api/models';
import { LoadingStatus } from 'src/app/modules/shared/loading-status';
import { BackendService } from 'src/app/services/backend.service';

@Component({
  selector: 'hy-about-us',
  templateUrl: './about-us.component.html',
  styleUrls: ['./about-us.component.scss']
})
export class AboutUsComponent implements OnInit {

    loadingStatus: LoadingStatus = LoadingStatus.Loading;

    siteInfo?: SiteInfoResponseSchema;

    selectedTab? : TabSchema;

    constructor(private backend: BackendService) { 
    }

    ngOnInit() {
        this.loadSiteInformation();
    }

    loadSiteInformation() {
        this.loadingStatus = LoadingStatus.Loading;

        this.backend.getSiteInformation()
            .then((siteInfo) => {
                this.siteInfo = siteInfo;
                this.selectedTab = siteInfo.tabs[0];
                
                this.loadingStatus = LoadingStatus.Loaded;
            })
            .catch((error) => this.loadingStatus = LoadingStatus.Failed);
    }

}
