import { Component } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, Router } from '@angular/router';
import { BackendService } from 'src/app/services/backend.service';
import { ToastMessageService } from 'src/app/services/toast-message.service';


@Component({
  selector: 'hy-reset-password-page',
  templateUrl: './reset-password-page.component.html',
  styleUrls: ['./reset-password-page.component.scss']
})
export class ResetPasswordPageComponent {

    error?: string;

    constructor(
        private backend: BackendService, 
        private toast: ToastMessageService,
        private router: Router,
        private route: ActivatedRoute) {
    }

    resetPassword(pass1: string, pass2: string) {
        this.error = undefined;

        if (pass1 !== pass2) {
            this.error = "Passwords do not match.";
        }
        else {
            const token = this.route.snapshot.queryParams.token;
            
            this.backend
                .resetPassword(pass1, token) // TODO token from query string
                .then(_ => this.router.navigate(["/reset-password-done"]))
                .catch(error => {
                    if ("message" in error) {
                        this.error = error.message;
                    }
                    else {
                        this.toast.networErrorHandler();
                    }
                });
        }

    }

}
