import { Injectable } from '@angular/core';
import { Router } from '@angular/router';


@Injectable({
    providedIn: 'root',
})
export class ToastMessageService {

    //private routerEventSubscribed = false;


    public messages: string[] = [];

    constructor(
        private router: Router,
      ) {}

    networErrorHandler = (error?: any) => {
        console.error(error);

        if (error && error.error === "InvalidTokenError") {
            // InvalidTokenError is handled by ExpiredTokenInterceptor
            return;
        }
        this.replace("There was a problem while connecting to the server. Reload this page to continue.");

        // register route change detection so that route change trigger a full
        // page reload.
/*
        if (!this.routerEventSubscribed) {
            this.routerEventSubscribed = true;

            this.router.events.subscribe(e => {
                if (e instanceof NavigationStart) {
                    window.location.href = e.url;
                }
            })
        }
*/
    }

    /** Replaces all existing messages with defined message. */
    public replace(message: string) {
        this.messages = [message];
    }

    public show(message: string) {
        this.messages.push(message);
    }

    
    public dismiss(toast: string) {
        this.messages = this.messages.filter(m => m !== toast);
    }

    public hasMessage() {
        return this.messages.length > 0;
    }

}
