<hy-date-selection-header #header
    title="Metrics"
    (selectedDateChanged)="selectedDateChanged()"
    stickyClass="stuck"
    class="sticky">
</hy-date-selection-header>

<div class="charts">
    <hy-line-chart-block
        id="metrics-profit"
        [zoomData]="zoomChartData?.profit"
        [data]="data?.profit"  
        [live]="dateSelectionService.liveModeSelected"
        [nowDate]="dateSelectionService.selectedDate"
        [fullRangeStart]="cls.fullRangeStart"
        [fullRangeEnd]="cls.fullRangeEnd"
        [zoomStart]="cls.zoomStartDate"
        [zoomEnd]="cls.zoomEndDate"
        [loadingStatus]="loadingStatus"
        [showTimeInTooltips]="showTimeInTooltips"
        (tryAgain)="reload()"
        (zoomSelection)="onZoomSelection($event)"
        expandRouterLink="/metrics/profit"
        metricTitle="METRICS"
        class="vstretch">
    </hy-line-chart-block>
    <hy-line-chart-block 
        id="metrics-hydrogen"
        [zoomData]="zoomChartData?.hydrogen"
        [data]="data?.hydrogen" 
        [live]="dateSelectionService.liveModeSelected"
        [nowDate]="dateSelectionService.selectedDate"
        [fullRangeStart]="cls.fullRangeStart"
        [fullRangeEnd]="cls.fullRangeEnd"
        [zoomStart]="cls.zoomStartDate"
        [zoomEnd]="cls.zoomEndDate"
        [loadingStatus]="loadingStatus"
        [showTimeInTooltips]="showTimeInTooltips"
        (tryAgain)="reload()"
        (zoomSelection)="onZoomSelection($event)"
        expandRouterLink="/metrics/hydrogen" 
        metricTitle="METRICS"
        class="vstretch">
    </hy-line-chart-block>
</div>
