import { Injectable } from "@angular/core";
import { AuthService as Auth0Service } from '@auth0/auth0-angular';
import { EMPTY, from } from 'rxjs';
import { catchError, switchMap } from "rxjs/operators";
import { BackendService } from "./backend.service";
import { ConfigurationService } from "./configuration.service";
const TokenKey = "HYAI-Token";

@Injectable({
    providedIn: 'root',
})
export class AuthService {

    private _token: string | null;

    constructor(
            public auth: Auth0Service,
            private backend: BackendService,
            private config: ConfigurationService) {
        this._token = localStorage.getItem(TokenKey);
    }

    get authorized(): boolean {
        return this._token !== null;
    }

    get token(): string | null {
        return this._token;
    }

    private setToken(token: string | null) {
        if (token !== null) {
            localStorage.setItem(TokenKey, token);
            this._token = token;
        }
        else {
            localStorage.removeItem(TokenKey);
        }
    }

    public login(appState: {[key: string]: any}) {
        console.log("Attempting to login ...");
        this.auth.loginWithRedirect(appState);
        this.auth.handleRedirectCallback().pipe(
            switchMap(() => from(this.backend.getConfiguration())),
            catchError( error => {
                console.error('Login Failed:', error);
                return EMPTY
            })
        ).subscribe( resp => {
            console.log("Response is:", resp);
            this.config.initFromConfigurationRersponse(resp)
        }
        );
    }

    public logout() {
        this.setToken(null);
        this.backend.token = "";
        this.auth.logout({ logoutParams: { returnTo: window.location.origin } });
    }
}
