<div class="overlay hy-fadein" *ngIf="visible">
    <div class="dialog">
        <div class="header">
            <h2>{{title}}</h2>

            <div class="esc" (click)="close()">
                <span>ESC</span> 
                <svg [innerHTML]="'collapse' | fico"></svg>
            </div>
        </div>

        <div class="content">
            <ng-content></ng-content>
        </div>

        <div class="buttons" *ngIf="buttons">
            <ng-container [ngTemplateOutlet]="buttons"></ng-container>
        </div>

    </div>
</div>