<div class="date-picker" (click)="$event.stopPropagation()">

    <div class="left">
        <div class="date-ranges">
            <div class="range"
                    *ngFor="let range of _dateRanges"
                    [class.selected]="range.isSelected"
                    (click)="selectRange(range)">
                <span>{{range.name}}</span>
                <svg *ngIf="range.isSelected" [innerHTML]="'check' | fico"></svg>
            </div>
        </div>
        <div class="date-range-info" *ngIf="env.enableScenarios">
            <svg [innerHTML]="'info-circle' | fico"></svg>
            <div class="text">
                <div class="title">TOTAL AVAILABLE DATE RANGE</div>
                <div class="dates">{{ minDate | date :'MMM d, yyyy' : "+0000"}} — {{ maxDate | date :'MMM d, yyyy' : "+0000"}}</div>
            </div>
        </div>
    </div>

    <div class="right">

        <div class="calendars">
            <div class="calendar-container" *ngFor="let calendarMonth of [leftMonth, rightMonth]">
                <div class="controls">
                    <div class="prev"
                        [class]="calendarMonth.prevMonthClass"
                        (click)="calendarMonth.moveToPrevMonth()">
                    </div>
                    <div class="month">{{calendarMonth.label}}</div>
                    <div class="next"
                        [class]="calendarMonth.nextMonthClass"
                        (click)="calendarMonth.moveToNextMonth()">
                    </div>
                </div>

                <div class="calendar">
                    <div class="header">Mo</div>
                    <div class="header">Tu</div>
                    <div class="header">We</div>
                    <div class="header">Th</div>
                    <div class="header">Fr</div>
                    <div class="header">Sa</div>
                    <div class="header sunday">Su</div>

                    <div *ngFor="let item of calendarMonth.days"
                            class="day"
                            [class]="item.type"
                            [class.unavailable]="!item.available"
                            (click)="selectItem(item)">
                        {{item.label}}
                    </div>
                </div>
            </div>
        </div>


        <div class="footer">
            <input #startInput 
                    type="text" 
                    placeholder="Start date" 
                    class="search" 
                    [value]="selectedStart | date: DateInputFormat : '+0000' " 
                    (keydown.enter)="setStartInput(startInput)"
                    (blur)="setStartInput(startInput)"
            />
            <div class="dash">—</div>
            <input #endInput 
                    type="text" 
                    placeholder="End date" 
                    class="search" 
                    value="{{ selectedEnd || selectedStart | date: DateInputFormat : '+0000' }}"
                    (keydown.enter)="setEndInput(endInput)"
                    (blur)="setEndInput(endInput)"
            />
            <div class="spring"></div>
            <div class="hy-btn secondary" (click)="cancelSelection()">CANCEL</div>
            <div class="hy-btn" (click)="applySelection()">APPLY</div>
        </div>
    </div>
</div>
