import { Injectable } from "@angular/core";
import { Subject } from "rxjs";
import { ConfigurationResponseSchema, PresetDate, ScenarioSchema } from "src/api/models";
import { environment } from "src/environments/environment";
import { dateStartOfDay } from "../util/DateUtils";
import { BackendService } from "./backend.service";
import { DateSelectionHeaderService } from "./date-selection-header.service";
import { ToastMessageService } from "./toast-message.service";

const KeyPrefix = "HYAI-";

const ChartKeyPrefix = KeyPrefix + "Chart";
const KeyChartPinnedSeriesPrefix = ChartKeyPrefix + "PinnedSeries-";
const KeyChartActiveSeriesPrefix = ChartKeyPrefix + "ActiveSeries-";

const SelectedScenarioKey = KeyPrefix + "SelectedScenario";


@Injectable({
    providedIn: 'root',
})
export class ConfigurationService {

    public chartConfigCleared = new Subject();

    private _tooltipsEnabled = true;

    /** Defines if new notifications will trigger audio alerts */
    private _audioAlertEnabled = true;

    public lastAudioAlertNotificationId = -1;

    /** Latitude and Longitude of current plant location */
    public coordinates: number[] = [0, 0];

    /** Name of current plant */
    public locationName = "";
    
    public userName: string = "";
    public userEmail: string = "";

    /** Defines which unit system is selected by the user. */
    public units: string = "metric";

    public selectedScenarioId: string = "";
    public selectedScenarioName: string = "";
    public scenarios: ScenarioSchema[] = [];

    public presetDates: PresetDate[] = [];
    public granularity: number = 30;

    constructor(
            private backend: BackendService, 
            private toast: ToastMessageService,
            private dateSelectionHeaderService: DateSelectionHeaderService) {
    }
    
    public get tooltipsEnabled() : boolean {
        return this._tooltipsEnabled;
    }

    public set tooltipsEnabled(enabled: boolean) {
        this._tooltipsEnabled = enabled;
        this.backend
            .enableTooltips(enabled)
            .catch(this.toast.networErrorHandler);
        
    }

    public get audioAlertEnabled(): boolean {
        return this._audioAlertEnabled;
    }

    public set audioAlertEnabled(enabled: boolean) {
        this._audioAlertEnabled = enabled;
        this.backend
            .enableAudioAlert(enabled)
            .catch(this.toast.networErrorHandler);
        
    }

    public loadConfiguration(){
        return this.backend
            .getConfiguration()
            .then(x  => this.initFromConfigurationRersponse(x));
    }

    public initFromConfigurationRersponse(resp: ConfigurationResponseSchema) {
        this._audioAlertEnabled = resp.audio_alert ?? false;
        this.coordinates        = resp.coordinates;
        this.locationName       = resp.location_name;
        this.userName           = resp.name ?? "";
        this.userEmail          = resp.email;
        this._tooltipsEnabled   = resp.tooltips ?? false;
        this.units              = resp.units ?? "metric";
        this.presetDates        = resp.preset_dates;
        this.granularity        = resp.granularity;

        const minDate = parseDate(resp.min_available_date);
        const dateService = this.dateSelectionHeaderService;

        dateService.minSelectableDate = minDate;
        dateService.maxSelectableDate = parseDate(resp.max_available_date);
        dateService.unavailableDates = parseDateArray(resp.missing_dates);

        if (environment.enableScenarios) {
            return this.loadScenarios(
                resp.default_setting, 
                this.lsGet(SelectedScenarioKey)
            );
        }
        else {
            this.backend.settingId = resp.default_setting;
            dateService.liveModeSelected = true;
            dateService.selectedDate = new Date();
            return
        }
    }

    public async loadScenarios(defaultScenario: string, lastSelectedScenario?: string) {
        const data = await this.backend.getScenarios();
        this.scenarios = data.scenarios;

        if (lastSelectedScenario && this.findScenarioWitId(lastSelectedScenario)) {
            // We select last selected scenario if it still exists
            this.selectScenario(lastSelectedScenario);
        }
        else {
            // otherwise we select default one from the configuration
            this.selectScenario(defaultScenario);
        }
    }

    private findScenarioWitId(scenarioId: string) {
        return this.scenarios.find(x => x.id === scenarioId);
    }


    public selectScenario(scenarioId: string, updateSelectedRange: boolean = true) {
        let scenario = this.scenarios.find(x => x.id === scenarioId);

        if (!scenario && this.scenarios.length > 0) {
            scenario = this.scenarios[0];
        }

        if (scenario) {
            this.lsSet(SelectedScenarioKey, scenarioId);

            this.selectedScenarioId = scenarioId;
            this.selectedScenarioName = scenario.name;
            this.backend.settingId = scenario.id;

            const dateService = this.dateSelectionHeaderService;

            const startDate = parseDate(scenario.min_available_date)!;
            dateService.minSelectableDate = startDate;
            dateService.maxSelectableDate = parseDate(scenario.max_available_date);
            dateService.unavailableDates = parseDateArray(scenario.missing_dates);

            const endDate = new Date(startDate);
            endDate.setDate(endDate.getUTCDate() + 6);

            dateService.liveModeSelected = false;
            if (updateSelectedRange) {
                dateService.selectedStartDate = new Date(startDate);
                dateService.selectedEndDate = endDate;
                //dateService.selectedStartDate = newUtc(2008, 9, 31)
                //dateService.selectedEndDate = newUtc(2009, 9, 2)
                dateService.selectedDate = dateService.selectedStartDate;
            }
            
        }
        else {
            console.error("No scenarios available, things will fail.");
        }
    }

    // ----- Chart Pinned Series

    public getChartPinnedSeries(chartId: string): number {
        return parseInt(this.lsGet(KeyChartPinnedSeriesPrefix + chartId) || "0");
    }

    public setChartPinnedSeries(chartId: string, index: number) {
        this.lsSet(KeyChartPinnedSeriesPrefix + chartId, index.toString());
    }

    // ----- Chart Active Series

    public getChartActiveSeries(chartId: string): number[] {
        const lsValue = this.lsGet(KeyChartActiveSeriesPrefix + chartId);
        const value = JSON.parse(lsValue || "[]");

        return Array.isArray(value) ? value : [];
    }

    public setChartActiveSeries(chartId: string, index: number[]) {
        this.lsSet(KeyChartActiveSeriesPrefix + chartId, JSON.stringify(index));
    }

    public clearChartConfig() {
        for (var key in localStorage) {
            if (key.startsWith(ChartKeyPrefix)) {
                localStorage.removeItem(key);
            }
        }
        this.chartConfigCleared.next(undefined);
    }

    // ----- Util methods

    private lsGet(key: string) {
        return localStorage.getItem(key) ?? undefined;
    }

    private lsSet(key: string, value: string) {
        return localStorage.setItem(key, value);
    }

    private booleanSet(key: string, value: boolean) {
        this.lsSet(key, value.toString());
    }

    private booleanGet(key: string, def: boolean): boolean {
        const value = this.lsGet(key);

        return (value === null) ? def : (value === "true");
    }

}

function parseDate(dateStr: string) {
    const timestamp = Date.parse(dateStr);

    if (isNaN(timestamp)) {
        console.error("Invalid date format: ", dateStr);
        return undefined;
    }

    const date = new Date(timestamp);

    if (isNaN(date.getTime())) {
        console.error("Invalid date format: ", dateStr);
        return undefined;
    }
    else {
        return dateStartOfDay(date);
    }
}

function parseDateArray(dateStrings?: string[]) : Date[] {
    if (!dateStrings) {
        return [];
    }

    return dateStrings.map(parseDate).filter((item): item is Date => !!item);
}
