import { Pipe, PipeTransform } from '@angular/core';
import { Observable, timer } from 'rxjs';
import { map } from 'rxjs/operators';
import { of } from 'rxjs';

@Pipe({
  name: 'countdown'
})
export class CountdownPipe implements PipeTransform {

    private getMsDiff(futureDate: Date): number {
        return futureDate.getTime() - Date.now();
    }

    private msToTime(msRemaining: number): string {
        if (msRemaining <= 0) {
            return "now";
        }

        return Math.ceil(msRemaining / 1000 / 60) + "min";
    }

    public transform(futureDate: Date | undefined): Observable<string> {
        if (!futureDate || this.getMsDiff(futureDate) < 0) {
            return of("now");
        }
        
        return timer(0, 5000).pipe(
            map(() => {
                return this.msToTime(this.getMsDiff(futureDate));
            })
        );
    }
}