import { Injectable } from "@angular/core";
import { EscKeyScopeService, EscKeySubscription } from "./esc-key-scope.service";

type DoneDelegate = (value1: string, value2: string) => Promise<boolean>;
type SingleDoneDelegate = (value1: string) => Promise<boolean>;

/**
 * Service that controls usage of Double field input  dialog.
 */
@Injectable({
    providedIn: 'root',
})
export class DoubleFieldInputService {
    public dialogOpened: boolean = false;

    public dialogTitle: string = "Change your password";
    public dialogSubtitle: string = "Enter your current password and a new password.";

    public firstFieldTitle: string = "CURRENT PASSWORD";
    public secondFieldTitle?: string;

    public firstFieldValue: string = "";
    public secondFieldValue?: string;

    public firstFieldType: string = "text";
    public secondFieldType: string = "text";

    /** Function that is executed when user clicks done in the dialog. */
    public doneDelegate: DoneDelegate = () => Promise.resolve(true);

    private escKeySubscription?: EscKeySubscription;

    constructor(private escKeyScopeService: EscKeyScopeService) {
	}

    
    public showDialog(
            title: string,
            subtitle: string,
            firstTitle: string,
            firstValue: string,
            firstType: "text" | "password",
            secondTitle: string ,
            secondValue: string,
            secondType: "text" | "password",
            delegate: DoneDelegate) {

        this.dialogOpened = true;
        this.dialogTitle = title;
        this.dialogSubtitle = subtitle;
        this.firstFieldTitle = firstTitle;
        this.firstFieldValue = firstValue;
        this.firstFieldType = firstType;
        this.secondFieldTitle = secondTitle;
        this.secondFieldValue = secondValue;
        this.secondFieldType = secondType;
        this.doneDelegate = delegate;

        this.escKeySubscription = this.escKeyScopeService.subscribe(() => this.closeDialog());
    }

    public showSingleInput(
            title: string,
            subtitle: string,
            firstTitle: string,
            firstValue: string,
            delegate: SingleDoneDelegate) {

        this.dialogOpened = true;
        this.dialogTitle = title;
        this.dialogSubtitle = subtitle;
        this.firstFieldTitle = firstTitle;
        this.firstFieldValue = firstValue;
        this.firstFieldType = "text";
        this.secondFieldTitle = undefined;
        this.secondFieldValue = undefined;
        this.doneDelegate = delegate;

        this.escKeySubscription = this.escKeyScopeService.subscribe(() => this.closeDialog());
    }

    public closeDialog() {
        this.dialogOpened = false;
        this.doneDelegate = () => Promise.resolve(true);

        this.escKeySubscription?.unsubscribe();
        this.escKeySubscription = undefined;
    }

}