<div class="header">
    <div class="ico"><svg [innerHTML]="icon | fico"></svg></div>
    <div class="title">{{title}}</div>
</div>

<div class="message">
    <ng-content></ng-content>
</div>

<div class="button" *ngIf="buttonLabel">
    <div class="hy-btn secondary try-again" (click)="buttonClick.emit()">{{buttonLabel}}</div>
</div>